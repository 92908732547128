import { Stack } from "@mui/material"
import { DashboardConfigurationToolActions } from "domain/dashboard/DashboardConfigurationTool/DashboardConfigurationToolActions"
import { DashboardLayoutGrid } from "domain/dashboard/DashboardLayout/DashboardLayoutGrid"
import { DashboardLayoutProvider, EMPTY_DASHBOARD_UUID } from "domain/dashboard/DashboardLayout/DashboardLayoutProvider"
import { DashboardSettingsContextProvider } from "domain/dashboard/DashboardSettings/DashboardSettingsContext"
import React from "react"

export const DashboardConfigurationTool = () => {
    return (
        <DashboardSettingsContextProvider uuid={EMPTY_DASHBOARD_UUID}>
            <DashboardLayoutProvider>
                <Stack gap={2}>
                    <DashboardConfigurationToolActions />
                    <DashboardLayoutGrid />
                </Stack>
            </DashboardLayoutProvider>
        </DashboardSettingsContextProvider>
    )
}
