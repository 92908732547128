import GenericPage from "domain/core/component/GenericPage"
import { GridColumnStateContextProvider } from "domain/user/settings/context/GridColumnStateContextProvider"
import { NewUiGenericPageMenuLeafDTO } from "generated/models"
import React from "react"

export interface NewUiGenericPageProps {
    pageConfiguration: NewUiGenericPageMenuLeafDTO
}

export const NewUiGenericPage = ({ pageConfiguration }: NewUiGenericPageProps) => {
    // At the moment, we render all pages using GenericPage
    return (
        <GridColumnStateContextProvider>
            <GenericPage pageConfiguration={pageConfiguration} />
        </GridColumnStateContextProvider>
    )
}
