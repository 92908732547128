import mfaService from "./service/mfaService"
import MailOutlineIcon from "@mui/icons-material/MailOutline"
import { Button } from "@mui/material"
import { OtpFormResponseDTO } from "generated/models"
import React, { type JSX, useEffect, useState } from "react"

type Props = {
    sendOnMount?: boolean
    onSuccess: () => void
    onError: () => void
}

const RequestEmailCodeButton: (props: Props) => JSX.Element = (props: Props): JSX.Element => {
    const [codeGenerationResponse, setCodeGenerationResponse] = useState<OtpFormResponseDTO>(undefined)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        if (props?.sendOnMount === true) requestCode()
        // TODO: is it safe to add the missing dependencies?
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.sendOnMount])

    const setDelayedIsLoading = (isLoading: boolean) => {
        if (isLoading) {
            setIsLoading(isLoading)
        } else {
            setTimeout(() => setIsLoading(isLoading), 500)
        }
    }

    const requestCode = () => {
        setDelayedIsLoading(true)
        mfaService.EMAIL.requestCode().then((response: OtpFormResponseDTO) => {
            setDelayedIsLoading(false)
            setCodeGenerationResponse(response)
            if (response.successful) {
                props?.onSuccess()
            } else {
                props?.onError()
            }
        })
    }

    return (
        <Button onClick={requestCode} startIcon={<MailOutlineIcon />} loading={isLoading} variant={"outlined"}>
            {props?.sendOnMount || codeGenerationResponse ? "Re-Send Code" : "Send Code"}
        </Button>
    )
}

export default RequestEmailCodeButton
