import { AxiosRequestConfig } from "axios"
import {
    ADSPEND_MEDIA_PLANS_DIMENSION_IDENTIFIER,
    ADSPEND_SCENARIO_DIMENSION_IDENTIFIER,
    CAMPAIGN_DIMENSION_IDENTIFIER,
    COMMENT_DIMENSION_IDENTIFIER,
    END_DATE_DIMENSION_IDENTIFIER,
    JOB_STATUS_DIMENSION_IDENTIFIER,
    MEDIAPLAN_GROUP_BY_DIMENSION_IDENTIFIER,
    START_DATE_DIMENSION_IDENTIFIER,
    STATUS_DIMENSION_IDENTIFIER,
} from "domain/adspend-optimizer/context/AdSpendOptimizerContext"
import { CONTEXT_MENU_FIELD, CONTEXT_MENU_FIELD_ID } from "domain/datagrid/component/DataGrid"
import DimensionService, { asDataColumnIdentifier } from "domain/dimension/service/DimensionService"
import { getVisiblePerDefaultColumnConfigs } from "domain/legacy/widget/generic/GridUtil"
import { BooleanOperator, ClauseType, ConditionClauseType, QueryModeType, Scenario } from "domain/types"
import {
    CreateExportResponseDTO,
    LoadResponseDTOReportingDataSetDTO,
    PageConfigDTO,
    UpdateResponseDTO,
} from "generated/models"
import {
    AppContextDTO,
    BooleanClauseDTO,
    ColumnConfigDTO,
    ContainerElementDTO,
    GridColumnPropertiesDTO,
    GridElementDTO,
    NumberValueClauseDTO,
    PageableDTO,
    QuerySettingsDTO,
    SortSettingsDTO,
} from "generated/models"
import * as api from "shared/service"
import PageService from "shared/service/page.service"
import UrlService from "shared/service/url.service"
import UrlUtil from "shared/util/UrlUtil"
import { v4 as uuid } from "uuid"

const BASE_URL = "/adspendscenario"
const CONFIG_URL = "/loadpageconfig"
const DATA_URL = "/loaddata"
const CREATE_URL = "/insertdata"
const UPDATE_URL = "/updatedata"
const DELETE_URL = "/deletedata"
const EXPORT_CREATE_URL = "/createexportdata"
const EXPORT_URL = "/exportdata"

const BASE_PAGINATION_SETTINGS: PageableDTO = {
    page: 0,
    pageSize: 1000,
}

const BASE_FILTER_QUERY: BooleanClauseDTO = {
    clauseType: ConditionClauseType.BOOLEAN,
    operator: BooleanOperator.AND,
    clauses: [],
}

const fetchScenarios = async (
    campaignId: number,
    appContext: AppContextDTO,
    paginationSettings: PageableDTO,
    sortSettings: SortSettingsDTO,
): Promise<LoadResponseDTOReportingDataSetDTO> => {
    const clauses = [
        {
            clauseType: ConditionClauseType.NUMBER,
            columnName: DimensionService.getValueColumn(asDataColumnIdentifier(CAMPAIGN_DIMENSION_IDENTIFIER)),
            type: "EQUALS",
            value: campaignId,
        },
        {
            clauseType: ConditionClauseType.NUMBER,
            columnName: DimensionService.getValueColumn(asDataColumnIdentifier(STATUS_DIMENSION_IDENTIFIER)),
            type: "EQUALS",
            value: 1,
        },
    ]

    const querySettings: QuerySettingsDTO = {
        filter: { ...BASE_FILTER_QUERY },
        appContext: appContext,
        paginationSettings: paginationSettings,
        sortSettings: sortSettings,
        columnNames: [
            DimensionService.getValueColumn(asDataColumnIdentifier(ADSPEND_SCENARIO_DIMENSION_IDENTIFIER)),
            DimensionService.getNameColumn(asDataColumnIdentifier(ADSPEND_SCENARIO_DIMENSION_IDENTIFIER)),
            DimensionService.getValueColumn(asDataColumnIdentifier(START_DATE_DIMENSION_IDENTIFIER)),
            DimensionService.getValueColumn(asDataColumnIdentifier(END_DATE_DIMENSION_IDENTIFIER)),
            DimensionService.getValueColumn(asDataColumnIdentifier(MEDIAPLAN_GROUP_BY_DIMENSION_IDENTIFIER)),
            DimensionService.getValueColumn(asDataColumnIdentifier(COMMENT_DIMENSION_IDENTIFIER)),
        ],
        queryIdentifier: { value: uuid() },
    }

    // @ts-expect-error TODO fix me
    querySettings.filter.clauses = [...clauses]

    const result: LoadResponseDTOReportingDataSetDTO = await api.postCancellableData(
        UrlUtil.joinUrl(UrlService.getUiServiceApiUrl(), BASE_URL),
        DATA_URL,
        querySettings,
    )

    result.dataSet.rows.forEach((entry) => {
        entry[CONTEXT_MENU_FIELD] = entry[ADSPEND_SCENARIO_DIMENSION_IDENTIFIER]
        return entry
    })

    return result
}

const fetchScenario = async (
    scenarioId: number,
    appContext: AppContextDTO,
): Promise<LoadResponseDTOReportingDataSetDTO> => {
    const clause: NumberValueClauseDTO = {
        clauseType: ConditionClauseType.NUMBER,
        columnName: DimensionService.getValueColumn(asDataColumnIdentifier(ADSPEND_SCENARIO_DIMENSION_IDENTIFIER)),
        type: ClauseType.EQUALS,
        value: scenarioId,
    }

    const querySettings: QuerySettingsDTO = {
        filter: {
            clauseType: ConditionClauseType.BOOLEAN,
            operator: BooleanOperator.AND,
            clauses: [clause],
        },
        paginationSettings: {
            page: 0,
            pageSize: 1000,
        },
        sortSettings: {
            sortProperties: [
                DimensionService.getValueColumn(asDataColumnIdentifier(ADSPEND_SCENARIO_DIMENSION_IDENTIFIER)),
            ],
            sortAscending: false,
        },
        appContext: appContext,
        columnNames: [
            DimensionService.getValueColumn(asDataColumnIdentifier(ADSPEND_SCENARIO_DIMENSION_IDENTIFIER)),
            DimensionService.getNameColumn(asDataColumnIdentifier(ADSPEND_SCENARIO_DIMENSION_IDENTIFIER)),
            DimensionService.getValueColumn(asDataColumnIdentifier(START_DATE_DIMENSION_IDENTIFIER)),
            DimensionService.getValueColumn(asDataColumnIdentifier(END_DATE_DIMENSION_IDENTIFIER)),
            DimensionService.getValueColumn(asDataColumnIdentifier(ADSPEND_MEDIA_PLANS_DIMENSION_IDENTIFIER)),
            DimensionService.getValueColumn(asDataColumnIdentifier(MEDIAPLAN_GROUP_BY_DIMENSION_IDENTIFIER)),
            DimensionService.getValueColumn(asDataColumnIdentifier(JOB_STATUS_DIMENSION_IDENTIFIER)),
            DimensionService.getValueColumn(asDataColumnIdentifier(COMMENT_DIMENSION_IDENTIFIER)),
        ],
        queryIdentifier: { value: uuid() },
    }

    // return await post<LoadResponseDTOReportingDataSetDTO>(querySettings, `${BASE_URL}${DATA_URL}`, {
    //     baseURL: UrlService.getUiServiceApiUrl(),
    // })

    return await api.postCancellableData(
        UrlUtil.joinUrl(UrlService.getUiServiceApiUrl(), BASE_URL),
        DATA_URL,
        querySettings,
    )
}

const createScenario = async (scenarioData: Scenario, appContext: AppContextDTO): Promise<UpdateResponseDTO> => {
    const payload = {
        appContext,
        rows: [],
    }
    payload.rows.push({
        [DimensionService.getValueColumn(asDataColumnIdentifier(CAMPAIGN_DIMENSION_IDENTIFIER))]:
            scenarioData.campaign_id,
        [DimensionService.getNameColumn(asDataColumnIdentifier(ADSPEND_SCENARIO_DIMENSION_IDENTIFIER))]:
            scenarioData.adspend_scenario_name,
        [DimensionService.getValueColumn(asDataColumnIdentifier(COMMENT_DIMENSION_IDENTIFIER))]:
            scenarioData.comment || null,
        [DimensionService.getValueColumn(asDataColumnIdentifier(MEDIAPLAN_GROUP_BY_DIMENSION_IDENTIFIER))]:
            `${scenarioData.optimizationLevel.toLowerCase()}_id`,
        [DimensionService.getValueColumn(asDataColumnIdentifier(START_DATE_DIMENSION_IDENTIFIER))]:
            scenarioData.start_date,
        [DimensionService.getValueColumn(asDataColumnIdentifier(END_DATE_DIMENSION_IDENTIFIER))]: scenarioData.end_date,
        [DimensionService.getValueColumn(asDataColumnIdentifier(ADSPEND_MEDIA_PLANS_DIMENSION_IDENTIFIER))]: [
            ...scenarioData.mediaPlan,
        ],
    })

    return await post(payload, `${BASE_URL}${CREATE_URL}`, { baseURL: UrlService.getUiServiceApiUrl() })
}

const updateScenario = async (scenarioData: Scenario, appContext: AppContextDTO): Promise<UpdateResponseDTO> => {
    const payload = {
        appContext,
        rows: [],
    }
    payload.rows.push({
        [DimensionService.getValueColumn(asDataColumnIdentifier(CAMPAIGN_DIMENSION_IDENTIFIER))]:
            scenarioData.campaign_id,
        [DimensionService.getValueColumn(asDataColumnIdentifier(ADSPEND_SCENARIO_DIMENSION_IDENTIFIER))]:
            scenarioData.adspend_scenario_id,
        [DimensionService.getNameColumn(asDataColumnIdentifier(ADSPEND_SCENARIO_DIMENSION_IDENTIFIER))]:
            scenarioData.adspend_scenario_name,
        [DimensionService.getValueColumn(asDataColumnIdentifier(COMMENT_DIMENSION_IDENTIFIER))]:
            scenarioData.comment || null,
        [DimensionService.getValueColumn(asDataColumnIdentifier(MEDIAPLAN_GROUP_BY_DIMENSION_IDENTIFIER))]:
            `${scenarioData.optimizationLevel.toLowerCase()}_id`,
        [DimensionService.getValueColumn(asDataColumnIdentifier(START_DATE_DIMENSION_IDENTIFIER))]:
            scenarioData.start_date,
        [DimensionService.getValueColumn(asDataColumnIdentifier(END_DATE_DIMENSION_IDENTIFIER))]: scenarioData.end_date,
        [DimensionService.getValueColumn(asDataColumnIdentifier(ADSPEND_MEDIA_PLANS_DIMENSION_IDENTIFIER))]: [
            ...scenarioData.mediaPlan,
        ],
    })

    return await put(payload, `${BASE_URL}${UPDATE_URL}`, { baseURL: UrlService.getUiServiceApiUrl() })
}

const deleteScenario = async (scenarioId: string, appContext): Promise<UpdateResponseDTO> => {
    const payload = {
        filter: {
            ...BASE_FILTER_QUERY,
            clauses: [
                {
                    columnName: DimensionService.getValueColumn(
                        asDataColumnIdentifier(ADSPEND_SCENARIO_DIMENSION_IDENTIFIER),
                    ),
                    value: scenarioId,
                    type: ClauseType.EQUALS,
                    clauseType: ConditionClauseType.STRING,
                },
            ],
        },
        appContext: { ...appContext },
    }

    return await api.deleteRequest(`${BASE_URL}${DELETE_URL}`, {
        baseURL: UrlService.getUiServiceApiUrl(),
        data: { ...payload },
    })
}

const loadPageConfig = (appContext: AppContextDTO): Promise<PageConfigDTO> =>
    PageService.loadPageConfig(`${BASE_URL}${CONFIG_URL}`, appContext, UrlService.getUiServiceApiUrl())

const fetchOptimizationLevelData = async (
    optimizationLevel: string,
    appContext: AppContextDTO,
): Promise<LoadResponseDTOReportingDataSetDTO> => {
    const querySettings: QuerySettingsDTO = {
        paginationSettings: { ...BASE_PAGINATION_SETTINGS },
        columnNames: [],
        sortSettings: {
            sortProperties: [DimensionService.getValueColumn(asDataColumnIdentifier(optimizationLevel.toLowerCase()))],
            sortAscending: true,
        },
        filter: null,
        appContext: appContext,
        queryIdentifier: { value: uuid() },
    }

    return await api.postCancellableData(
        UrlUtil.joinUrl(UrlService.getUiServiceApiUrl(), BASE_URL),
        UrlUtil.joinUrl(DATA_URL, optimizationLevel),
        querySettings,
    )
}

const getOptimizationLevel = (level: string): string => {
    return level === "campaign_id" ? "CAMPAIGN" : level === "sub_campaign_id" ? "SUB_CAMPAIGN" : "CHANNEL"
}

const createCompareModePayload = (
    prefix: string,
    scenarioIds: string[],
    appContext: AppContextDTO,
    weekly = false,
): QuerySettingsDTO => {
    const columnNames = [
        DimensionService.getValueColumn(asDataColumnIdentifier(prefix)),
        DimensionService.getNameColumn(asDataColumnIdentifier(prefix)),
        DimensionService.getValueColumn(asDataColumnIdentifier("adspend_conversions")),
        DimensionService.getValueColumn(asDataColumnIdentifier("adspend_costs")),
        DimensionService.getValueColumn(asDataColumnIdentifier("adspend_total_price")),
        DimensionService.getValueColumn(asDataColumnIdentifier("adspend_roi")),
        DimensionService.getValueColumn(asDataColumnIdentifier("adspend_roas")),
        DimensionService.getValueColumn(asDataColumnIdentifier("adspend_cpo")),
    ]

    if (weekly) {
        columnNames.unshift(
            DimensionService.getValueColumn(asDataColumnIdentifier("weekly")),
            DimensionService.getNameColumn(asDataColumnIdentifier("weekly")),
        )
    }

    return {
        columnNames: columnNames,
        appContext: appContext,
        filter: {
            operator: BooleanOperator.AND,
            clauseType: ConditionClauseType.BOOLEAN,
            clauses: [
                {
                    clauses: scenarioIds.map((id) => ({
                        columnName: DimensionService.getValueColumn(
                            asDataColumnIdentifier(ADSPEND_SCENARIO_DIMENSION_IDENTIFIER),
                        ),
                        value: id,
                        type: ClauseType.EQUALS,
                        clauseType: ConditionClauseType.STRING,
                    })),
                    clauseType: ConditionClauseType.BOOLEAN,
                    operator: BooleanOperator.OR,
                } as BooleanClauseDTO,
            ],
        },
        mode: {
            type: QueryModeType.COMPARE,
            // ASO only supports compare mode for "columnNames": ["adspend_scenario"]
            // because we always want to compare 2 scenarios
            columnNames: [ADSPEND_SCENARIO_DIMENSION_IDENTIFIER],
        },
        paginationSettings: {
            page: 0,
            pageSize: 100,
        },
        sortSettings: {
            sortProperties: [DimensionService.getNameColumn(asDataColumnIdentifier(prefix))], // TODO sort by multiple fields, like week asc, channel asc
            sortAscending: true,
        },
        timespanSettings: null,
        queryIdentifier: { value: uuid() },
    }
}

const fetchComparisonData = async (
    prefix: string,
    scenarioIds: string[],
    appContext: AppContextDTO,
    weekly: boolean = false,
): Promise<LoadResponseDTOReportingDataSetDTO> => {
    return await api.postCancellableData(
        UrlUtil.joinUrl(UrlService.getUiServiceApiUrl(), BASE_URL),
        DATA_URL,
        createCompareModePayload(prefix, scenarioIds, appContext, weekly),
    )
}

const exportComparisonData = ({ prefix, scenarioIds, appContext }): Promise<void> => {
    const settings = createCompareModePayload(prefix, scenarioIds, appContext)

    return post<CreateExportResponseDTO>(settings, `${BASE_URL}${EXPORT_CREATE_URL}`, {
        baseURL: UrlService.getUiServiceApiUrl(),
    }).then((triggerResponse) => {
        const identifier = triggerResponse.id
        const link = document.createElement("a")
        link.href = `${UrlService.getUiServiceApiUrl()}${BASE_URL}${EXPORT_URL}/${identifier}`
        link.setAttribute("download", triggerResponse.filename)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    })
}

const createPlotModePayload = (
    mediaPlanType: string,
    mediaPlanId: number,
    appContext: AppContextDTO,
): QuerySettingsDTO => {
    const columnNames = [
        DimensionService.getValueColumn(asDataColumnIdentifier("adspend_costs")),
        DimensionService.getValueColumn(asDataColumnIdentifier("adspend_conversions")),
    ]

    return {
        columnNames: columnNames,
        appContext: appContext,
        filter: {
            operator: BooleanOperator.AND,
            clauseType: ConditionClauseType.BOOLEAN,
            clauses: [
                {
                    columnName: DimensionService.getValueColumn(asDataColumnIdentifier(mediaPlanType)),
                    value: mediaPlanId,
                    type: ClauseType.EQUALS,
                    clauseType: ConditionClauseType.NUMBER,
                } as NumberValueClauseDTO,
            ],
        },
        mode: {
            type: QueryModeType.PLOT,
        },
        paginationSettings: {
            page: 0,
            pageSize: 100,
        },
        sortSettings: {
            sortProperties: [DimensionService.getValueColumn(asDataColumnIdentifier("adspend_costs"))],
            sortAscending: true,
        },
        timespanSettings: null,
        queryIdentifier: { value: uuid() },
    }
}

const fetchEfficiencyData = async (
    mediaPlanType: string,
    mediaPlanId: number,
    appContext: AppContextDTO,
): Promise<LoadResponseDTOReportingDataSetDTO> => {
    const payload = createPlotModePayload(mediaPlanType, mediaPlanId, appContext)
    return await api.postCancellableData(UrlUtil.joinUrl(UrlService.getUiServiceApiUrl(), BASE_URL), DATA_URL, payload)
}

const post = <T>(data: any, path: string, config: AxiosRequestConfig): Promise<T> => {
    return api.post(path, data, config)
}

const put = <T>(data: any, path: string, config: AxiosRequestConfig): Promise<T> => {
    return api.put(path, data, config)
}

const mapColumnConfigs = (pageConfig: PageConfigDTO): ColumnConfigDTO[] => {
    // const config = ((pageConfig.layoutConfig as ContainerElementDTO).children[0] as GridElementDTO).elementConfig
    const config = (
        ((pageConfig.layoutConfig as ContainerElementDTO).children[1] as ContainerElementDTO)
            .children[0] as GridElementDTO
    ).elementConfig

    const actionColumn = {
        columnIdentifier: CONTEXT_MENU_FIELD_ID,
        // @ts-expect-error custom ColumnRendererDTO type used
        gridColumnProperties: {
            isMetric: false,
            isFixed: false,
            postfix: "",
            columnHeader: "",
            width: 110,
            renderer: { type: RendererType.CONTEXT_MENU, cssClasses: [] } as ColumnRendererDTO,
            sortable: false,
            editable: false,
        } as GridColumnPropertiesDTO,
    } as ColumnConfigDTO

    return [
        ...getVisiblePerDefaultColumnConfigs(config.gridConfig).filter(
            (c) =>
                c.columnIdentifier !==
                    DimensionService.getValueColumn(asDataColumnIdentifier(STATUS_DIMENSION_IDENTIFIER)) &&
                c.columnIdentifier !==
                    DimensionService.getValueColumn(asDataColumnIdentifier(JOB_STATUS_DIMENSION_IDENTIFIER)),
        ),
        actionColumn,
    ]
}

type ColumnRendererDTO = {
    type: RendererType
    cssClasses: string[]
}

export enum RendererType {
    // TEXT = 'TEXT',
    // NUMBER = 'NUMBER',
    // DATE = 'DATE',
    // DATE_TIME = 'DATE_TIME',
    // STATUS = 'STATUS',
    // BOOLEAN = 'BOOLEAN',
    // CSS_STYLED = 'CSS_STYLED',
    // TOOLTIP = 'TOOLTIP',
    ROW_SELECTOR = "ROW_SELECTOR",
    CONTEXT_MENU = "CONTEXT_MENU",
    OPTIMIZATION_LEVEL = "OPTIMIZATION_LEVEL",
    VALUE_COMPARISON = "VALUE_COMPARISON",
    // PERCENT = 'PERCENT',
    // JSON = 'JSON',
    // INLINE_BUTTONS = 'INLINE_BUTTONS',
    // HEALTH = 'HEALTH',
    DATE = "DATE",
    DATE_TIME = "DATE_TIME",
    INLINE_BUTTONS = "INLINE_BUTTONS",
    VALUE = "VALUE",
    PREFIX_NAME = "PREFIX_NAME",
    POSTFIX_VALUE = "POSTFIX_VALUE",
    NAME = "NAME",
    ICON = "ICON",
    ICON_NAME = "ICON_NAME",
    INLINE_ICON_NAME = "INLINE_ICON_NAME",
    DATA = "DATA",
}

const AdSpendOptimizerService = {
    fetchScenarios,
    fetchScenario: fetchScenario,
    createScenario,
    updateScenario,
    loadPageConfig: loadPageConfig,
    fetchOptimizationLevelData: fetchOptimizationLevelData,
    getOptimizationLevel,
    fetchComparisonData,
    exportComparisonData,
    fetchEfficiencyData,
    deleteScenario,
    mapColumnConfigs,
}
export default AdSpendOptimizerService
