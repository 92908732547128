import {
    CHANNEL_DIMENSION_IDENTIFIER,
    SUB_CAMPAIGN_DIMENSION_IDENTIFIER,
} from "domain/adspend-optimizer/context/AdSpendOptimizerContext"
import { Scenario } from "domain/types"
import ReactEcharts from "echarts-for-react"
import { ReportingDataSetDTO } from "generated/models"
import numbro from "numbro"
import React from "react"

/**
 * NOTE: this chart should be made more generic in the future and probably be turned into a global component instead; but I wanted to start as simple as  possible...
 */

/**
 * Represents a single point in the line chart. Usually an Array of coordinates is supplied
 * via the data property of the LineChart component.
 */
export type ChartCoordinate = {
    x: number
    y: number
}

type Props = {
    data?: ReportingDataSetDTO
    scenario1?: Scenario
    scenario2?: Scenario
}

/**
 * Renders a line chart based on the given configuration
 */
export class MultiBarChart extends React.Component<Props, never> {
    render() {
        const { data, scenario1, scenario2 } = this.props
        const { rows } = data

        const labels = []
        const data1 = []
        const data2 = []

        const scenario1Name = scenario1 !== undefined ? scenario1.adspend_scenario_name : "Historic Performance" // TODO change hardcoded default name
        const scenario2Name = scenario2 !== undefined ? scenario2.adspend_scenario_name : "Recommended Scenario" // TODO change hardcoded default name

        const stackedBarNames = [scenario1Name, scenario2Name]

        const column = "adspend_costs" // TODO make dynamic

        rows.forEach((row, index) => {
            const label =
                CHANNEL_DIMENSION_IDENTIFIER in row
                    ? row[CHANNEL_DIMENSION_IDENTIFIER]["name"]
                    : row[SUB_CAMPAIGN_DIMENSION_IDENTIFIER]["name"] // TODO make dynamic
            labels.push(label)
            const columnData1 = row.slices.data[0].data[column]
            const columnData2 = row.slices.data[1].data[column]
            const costs1 = columnData1 ? columnData1["value"] : null
            const costs2 = columnData2 ? columnData2["value"] : null
            data1.push(costs1)
            data2.push(costs2)
        })

        const labelOption = {
            normal: {
                show: false,
                // position: config.position,
                // distance: config.distance,
                // align: config.align,
                // verticalAlign: config.verticalAlign,
                // rotate: config.rotate,
                formatter: "LABEL{c}  {name|{a}}",
                fontSize: 16,
                rich: {
                    name: {
                        textBorderColor: "#fff",
                    },
                },
            },
        }

        const option = {
            color: ["#3562E3", "#113263"],
            grid: {
                bottom: 0,
                left: 0,
                containLabel: true,
            },
            tooltip: {
                trigger: "item",
                axisPointer: {
                    type: "shadow",
                },
                formatter: function (params, ticket, callback) {
                    const nameIndex = params.componentIndex % 2
                    const name = stackedBarNames[nameIndex]

                    const formattedNumber = numbro(params.data).format({
                        thousandSeparated: true,
                        mantissa: 2,
                        trimMantissa: true,
                    })

                    return "<b>" + name + "</b> (" + params.name + ")<br>" + "Budget: " + formattedNumber + "€"
                },
            },
            legend: {
                show: true,
                data: labels,
            },
            calculable: true,
            xAxis: [
                {
                    axisLabel: {
                        rotate: 90,

                        formatter: function (value, index) {
                            if (value.length > 20) return "..." + value.substring(value.length - 20, value.length)
                            else return value
                        },
                    },
                    type: "category",
                    axisTick: { show: false },
                    data: labels,
                },
            ],
            yAxis: [
                {
                    type: "value",
                },
            ],
            series: [
                {
                    name: scenario1Name,
                    type: "bar",
                    barGap: "5%",
                    barMaxWidth: 40,
                    barMinHeight: 5,
                    label: labelOption,
                    data: data1,
                },

                {
                    name: scenario2Name,
                    type: "bar",
                    barMaxWidth: 40,
                    barMinHeight: 5,
                    label: labelOption,
                    data: data2,
                },
            ],
        }

        return (
            <ReactEcharts
                style={{ height: "450px" }}
                option={option}
                notMerge={true}
                lazyUpdate={true}
                theme={"exactag"}
                opts={{ renderer: "svg" }}
            />
        )
    }
}
