/* eslint-disable @typescript-eslint/no-unused-expressions */
import { ScenarioComparisonBox } from "domain/adspend-optimizer/component/overview/ScenarioComparisonBox"
import { ScenarioInfoBoxes } from "domain/adspend-optimizer/component/overview/ScenarioInfoBoxes"
import { AdSpendOptimizerContext } from "domain/adspend-optimizer/context/AdSpendOptimizerContext"
import React, { useContext, useEffect, useState } from "react"

export const ScenarioOverview = () => {
    const asoContext = useContext(AdSpendOptimizerContext)

    const [scenario1, setScenario1] = useState(null)
    const [scenario2, setScenario2] = useState(null)

    useEffect(() => {
        asoContext && asoContext.scenario1data && setScenario1(asoContext.scenario1data)
        // TODO: is it safe to add the missing dependencies?
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [asoContext.scenario1data])

    useEffect(() => {
        asoContext && asoContext.scenario2data && setScenario2(asoContext.scenario2data)
        // TODO: is it safe to add the missing dependencies?
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [asoContext.scenario2data])

    const selectScenario1 = (_: string, scenarioId: number) => asoContext.selectFirstScenario(scenarioId)

    const selectScenario2 = (_: string, scenarioId: number) => asoContext.selectSecondScenario(scenarioId)

    const pageLoaded = !!asoContext.scenarioGridColumns

    return (
        pageLoaded && (
            <React.Fragment>
                <ScenarioInfoBoxes
                    scenario1={scenario1}
                    scenario2={scenario2}
                    comparisonData={asoContext.comparisonData}
                    scenarios={asoContext.allScenarios}
                    selectScenario1={selectScenario1}
                    selectScenario2={selectScenario2}
                />

                {asoContext.comparisonData && (
                    <ScenarioComparisonBox
                        scenarioName1={scenario1 ? scenario1.adspend_scenario_name : ""}
                        scenarioName2={scenario2 ? scenario2.adspend_scenario_name : ""}
                        comparisonData={asoContext.comparisonData}
                    />
                )}
            </React.Fragment>
        )
    )
}
