import { KeyboardDoubleArrowLeft, KeyboardDoubleArrowRight } from "@mui/icons-material"
import { IconButton, Tooltip } from "@mui/material"
import React, { useCallback, useState } from "react"

interface SidebarToggleButtonProps {
    isDrawerOpen: boolean
    toggleDrawer: () => void
}

const SidebarToggleButton: React.FC<SidebarToggleButtonProps> = ({ isDrawerOpen, toggleDrawer }) => {
    const tooltipText = isDrawerOpen ? "Collapse sidebar" : "Expand sidebar"
    const [tooltipOpen, setTooltipOpen] = useState(false)

    const handleClick = useCallback(() => {
        // Hide tooltip first
        setTooltipOpen(false)
        // Then toggle the sidebar after a short delay to avoid seeing incorrect tooltip for a short amount of time
        setTimeout(() => toggleDrawer(), 50)
    }, [toggleDrawer])

    return (
        <Tooltip
            title={tooltipText}
            open={tooltipOpen}
            onOpen={() => setTooltipOpen(true)}
            onClose={() => setTooltipOpen(false)}
        >
            <IconButton
                color="inherit"
                onClick={handleClick}
                edge="start"
                sx={{
                    marginRight: isDrawerOpen ? "0px" : "7px",
                    marginBottom: "14px",
                    padding: "8px",
                }}
            >
                {isDrawerOpen ? (
                    <KeyboardDoubleArrowLeft fontSize={"small"} />
                ) : (
                    <KeyboardDoubleArrowRight fontSize={"small"} />
                )}
            </IconButton>
        </Tooltip>
    )
}

export default SidebarToggleButton
