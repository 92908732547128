import { useDashboardLayout } from "./DashboardLayoutContext"
import { DashboardLayoutItem } from "./DashboardLayoutItem"
import { Box, Paper } from "@mui/material"
import React, { useCallback } from "react"

export const DashboardLayoutGrid = () => {
    const { init, layoutForm: form, htmlToImage } = useDashboardLayout()
    const refHandler = useCallback(
        (el: HTMLDivElement | null) => {
            if (!el) return
            htmlToImage.ref.current = el
            init(el)
        },
        [htmlToImage.ref, init],
    )

    return (
        <Paper
            elevation={4}
            sx={{
                minHeight: "120px",
                p: 3,
            }}
        >
            <Box
                component="div"
                ref={refHandler}
                className="grid-stack"
                sx={{
                    width: "100%",
                }}
            >
                <form.Field name="layout" mode="array">
                    {(field) =>
                        field.state.value.map((field) => {
                            return <DashboardLayoutItem key={field.id} {...field} />
                        })
                    }
                </form.Field>
            </Box>
        </Paper>
    )
}
