import { Autocomplete, DialogContentText, FormControl, TextField } from "@mui/material"
import { AppContextState } from "domain/core/redux/appcontext.slice"
import { UserConfigs, actions } from "domain/user/redux/load.user.data.actions"
import { AppContextDTO } from "generated/models"
import { AdvertiserTree, UserTreeDTO } from "generated/models"
import { CustomDialog } from "layout/components/CustomDialog"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useSelector } from "react-redux"
import { useLocation } from "react-router"
import { RootState, useAppDispatch } from "shared/redux/store"

type Props = {
    campaignSelectionRequired: boolean
}

type Campaign = {
    id: number
    name: string
}

export const RequiredCampaignSelection = ({ campaignSelectionRequired: campaignSelectionRequired }: Props) => {
    const userStore: UserConfigs = useSelector((state: RootState) => state.user)
    const appContext: AppContextState = useSelector((state: RootState) => state.appContext)
    const dispatch = useAppDispatch()

    const location = useLocation()
    const shouldModalBeShown = useCallback((): boolean => {
        return campaignSelectionRequired && (appContext.appContext as AppContextDTO).campaignId === undefined
    }, [campaignSelectionRequired, appContext.appContext])

    const getCampaignsFromTree = useMemo(
        () =>
            (advertiserTree: UserTreeDTO, currentAdvertiserId: number): Campaign[] => {
                const advertiser = advertiserTree.root.find(
                    (advertiser: AdvertiserTree) => advertiser.value == currentAdvertiserId,
                )

                return advertiser
                    ? advertiser.children.map((campaign: any) => {
                          return {
                              id: campaign.value,
                              name: campaign.label,
                          }
                      })
                    : []
            },
        [],
    )

    const [showModal, setShowModal] = useState(shouldModalBeShown())

    useEffect(() => {
        if (showModal) {
            // reset selected campaign
            setSelectedCampaign(undefined)
        }
    }, [showModal])

    useEffect(() => {
        const result = shouldModalBeShown()
        setShowModal(result)
    }, [location.pathname, appContext.appContext.advertiserId, appContext.appContext.campaignId, shouldModalBeShown])

    const handleChangeCampaign = (event: React.SyntheticEvent, value: Campaign | null) => {
        setSelectedCampaign(value)
    }

    const handleSelectCampaign = (event) => {
        actions.updateSelectedAdvertiserCampaign(
            appContext.appContext.advertiserId,
            selectedCampaign?.id as number,
        )(dispatch)
        setShowModal(false)
    }

    const [selectedCampaign, setSelectedCampaign] = useState<Campaign>(undefined)
    const campaigns = getCampaignsFromTree(userStore.tree, appContext.appContext?.advertiserId as number)

    return (
        <CustomDialog
            header={"Select Campaign"}
            closeButtonDisabled={true}
            content={
                <DialogContentText minWidth={450}>
                    <FormControl fullWidth>
                        <Autocomplete
                            fullWidth
                            options={campaigns}
                            getOptionLabel={(option: Campaign) => option.name}
                            onChange={handleChangeCampaign}
                            isOptionEqualToValue={(option: Campaign, value: Campaign) => option.id === value.id}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    size="small"
                                    InputProps={{
                                        ...params.InputProps,
                                    }}
                                    label="Select Campaign"
                                />
                            )}
                        />
                    </FormControl>
                </DialogContentText>
            }
            footer={{
                kind: "yesButton",
                yesText: "Select",
                onYesButtonClick: handleSelectCampaign,
                submitting: false,
                isYesButtonDisabled: selectedCampaign == null,
            }}
            open={showModal}
        />
    )
}
