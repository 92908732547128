import { createSvgIcon } from "@mui/material"
import React from "react"

const LogoXIcon = createSvgIcon(
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <g clipPath="url(#a)">
            <mask id="b" width="24" height="21" x="0" y="2" maskUnits="userSpaceOnUse">
                <path fill="#fff" d="M24 2.371H0v19.862h24V2.371Z" />
            </mask>
            <g mask="url(#b)">
                <path
                    fill="currentColor"
                    d="m11.956 16.474 5.229 5.747h6.813l-5.23-5.747h-6.812ZM.002 2.384l8.143 9.918-8.143 9.919h6.813l8.142-9.919-8.142-9.919H.002Zm17.183 0L11.956 8.13h6.813l5.229-5.748h-6.813Z"
                />
            </g>
        </g>
        <defs>
            <clipPath id="a">
                <path fill="#fff" d="M0 2.371h24v19.862H0z" />
            </clipPath>
        </defs>
    </svg>,
    "Logo X",
)

export default LogoXIcon
