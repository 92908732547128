import { useQuery } from "@tanstack/react-query"
import type { UseQueryResult } from "@tanstack/react-query"
import { UiConfigDTO } from "generated/models"
import { useSelector } from "react-redux"
import { RootState } from "shared/redux/store"
import * as api from "shared/service"
import UrlService from "shared/service/url.service"
import { log } from "shared/util/log"

const URL = `${UrlService.getUiServiceApiUrl()}/ui-config`

export type GetUiConfigHookParams = {
    skip?: boolean
}
export type GetUiConfigHookResult = UseQueryResult<UiConfigDTO, Error>
export type GetUiConfigHook = (params: GetUiConfigHookParams) => GetUiConfigHookResult

export const useUiConfigQuery: GetUiConfigHook = ({ skip }) => {
    const agencyId: number | null = useSelector((state: RootState) => state.user?.userInfo?.agencyId)

    return useQuery({
        queryKey: ["uiConfig", agencyId],
        queryFn: async () => {
            log.debug("Fetching UI config")

            const response: UiConfigDTO = await api.get(URL)
            return response
        },
        enabled: !skip,
    })
}
