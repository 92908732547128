/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from "../runtime"
import { ContainerWidgetDTOFromJSONTyped } from "./ContainerWidgetDTO"
import { HistoricalComparisonBarChartWidgetDTOFromJSONTyped } from "./HistoricalComparisonBarChartWidgetDTO"
import { HistoricalComparisonCardWidgetDTOFromJSONTyped } from "./HistoricalComparisonCardWidgetDTO"
import { LineChartWidgetDTOFromJSONTyped } from "./LineChartWidgetDTO"
import { MetricPerformanceCardWidgetDTOFromJSONTyped } from "./MetricPerformanceCardWidgetDTO"
import { MetricToggleWidgetDTOFromJSONTyped } from "./MetricToggleWidgetDTO"
import { MultiBarChartWidgetDTOFromJSONTyped } from "./MultiBarChartWidgetDTO"
import { PeriodWidgetDTOFromJSONTyped } from "./PeriodWidgetDTO"
import { PieChartWidgetDTOFromJSONTyped } from "./PieChartWidgetDTO"
import { PreviousPeriodWidgetDTOFromJSONTyped } from "./PreviousPeriodWidgetDTO"
import { ReportingDimensionToggleWidgetDTOFromJSONTyped } from "./ReportingDimensionToggleWidgetDTO"
import { ReportingMethodToggleWidgetDTOFromJSONTyped } from "./ReportingMethodToggleWidgetDTO"
import { ScatterPlotDashboardsWidgetDTOFromJSONTyped } from "./ScatterPlotDashboardsWidgetDTO"
import { TabbedWidgetDTOFromJSONTyped } from "./TabbedWidgetDTO"
import { TextWidgetDTOFromJSONTyped } from "./TextWidgetDTO"
import { TimeBasedBarChartWidgetDTOFromJSONTyped } from "./TimeBasedBarChartWidgetDTO"
import type { WidgetDTOSettings } from "./WidgetDTOSettings"
import { WidgetDTOSettingsFromJSON, WidgetDTOSettingsFromJSONTyped, WidgetDTOSettingsToJSON } from "./WidgetDTOSettings"

/**
 *
 * @export
 * @interface WidgetDTO
 */
export interface WidgetDTO {
    /**
     *
     * @type {string}
     * @memberof WidgetDTO
     */
    id: string
    /**
     *
     * @type {WidgetDTOSettings}
     * @memberof WidgetDTO
     */
    settings: WidgetDTOSettings
    /**
     *
     * @type {string}
     * @memberof WidgetDTO
     */
    type: WidgetDTOTypeEnum
}

/**
 * @export
 */
export const WidgetDTOTypeEnum = {
    SCATTER_PLOT_DASHBOARDS_WIDGET: "ScatterPlotDashboardsWidget",
    CONTAINER_WIDGET: "ContainerWidget",
    TEXT_WIDGET: "TextWidget",
    TABBED_WIDGET: "TabbedWidget",
    LINE_CHART_WIDGET: "LineChartWidget",
    MULTI_BAR_CHART_WIDGET: "MultiBarChartWidget",
    TIME_BASED_BAR_CHART_WIDGET: "TimeBasedBarChartWidget",
    HISTORICAL_COMPARISON_BAR_CHART_WIDGET: "HistoricalComparisonBarChartWidget",
    HISTORICAL_COMPARISON_CARD_WIDGET: "HistoricalComparisonCardWidget",
    METRIC_PERFORMANCE_CARD_WIDGET: "MetricPerformanceCardWidget",
    PIE_CHART_WIDGET: "PieChartWidget",
    PERIOD_WIDGET: "PeriodWidget",
    PREVIOUS_PERIOD_WIDGET: "PreviousPeriodWidget",
    METRIC_TOGGLE_WIDGET: "MetricToggleWidget",
    REPORTING_DIMENSION_TOGGLE_WIDGET: "ReportingDimensionToggleWidget",
    REPORTING_METHOD_TOGGLE_WIDGET: "ReportingMethodToggleWidget",
} as const
export type WidgetDTOTypeEnum = (typeof WidgetDTOTypeEnum)[keyof typeof WidgetDTOTypeEnum]

/**
 * Check if a given object implements the WidgetDTO interface.
 */
export function instanceOfWidgetDTO(value: object): value is WidgetDTO {
    if (!("id" in value) || value["id"] === undefined) return false
    if (!("settings" in value) || value["settings"] === undefined) return false
    if (!("type" in value) || value["type"] === undefined) return false
    return true
}

export function WidgetDTOFromJSON(json: any): WidgetDTO {
    return WidgetDTOFromJSONTyped(json, false)
}

export function WidgetDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): WidgetDTO {
    if (json == null) {
        return json
    }
    if (!ignoreDiscriminator) {
        if (json["type"] === "ContainerWidgetDTO") {
            return ContainerWidgetDTOFromJSONTyped(json, true)
        }
        if (json["type"] === "HistoricalComparisonBarChartWidgetDTO") {
            return HistoricalComparisonBarChartWidgetDTOFromJSONTyped(json, true)
        }
        if (json["type"] === "HistoricalComparisonCardWidgetDTO") {
            return HistoricalComparisonCardWidgetDTOFromJSONTyped(json, true)
        }
        if (json["type"] === "LineChartWidgetDTO") {
            return LineChartWidgetDTOFromJSONTyped(json, true)
        }
        if (json["type"] === "MetricPerformanceCardWidgetDTO") {
            return MetricPerformanceCardWidgetDTOFromJSONTyped(json, true)
        }
        if (json["type"] === "MetricToggleWidgetDTO") {
            return MetricToggleWidgetDTOFromJSONTyped(json, true)
        }
        if (json["type"] === "MultiBarChartWidgetDTO") {
            return MultiBarChartWidgetDTOFromJSONTyped(json, true)
        }
        if (json["type"] === "PeriodWidgetDTO") {
            return PeriodWidgetDTOFromJSONTyped(json, true)
        }
        if (json["type"] === "PieChartWidgetDTO") {
            return PieChartWidgetDTOFromJSONTyped(json, true)
        }
        if (json["type"] === "PreviousPeriodWidgetDTO") {
            return PreviousPeriodWidgetDTOFromJSONTyped(json, true)
        }
        if (json["type"] === "ReportingDimensionToggleWidgetDTO") {
            return ReportingDimensionToggleWidgetDTOFromJSONTyped(json, true)
        }
        if (json["type"] === "ReportingMethodToggleWidgetDTO") {
            return ReportingMethodToggleWidgetDTOFromJSONTyped(json, true)
        }
        if (json["type"] === "ScatterPlotDashboardsWidgetDTO") {
            return ScatterPlotDashboardsWidgetDTOFromJSONTyped(json, true)
        }
        if (json["type"] === "TabbedWidgetDTO") {
            return TabbedWidgetDTOFromJSONTyped(json, true)
        }
        if (json["type"] === "TextWidgetDTO") {
            return TextWidgetDTOFromJSONTyped(json, true)
        }
        if (json["type"] === "TimeBasedBarChartWidgetDTO") {
            return TimeBasedBarChartWidgetDTOFromJSONTyped(json, true)
        }
    }
    return {
        id: json["id"],
        settings: WidgetDTOSettingsFromJSON(json["settings"]),
        type: json["type"],
    }
}

export function WidgetDTOToJSON(value?: WidgetDTO | null): any {
    if (value == null) {
        return value
    }
    return {
        id: value["id"],
        settings: WidgetDTOSettingsToJSON(value["settings"]),
        type: value["type"],
    }
}
