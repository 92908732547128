import LaunchRoundedIcon from "@mui/icons-material/LaunchRounded"
import { DialogContentText, Link, List, ListItem, ListItemText, Typography, styled } from "@mui/material"
import { CustomDialog } from "layout/components/CustomDialog"
import Separator from "layout/components/Separator"
import React, { type JSX } from "react"
import { FONT_WEIGHT_SEMI_BOLD } from "styles/theme/constants"

const MODAL_WIDTH = 650

export interface SupportDialogProps {
    open: boolean
    onClose: () => void
}

export const SupportDialog = ({ open, onClose }: SupportDialogProps): JSX.Element => {
    const content = (
        <StyledDialogContentText>
            <Typography sx={{ fontWeight: FONT_WEIGHT_SEMI_BOLD }}>
                The Exactag Help Center and support ticketing platform are available at{" "}
                <Link href="https://ticket.exactag.com/" target="_blank">
                    ticket.exactag.com
                </Link>
                .
            </Typography>
            <Typography>You can create your own account using the sign up option.</Typography>

            <Separator />

            <Typography sx={{ fontWeight: FONT_WEIGHT_SEMI_BOLD }}>Available Resources:</Typography>

            <List>
                <ListItem>
                    <ListItemText>
                        <Link underline="none" href="https://ticket.exactag.com/hc/en-us" target="_blank">
                            <LaunchRoundedIcon fontSize="inherit" /> All Help Center articles
                        </Link>
                    </ListItemText>
                </ListItem>
                <ListItem>
                    <ListItemText>
                        <Link underline="none" href="https://ticket.exactag.com/hc/en-us/requests/new" target="_blank">
                            <LaunchRoundedIcon fontSize="inherit" /> Submit a new support request
                        </Link>
                    </ListItemText>
                </ListItem>
                <ListItem>
                    <ListItemText>
                        <Link underline="none" href="https://ticket.exactag.com/hc/en-us/requests" target="_blank">
                            <LaunchRoundedIcon fontSize="inherit" /> Your support requests
                        </Link>
                    </ListItemText>
                </ListItem>
            </List>

            <Separator />

            <Typography>
                If you encounter any problems or prefer to submit a support request via email, please contact{" "}
                <Link href="mailto:support@exactag.com">support@exactag.com</Link>.
            </Typography>
        </StyledDialogContentText>
    )

    return (
        <CustomDialog
            open={open}
            header="Support"
            content={content}
            footer={{ kind: "none" }}
            modalWidth={MODAL_WIDTH}
            onClose={onClose}
        />
    )
}

const StyledDialogContentText = styled(DialogContentText)(({ theme }) => ({
    "& .MuiList-root": {
        padding: 0,
    },
    "& .MuiListItem-root": {
        padding: "2px",
    },
    "& .MuiList-root .MuiLink-root ": {
        display: "flex",
        alignItems: "center",
    },
    "& .MuiList-root .MuiLink-root .MuiSvgIcon-root": {
        marginRight: theme.spacing(1),
    },
}))
