import React, { useMemo } from "react"
import { useSelector } from "react-redux"
import { RootState } from "shared/redux/store"
import { useUiConfigQuery } from "shared/uiconfig/useUiConfigQuery"
import CustomTheme from "styles/theme/CustomTheme"

export type BackendProvidedCustomizedThemeProps = {
    paletteMode?: "light" | "dark"
    children: React.ReactNode
}

export const BackendProvidedCustomizedTheme = ({
    paletteMode = "light",
    children,
}: BackendProvidedCustomizedThemeProps) => {
    // Step 1: Is the user logged in via second factor? We can't get custom theme config before that.
    const loggedInViaSecondFactor = useSelector((state: RootState) => state.authentication.loggedInViaSecondFactor)
    const skipUiConfigQuery = !loggedInViaSecondFactor

    // Step 2: Get the config from the backend if the user is logged in.
    const uiConfig = useUiConfigQuery({ skip: skipUiConfigQuery })

    const paletteOptionsOverrides = useMemo(() => {
        if (!uiConfig.isSuccess) {
            return {}
        }
        return {
            topBar: {
                backgroundColor: uiConfig.data.topToolbarBackgroundColor,
            },
        }
    }, [uiConfig.isSuccess, uiConfig.data])

    return (
        <CustomTheme paletteMode={paletteMode} paletteOptionsOverrides={paletteOptionsOverrides}>
            {children}
        </CustomTheme>
    )
}
