import { PingDTO } from "generated/models"
import { get } from "shared/service"
import UrlService from "shared/service/url.service"

/**
 * Provides system services backed by ApiSystemController in ui-backend
 */
export default class SystemService {
    static ping(): Promise<PingDTO> {
        return get("/api/user/system/ping", {}, false)
    }
}
