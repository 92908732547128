import { ExportTaskService } from "domain/exporttask/ExportTaskService"
import { ExportTaskState } from "domain/exporttask/types"
import { ExportTaskDTO } from "generated/models"
import { useExportCenterContext } from "layout/MainLayout/ExportCenter/ExportCenterContext"
import * as React from "react"
import { useEffect } from "react"
import { log } from "shared/util/log"

export const ExportCenterPolling = () => {
    const exportCenterContext = useExportCenterContext()
    const { tasks: contextTasks, setPolling, invalidateQuery } = exportCenterContext
    const { data: currentTasks } = exportCenterContext.useLoadExportTaskList()

    const filterPendingOrInProgressTasks = (tasks: ExportTaskDTO[]): ExportTaskDTO[] => {
        return tasks.filter(
            (task) => task.state === ExportTaskState.PENDING || task.state === ExportTaskState.IN_PROGRESS,
        )
    }

    const filterCompletedTasks = (tasks: ExportTaskDTO[]): ExportTaskDTO[] => {
        return tasks.filter((task) => task.state === ExportTaskState.COMPLETED)
    }

    const areListsDifferent = (tasksFromState: ExportTaskDTO[], currentTasks: ExportTaskDTO[]): boolean => {
        const tasksFromStateIds = tasksFromState.map((task) => task.id)
        const currentTasksHasNewTasks = currentTasks.some((task) => !tasksFromStateIds.includes(task.id))
        if (currentTasksHasNewTasks) {
            return true
        }

        const currentTaskIds = currentTasks.map((task) => task.id)
        const currentTasksMissSomeTasks = tasksFromState.some((task) => !currentTaskIds.includes(task.id))
        if (currentTasksMissSomeTasks) {
            return true
        }

        const someTasksHaveDifferentState = tasksFromState.some((task) => {
            const currentTask = currentTasks.find((currentTask) => currentTask.id === task.id)
            return task.state !== currentTask.state
        })

        return someTasksHaveDifferentState
    }

    const getCompletedTasks = (taskFromState: ExportTaskDTO[], currentTasks: ExportTaskDTO[]): ExportTaskDTO[] => {
        const pendingIds = filterPendingOrInProgressTasks(taskFromState).map((task) => task.id)
        const allCompleted = filterCompletedTasks(currentTasks)

        return allCompleted.filter((task) => pendingIds.includes(task.id))
    }

    const pollExportCenter = (currentTasks: ExportTaskDTO[]) => {
        const thereAreSomeDifferences = areListsDifferent(contextTasks, currentTasks)
        if (thereAreSomeDifferences) {
            exportCenterContext.setTasks(currentTasks)
        }

        const completedTasks = getCompletedTasks(contextTasks, currentTasks)
        completedTasks.forEach((completedTask, index) => {
            setTimeout(() => {
                ExportTaskService.downloadExportTaskResult(completedTask)
            }, 1000 * index)
        })

        const runningTasksCount = filterPendingOrInProgressTasks(currentTasks).length
        if (runningTasksCount > 0) {
            setPolling(true)
        } else {
            invalidateQuery(false)
            setPolling(false)
        }
    }

    useEffect(() => {
        if (currentTasks !== undefined) {
            log.info("currentTasks length", currentTasks.length)
            pollExportCenter(currentTasks)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(currentTasks)])

    return <React.Fragment />
}
