import { ArrowBack } from "@mui/icons-material"
import LockOutlinedIcon from "@mui/icons-material/LockOutlined"
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined"
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined"
import { Alert, AlertColor, Button, Container, IconButton, InputAdornment, TextField } from "@mui/material"
import Grid from "@mui/material/Grid2"
import { passwordResetService } from "domain/password/service"
import { FormikProps, useFormik } from "formik"
import { PasswordFormResponseDTO } from "generated/models"
import PublicLayout from "layout/PublicLayout/PublicLayout"
import React, { useState } from "react"
import { useParams } from "react-router"
import { useNavigationContext } from "shared/NavigationContext"
import * as Yup from "yup"
import { ObjectSchema } from "yup"

interface PasswordResetConfirmationValues {
    password1: string
    password2: string
}

type PasswordResetConfirmationState =
    | { initiated: false }
    | { initiated: true; successful: boolean; message: React.ReactNode; alertColor: AlertColor }

const passwordResetConfirmationSchema: ObjectSchema<PasswordResetConfirmationValues> = Yup.object().shape({
    password1: Yup.string()
        .min(15, "Password must be at least 15 characters")
        .required("Please enter your new password"),
    password2: Yup.string()
        .oneOf([Yup.ref("password1"), null], "Passwords must match")
        .required("Please confirm your new password"),
})

const PasswordResetConfirmationFormPage = () => {
    const { token } = useParams()

    const navigationContext = useNavigationContext()
    const [passwordResetConfirmationState, setPasswordResetConfirmationState] =
        useState<PasswordResetConfirmationState>({
            initiated: false,
        })
    const [showPassword, setShowPassword] = React.useState(false)

    const formik = useFormik<PasswordResetConfirmationValues>({
        initialValues: { password1: "", password2: "" },
        validationSchema: passwordResetConfirmationSchema,
        onSubmit: (values) =>
            passwordResetService
                .reset(token, values.password1, values.password2)
                .then(handleFormResponse, handleFormResponse),
    })

    const handleClickShowPassword = () => setShowPassword((show) => !show)

    const handleFormResponse = (formResponse: PasswordFormResponseDTO) => {
        if (formResponse.successful) {
            setPasswordResetConfirmationState({
                initiated: true,
                successful: true,
                message: "Your password has been updated.",
                alertColor: "success",
            })
        } else {
            let errorsMsg: string
            if (formResponse.errorMsg) {
                errorsMsg = formResponse.errorMsg
            } else if (formResponse.errors) {
                const messages = []
                for (const name in formResponse.errors) {
                    messages.push(formResponse.errors[name])
                }
                errorsMsg = messages.join(". ")
            } else {
                errorsMsg = "An unknown error happened"
            }

            setPasswordResetConfirmationState({
                initiated: true,
                successful: false,
                message: errorsMsg,
                alertColor: "error",
            })
        }
    }

    const handleBackToLogin = () => {
        navigationContext.redirectToReferrer(false)
    }

    const anyFieldHasError = Object.keys(formik.errors).length > 0

    return (
        <PublicLayout>
            <Container>
                <div className={"content-body standalone-form password-reset-form"}>
                    <h3>Reset your password</h3>

                    {passwordResetConfirmationState.initiated && (
                        <Alert className={"form-response-alert"} color={passwordResetConfirmationState.alertColor}>
                            {passwordResetConfirmationState.message}
                        </Alert>
                    )}

                    <form onSubmit={formik.handleSubmit}>
                        <Grid container justifyContent="center" spacing={2}>
                            <Grid size={12}>
                                <PasswordField
                                    name="password1"
                                    placeholder="New password"
                                    formik={formik}
                                    showPassword={showPassword}
                                    handleClickShowPassword={handleClickShowPassword}
                                />
                            </Grid>
                            <Grid size={12}>
                                <PasswordField
                                    name="password2"
                                    placeholder="Confirm new password"
                                    formik={formik}
                                    showPassword={showPassword}
                                    handleClickShowPassword={handleClickShowPassword}
                                />
                            </Grid>
                            <Grid size={6}>
                                <Button
                                    fullWidth
                                    variant="outlined"
                                    size="large"
                                    onClick={handleBackToLogin}
                                    className={"btn-back-to-login"}
                                >
                                    <ArrowBack />
                                    Back to Login
                                </Button>
                            </Grid>
                            <Grid size={6}>
                                <Button
                                    fullWidth
                                    type="submit"
                                    variant="contained"
                                    loading={formik.isSubmitting}
                                    size={"large"}
                                    disabled={anyFieldHasError}
                                >
                                    Reset Password
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </div>
            </Container>
        </PublicLayout>
    )
}

interface PasswordFieldProps {
    name: string
    placeholder: string
    formik: FormikProps<PasswordResetConfirmationValues>
    showPassword: boolean
    handleClickShowPassword: () => void
}

const PasswordField = ({ name, placeholder, formik, showPassword, handleClickShowPassword }: PasswordFieldProps) => (
    <TextField
        type={showPassword ? "text" : "password"}
        name={name}
        value={formik.values[name]}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched[name] && Boolean(formik.errors[name])}
        helperText={formik.touched[name] && formik.errors[name]}
        InputProps={{
            startAdornment: (
                <InputAdornment position="start">
                    <LockOutlinedIcon style={{ color: "rgba(0,0,0,.25)" }} />
                </InputAdornment>
            ),
            endAdornment: (
                <InputAdornment position="end">
                    <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={(event) => event.preventDefault()}
                        edge="end"
                    >
                        {showPassword ? (
                            <VisibilityOffOutlinedIcon style={{ color: "rgba(0,0,0,.25)" }} />
                        ) : (
                            <VisibilityOutlinedIcon style={{ color: "rgba(0,0,0,.25)" }} />
                        )}
                    </IconButton>
                </InputAdornment>
            ),
        }}
        placeholder={placeholder}
        fullWidth
    />
)

export default PasswordResetConfirmationFormPage
