import { SupportDialog } from "./SupportDialog"
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined"
import IconButton from "@mui/material/IconButton"
import { NotificationType } from "domain/notification/NotificationService"
import { notificationActions } from "domain/notification/redux/load.notification.actions"
import { TopBarTooltip } from "layout/MainLayout/TopBar/TopBarTooltip"
import React from "react"
import { useAnalytics } from "shared/analytics/AnalyticsContext"

export const Support = () => {
    const [open, setOpen] = React.useState(false)
    const analyticsService = useAnalytics()

    const onClick = () => {
        setOpen(true)
        analyticsService.trackButtonClick("Help Center & Support")
    }

    return (
        <>
            <TopBarTooltip title={"Help Center & Support"}>
                <IconButton color="inherit" onClick={onClick}>
                    <HelpOutlineOutlinedIcon />
                </IconButton>
            </TopBarTooltip>
            <SupportDialog open={open} onClose={() => setOpen(false)} />
        </>
    )
}
