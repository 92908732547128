import Box from "@mui/material/Box"
import CircularProgress from "@mui/material/CircularProgress"
// Material UI imports
import Grid from "@mui/material/Grid2"
import { MediaPlanEfficiencyChart } from "domain/adspend-optimizer/component/efficiency"
import {
    AdSpendOptimizerContext,
    CHANNEL_DIMENSION_IDENTIFIER,
    SUB_CAMPAIGN_DIMENSION_IDENTIFIER,
} from "domain/adspend-optimizer/context/AdSpendOptimizerContext"
import { ContentBox } from "domain/core/component/ContentBox"
import { SelectComponent } from "domain/filter/component"
import { GridDataRowDTO } from "domain/types"
import { DimensionValueListDTO } from "generated/models"
import { ColumnResponseDTO, SelectFormElementDTO } from "generated/models"
import React, { Fragment, useContext, useEffect, useState } from "react"
import * as R from "remeda"
import { capitalizeEnum } from "shared/util/util"

export const MediaPlanEfficiencyWrapper = ({ mediaPlanType }) => {
    const asoContext = useContext(AdSpendOptimizerContext)

    const [mediaPlanData, setMediaPlanData] = useState<ColumnResponseDTO[]>([])
    const [selectedMediaPlans, setSelectedMediaPlans] = useState<number[]>([])
    const [availableSelectedCharts, setAvailableSelectedCharts] = useState<number[]>([])
    const [efficiencyData, setEfficiencyData] = useState({
        channel: {},
        sub_campaign: {},
    })

    useEffect(() => {
        switch (mediaPlanType) {
            case CHANNEL_DIMENSION_IDENTIFIER: {
                setMediaPlanData(
                    asoContext.channelData.map((row) => ({
                        value: Number(row.channel.value),
                        name: row.channel.name,
                    })),
                )
                const orderedChannelData: GridDataRowDTO[] = R.pipe(
                    asoContext.channelData,
                    R.sortBy((row) => -Number(row.adspend_costs)),
                )
                setSelectedMediaPlans(
                    orderedChannelData.filter((item, index) => index < 6).map((item) => item.channel.value as number),
                )
                break
            }
            case SUB_CAMPAIGN_DIMENSION_IDENTIFIER: {
                setMediaPlanData(
                    asoContext.subCampaignData.map((row) => ({
                        value: Number(row.sub_campaign.value),
                        name: row.sub_campaign.name,
                    })),
                )
                const orderedSubCampaignData: GridDataRowDTO[] = R.pipe(
                    asoContext.subCampaignData,
                    R.sortBy((row) => -Number(row.adspend_costs)),
                )
                setSelectedMediaPlans(
                    orderedSubCampaignData
                        .filter((item, index) => index < 6)
                        .map((item) => item.sub_campaign.value as number),
                )
                break
            }
        }
        // TODO: is it safe to add the missing dependencies?
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mediaPlanType])

    useEffect(() => {
        selectedMediaPlans.forEach((mediaPlanId) => {
            if (!efficiencyData[mediaPlanType][mediaPlanId])
                asoContext.fetchEfficiencyData(mediaPlanId).then((data) => {
                    const newEfficiencyData = { ...efficiencyData }
                    newEfficiencyData[mediaPlanType][mediaPlanId] = data.dataSet
                    setEfficiencyData(newEfficiencyData)
                })
        })
        // TODO: is it safe to add the missing dependencies?
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedMediaPlans])

    useEffect(() => {
        const ids: number[] = Object.keys(efficiencyData[mediaPlanType])
            .filter((key) => selectedMediaPlans.includes(Number(key)))
            .map((id) => Number(id))
        setAvailableSelectedCharts(ids)
    }, [efficiencyData, mediaPlanType, selectedMediaPlans])

    return (
        <Fragment>
            <div className="comparison-header media-plan-selection-header">
                <div style={{ textAlign: "left" }}>
                    <div className="media-plan-selector">
                        <h3>Efficiency per {capitalizeEnum(mediaPlanType)}:</h3>
                        <div style={{ flexGrow: 23 }}>
                            <SelectComponent
                                size={"medium"}
                                componentStyle={{ margin: 0, padding: 0, marginRight: 10 }}
                                selectStyle={{ width: "100%", margin: 0 }}
                                label={""}
                                filter={{
                                    value: selectedMediaPlans,
                                    selectFormElement: {} as SelectFormElementDTO,
                                    filterEntries: {
                                        entries: mediaPlanData,
                                    } as DimensionValueListDTO,
                                }}
                                onChange={(rest, values) => {
                                    setSelectedMediaPlans(values as number[])
                                }}
                                multiple={true}
                            />
                        </div>
                    </div>
                </div>
            </div>

            {selectedMediaPlans.length > 0 && (
                <ContentBox>
                    {asoContext && asoContext.efficiencyChartsLoading ? (
                        <Box display="flex" justifyContent="center" alignItems="center" width="100%" py={3}>
                            <CircularProgress />
                        </Box>
                    ) : (
                        <>
                            <Grid container>
                                <Box mb={3}>
                                    The efficiency curves below represent the relationship between costs (x-axis) and
                                    conversions (y-axis) for each channel. The optimal budget is derived from the
                                    efficiency curve of each channel or sub campaign. The optimum is reached when
                                    investing an additional euro into a channel or sub campaign will not lead to an
                                    over-proportioned growth in sales, or the derivation of the curve.
                                </Box>
                                <ChartLegend />
                            </Grid>
                            <Grid container>
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignContent: "flex-start",
                                        justifyContent: "space-around",
                                        flexWrap: "wrap",
                                        width: "100%",
                                    }}
                                >
                                    {efficiencyData &&
                                        mediaPlanData
                                            .filter((mp) => availableSelectedCharts.includes(mp.value))
                                            .map((mplan) => {
                                                const mediaPlanName =
                                                    mediaPlanData.find((mp) => mp.value === Number(mplan.value)).name ||
                                                    ""
                                                const historic =
                                                    asoContext.historicPerformanceData &&
                                                    asoContext.historicPerformanceData
                                                        .filter((data) => data.mediaPlanType === mediaPlanType)
                                                        .find((pd) => pd.mediaPlanId === Number(mplan.value))
                                                const recommended =
                                                    asoContext.recommendedScenarioData &&
                                                    asoContext.recommendedScenarioData
                                                        .filter((data) => data.mediaPlanType === mediaPlanType)
                                                        .find((pd) => pd.mediaPlanId === Number(mplan.value))
                                                const data = efficiencyData[mediaPlanType][mplan.value]
                                                return data ? (
                                                    <MediaPlanEfficiencyChart
                                                        mediaPlanName={mediaPlanName}
                                                        efficiencyData={data}
                                                        historicPerformanceData={historic}
                                                        recommendedScenarioData={recommended}
                                                        key={`efficiencyChart_${mediaPlanName}_${mplan.value}`}
                                                    />
                                                ) : undefined
                                            })}
                                </Box>
                            </Grid>
                        </>
                    )}
                </ContentBox>
            )}
        </Fragment>
    )
}

const ChartLegend = () => (
    <div className="media-plan-efficiency-charts-legends">
        <div className="media-plan-efficiency-charts-legend">
            <div className="visual-indicator low-reliability" />
            <div className="legend-text">
                <strong>Low Prediction Reliability</strong>
                <br />
                These data points are outside of observed data points and therefore represent risky, less stable
                predictions.
            </div>
        </div>
        <div className="media-plan-efficiency-charts-legend">
            <div className="visual-indicator high-reliability" />
            <div className="legend-text">
                <strong>High Prediction Reliability</strong>
                <br />
                These data points are inside our range of observed values should represent a better overall prediction.
            </div>
        </div>
        <div className="media-plan-efficiency-charts-legend">
            <div className="visual-indicator historic">
                <div className="indicator-circle-historic" />
            </div>
            <div className="legend-text">
                <strong>Historic Budget</strong>
                <br />
                This data point represents the historical average budget that has been observed in this channel.
            </div>
        </div>
        <div className="media-plan-efficiency-charts-legend">
            <div className="visual-indicator recommended">
                <div className="indicator-circle-recommended" />
            </div>
            <div className="legend-text">
                <strong>Recommended Budget</strong>
                <br />
                This data point represents the Recommended scenario's budget for this channel.
            </div>
        </div>
    </div>
)
