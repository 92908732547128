import { Add } from "@mui/icons-material"
import {
    Box,
    ButtonBase,
    Card,
    CardContent,
    CardMedia,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Typography,
} from "@mui/material"
import { useDashboardLayout } from "domain/dashboard/DashboardLayout/DashboardLayoutContext"
import DimensionService, { asDataColumnIdentifier } from "domain/dimension/service/DimensionService"
import { WidgetMap } from "domain/widget/WidgetRenderer"
import { PieChartDashboardsWidgetSettingsDTOStyleEnum, WidgetDTOTypeEnum } from "generated/models"
import React, { useState } from "react"

type AddOption = {
    label: string
    src: string
}

const WidgetConfig = {
    [WidgetDTOTypeEnum.CONTAINER_WIDGET]: {
        label: "Container Widget",
        src: "/ui/widgets/widget-placeholder-image.png",
    },
    [WidgetDTOTypeEnum.TEXT_WIDGET]: {
        label: "Text widget",
        src: "/ui/widgets/text-widget.jpeg",
    },
    [WidgetDTOTypeEnum.MULTI_BAR_CHART_WIDGET]: {
        label: "Multi Bar chart",
        src: "/ui/widgets/bar-chart.png",
    },
    [WidgetDTOTypeEnum.TIME_BASED_BAR_CHART_WIDGET]: {
        label: "Time-based Bar chart",
        src: "/ui/widgets/bar-chart.png",
    },
    [WidgetDTOTypeEnum.LINE_CHART_WIDGET]: {
        label: "Line chart",
        src: "/ui/widgets/line-chart.png",
    },
    [WidgetDTOTypeEnum.PIE_CHART_WIDGET]: [
        {
            label: "Pie chart",
            src: "/ui/widgets/widget-placeholder-image.png",
            settings: {
                style: "PIE" as PieChartDashboardsWidgetSettingsDTOStyleEnum,
            },
        },
        {
            label: "Doughnut chart",
            src: "/ui/widgets/widget-placeholder-image.png",
            settings: {
                style: "DOUGHNUT" as PieChartDashboardsWidgetSettingsDTOStyleEnum,
            },
        },
        {
            label: "Half Doughnut chart",
            src: "/ui/widgets/widget-placeholder-image.png",
            settings: {
                style: "HALF_DOUGHNUT" as PieChartDashboardsWidgetSettingsDTOStyleEnum,
            },
        },
        {
            label: "Rose chart",
            src: "/ui/widgets/widget-placeholder-image.png",
            settings: {
                style: "ROSE" as PieChartDashboardsWidgetSettingsDTOStyleEnum,
            },
        },
    ],
    [WidgetDTOTypeEnum.TABBED_WIDGET]: {
        label: "Tabbed widget",
        src: "/ui/widgets/tabbed-widget.png",
    },
    [WidgetDTOTypeEnum.PERIOD_WIDGET]: {
        label: "Period",
        src: "/ui/widgets/widget-placeholder-image.png",
    },
    [WidgetDTOTypeEnum.PREVIOUS_PERIOD_WIDGET]: {
        label: "Previous period",
        src: "/ui/widgets/widget-placeholder-image.png",
    },
    [WidgetDTOTypeEnum.METRIC_TOGGLE_WIDGET]: {
        label: "Metric toggle",
        src: "/ui/widgets/widget-placeholder-image.png",
    },
    [WidgetDTOTypeEnum.REPORTING_DIMENSION_TOGGLE_WIDGET]: {
        label: "Reporting dimension toggle",
        src: "/ui/widgets/widget-placeholder-image.png",
    },
    [WidgetDTOTypeEnum.REPORTING_METHOD_TOGGLE_WIDGET]: {
        label: "Reporting method toggle",
        src: "/ui/widgets/widget-placeholder-image.png",
    },
    [WidgetDTOTypeEnum.HISTORICAL_COMPARISON_BAR_CHART_WIDGET]: {
        label: "Historical Comparison Bar chart",
        src: "/ui/widgets/widget-placeholder-image.png",
    },
    [WidgetDTOTypeEnum.HISTORICAL_COMPARISON_CARD_WIDGET]: {
        label: "Historical Comparison Card",
        src: "/ui/widgets/widget-placeholder-image.png",
    },
    [WidgetDTOTypeEnum.METRIC_PERFORMANCE_CARD_WIDGET]: {
        label: "Metric Performance Card",
        src: "/ui/widgets/widget-placeholder-image.png",
    },
    // TODO: Replace with the real scatter plot widget image
    [WidgetDTOTypeEnum.SCATTER_PLOT_DASHBOARDS_WIDGET]: {
        label: "Scatter Plot",
        src: "/ui/widgets/widget-placeholder-image.png",
    },
    DataGrid: {
        label: "DataGrid",
        src: "/ui/widgets/widget-placeholder-image.png",
    },
} satisfies Record<keyof WidgetMap, any>

type WidgetItemProps<Type extends keyof WidgetMap> = AddOption & {
    type: Type
    onClick: (type: Type) => void
}

const WidgetItem = <Type extends keyof WidgetMap>(props: WidgetItemProps<Type>) => {
    const { type, onClick, label, src } = props

    return (
        <ButtonBase
            onClick={() => onClick(type)}
            sx={{
                display: "flex",
            }}
        >
            <Card
                elevation={1}
                sx={{
                    flex: 1,
                }}
            >
                <CardMedia sx={{ height: 140 }} image={src} />
                <CardContent
                    sx={{
                        p: 0,
                    }}
                >
                    <Typography gutterBottom variant="button" fontWeight={500} component="div">
                        {label}
                    </Typography>
                </CardContent>
            </Card>
        </ButtonBase>
    )
}

type AddWidgetModalProps = {
    enabledWidgets?: Array<keyof WidgetMap>
}

// TODO<Firat>: Where to get initial values?
const AddWidgetModal = (props: AddWidgetModalProps) => {
    const { enabledWidgets } = props
    const [open, setOpen] = useState(false)
    const { addWidget } = useDashboardLayout()

    const renderWidget = (type: keyof WidgetMap) => {
        return !enabledWidgets || enabledWidgets.includes(type)
    }

    return (
        <>
            <IconButton sx={{ zIndex: 10 }} onClick={() => setOpen(true)}>
                <Add />
            </IconButton>
            <Dialog open={open} onClose={() => setOpen(false)} maxWidth="md" PaperProps={{ sx: { width: "100%" } }}>
                <DialogTitle>Add widget</DialogTitle>
                <DialogContent>
                    <Box
                        sx={{
                            display: "grid",
                            gridTemplateColumns: "repeat(4, minmax(0, 1fr))",
                            rowGap: 2,
                            columnGap: 2,
                            px: 4,
                        }}
                    >
                        {renderWidget(WidgetDTOTypeEnum.CONTAINER_WIDGET) && (
                            <WidgetItem
                                {...WidgetConfig[WidgetDTOTypeEnum.CONTAINER_WIDGET]}
                                type={WidgetDTOTypeEnum.CONTAINER_WIDGET}
                                onClick={(type) => {
                                    addWidget(
                                        {
                                            type,
                                            settings: {
                                                layout: [],
                                            },
                                        },
                                        {
                                            w: 4,
                                            h: 40,
                                        },
                                    )
                                    setOpen(false)
                                }}
                            />
                        )}
                        {renderWidget(WidgetDTOTypeEnum.TEXT_WIDGET) && (
                            <WidgetItem
                                {...WidgetConfig[WidgetDTOTypeEnum.TEXT_WIDGET]}
                                type={WidgetDTOTypeEnum.TEXT_WIDGET}
                                onClick={(type) => {
                                    addWidget(
                                        {
                                            type,
                                            settings: {
                                                htmlContent: "",
                                            },
                                        },
                                        {
                                            w: 6,
                                            h: 30,
                                        },
                                    )
                                    setOpen(false)
                                }}
                            />
                        )}
                        {renderWidget(WidgetDTOTypeEnum.MULTI_BAR_CHART_WIDGET) && (
                            <WidgetItem
                                {...WidgetConfig[WidgetDTOTypeEnum.MULTI_BAR_CHART_WIDGET]}
                                type={WidgetDTOTypeEnum.MULTI_BAR_CHART_WIDGET}
                                onClick={(type) => {
                                    addWidget(
                                        {
                                            type,
                                            settings: {
                                                orientation: "horizontal",
                                                primaryDimension: DimensionService.getValueColumn(
                                                    asDataColumnIdentifier("device_type"),
                                                ),
                                                secondaryDimensions: [
                                                    DimensionService.getValueColumn(asDataColumnIdentifier("clicks")),
                                                ],
                                            },
                                        },
                                        {
                                            w: 4,
                                            h: 40,
                                        },
                                    )
                                    setOpen(false)
                                }}
                            />
                        )}
                        {renderWidget(WidgetDTOTypeEnum.TIME_BASED_BAR_CHART_WIDGET) && (
                            <WidgetItem
                                {...WidgetConfig[WidgetDTOTypeEnum.TIME_BASED_BAR_CHART_WIDGET]}
                                type={WidgetDTOTypeEnum.TIME_BASED_BAR_CHART_WIDGET}
                                onClick={(type) => {
                                    addWidget(
                                        {
                                            type,
                                            settings: {
                                                orientation: "horizontal",
                                                primaryDimension: DimensionService.getValueColumn(
                                                    asDataColumnIdentifier("device_type"),
                                                ),
                                                secondaryDimensions: [
                                                    DimensionService.getValueColumn(asDataColumnIdentifier("clicks")),
                                                ],
                                            },
                                        },
                                        {
                                            w: 4,
                                            h: 40,
                                        },
                                    )
                                    setOpen(false)
                                }}
                            />
                        )}
                        {renderWidget(WidgetDTOTypeEnum.HISTORICAL_COMPARISON_BAR_CHART_WIDGET) && (
                            <WidgetItem
                                {...WidgetConfig[WidgetDTOTypeEnum.HISTORICAL_COMPARISON_BAR_CHART_WIDGET]}
                                type={WidgetDTOTypeEnum.HISTORICAL_COMPARISON_BAR_CHART_WIDGET}
                                onClick={(type) => {
                                    addWidget(
                                        {
                                            type,
                                            settings: {
                                                metricIdentifier: DimensionService.getValueColumn(
                                                    asDataColumnIdentifier("clicks"),
                                                ),
                                            },
                                        },
                                        {
                                            w: 4,
                                            h: 40,
                                        },
                                    )
                                    setOpen(false)
                                }}
                            />
                        )}
                        {renderWidget(WidgetDTOTypeEnum.HISTORICAL_COMPARISON_CARD_WIDGET) && (
                            <WidgetItem
                                {...WidgetConfig[WidgetDTOTypeEnum.HISTORICAL_COMPARISON_CARD_WIDGET]}
                                type={WidgetDTOTypeEnum.HISTORICAL_COMPARISON_CARD_WIDGET}
                                onClick={(type) => {
                                    addWidget(
                                        {
                                            type,
                                            settings: {
                                                metricIdentifier: DimensionService.getValueColumn(
                                                    asDataColumnIdentifier("clicks"),
                                                ),
                                            },
                                        },
                                        {
                                            w: 4,
                                            h: 40,
                                        },
                                    )
                                    setOpen(false)
                                }}
                            />
                        )}
                        {renderWidget(WidgetDTOTypeEnum.METRIC_PERFORMANCE_CARD_WIDGET) && (
                            <WidgetItem
                                {...WidgetConfig[WidgetDTOTypeEnum.METRIC_PERFORMANCE_CARD_WIDGET]}
                                type={WidgetDTOTypeEnum.METRIC_PERFORMANCE_CARD_WIDGET}
                                onClick={(type) => {
                                    addWidget(
                                        {
                                            type,
                                            settings: {
                                                metricField: DimensionService.getValueColumn(
                                                    asDataColumnIdentifier("clicks"),
                                                ),
                                            },
                                        },
                                        {
                                            w: 4,
                                            h: 40,
                                        },
                                    )
                                    setOpen(false)
                                }}
                            />
                        )}
                        {renderWidget(WidgetDTOTypeEnum.PIE_CHART_WIDGET) &&
                            WidgetConfig[WidgetDTOTypeEnum.PIE_CHART_WIDGET].map((widget, index) => (
                                <WidgetItem
                                    key={index}
                                    label={widget.label}
                                    src={widget.src}
                                    type={WidgetDTOTypeEnum.PIE_CHART_WIDGET}
                                    onClick={(type) => {
                                        addWidget(
                                            {
                                                type,
                                                settings: {
                                                    style: widget.settings.style,
                                                    dimensionField: DimensionService.getValueColumn(
                                                        asDataColumnIdentifier("device_type"),
                                                    ),
                                                    metricField: DimensionService.getValueColumn(
                                                        asDataColumnIdentifier("clicks"),
                                                    ),
                                                },
                                            },
                                            {
                                                w: 4,
                                                h: 40,
                                            },
                                        )
                                        setOpen(false)
                                    }}
                                />
                            ))}
                        {renderWidget(WidgetDTOTypeEnum.LINE_CHART_WIDGET) && (
                            <WidgetItem
                                {...WidgetConfig[WidgetDTOTypeEnum.LINE_CHART_WIDGET]}
                                type={WidgetDTOTypeEnum.LINE_CHART_WIDGET}
                                onClick={(type) => {
                                    addWidget(
                                        {
                                            type,
                                            settings: {
                                                primaryDimension: DimensionService.getValueColumn(
                                                    asDataColumnIdentifier("daily"),
                                                ),
                                                secondaryDimensions: [
                                                    DimensionService.getValueColumn(
                                                        asDataColumnIdentifier("bounce_visits"),
                                                    ),
                                                    DimensionService.getValueColumn(
                                                        asDataColumnIdentifier("quality_visits"),
                                                    ),
                                                    DimensionService.getValueColumn(
                                                        asDataColumnIdentifier("failed_reach_visits"),
                                                    ),
                                                ],
                                            },
                                        },
                                        {
                                            w: 4,
                                            h: 40,
                                        },
                                    )
                                    setOpen(false)
                                }}
                            />
                        )}
                        {renderWidget(WidgetDTOTypeEnum.TABBED_WIDGET) && (
                            <WidgetItem
                                {...WidgetConfig[WidgetDTOTypeEnum.TABBED_WIDGET]}
                                type={WidgetDTOTypeEnum.TABBED_WIDGET}
                                onClick={(type) => {
                                    addWidget(
                                        {
                                            type,
                                            settings: {
                                                tabs: [
                                                    {
                                                        metricIdentifier: DimensionService.getValueColumn(
                                                            asDataColumnIdentifier("imps"),
                                                        ),
                                                        layout: [],
                                                    },
                                                    {
                                                        metricIdentifier: DimensionService.getValueColumn(
                                                            asDataColumnIdentifier("clicks"),
                                                        ),
                                                        layout: [],
                                                    },
                                                    {
                                                        metricIdentifier: DimensionService.getValueColumn(
                                                            asDataColumnIdentifier("contacts_totp"),
                                                        ),
                                                        layout: [],
                                                    },
                                                    {
                                                        metricIdentifier: DimensionService.getValueColumn(
                                                            asDataColumnIdentifier("pi"),
                                                        ),
                                                        layout: [],
                                                    },
                                                ],
                                            },
                                        },
                                        {
                                            w: 6,
                                            h: 40,
                                        },
                                    )
                                    setOpen(false)
                                }}
                            />
                        )}
                        {renderWidget("DataGrid") && (
                            <WidgetItem
                                {...WidgetConfig["DataGrid"]}
                                type={"DataGrid"}
                                onClick={(type) => {
                                    addWidget(
                                        {
                                            type,
                                            settings: {
                                                simple: false,
                                            },
                                        },
                                        {
                                            w: 12,
                                            h: 40,
                                        },
                                    )
                                    setOpen(false)
                                }}
                            />
                        )}
                        {(
                            [
                                WidgetDTOTypeEnum.PERIOD_WIDGET,
                                WidgetDTOTypeEnum.PREVIOUS_PERIOD_WIDGET,
                                WidgetDTOTypeEnum.METRIC_TOGGLE_WIDGET,
                                WidgetDTOTypeEnum.REPORTING_DIMENSION_TOGGLE_WIDGET,
                                WidgetDTOTypeEnum.REPORTING_METHOD_TOGGLE_WIDGET,
                            ] as const
                        ).map(
                            (w) =>
                                renderWidget(w) && (
                                    <WidgetItem
                                        {...WidgetConfig[w]}
                                        key={w}
                                        type={w}
                                        onClick={(type) => {
                                            addWidget(
                                                {
                                                    type,
                                                    settings: {},
                                                },
                                                {
                                                    w: 3,
                                                    h: 8,
                                                },
                                            )
                                            setOpen(false)
                                        }}
                                    />
                                ),
                        )}
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default AddWidgetModal
