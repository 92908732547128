/**
 * Trigger download dialog for the Blob object
 */
export const triggerDownloadDialog = (blob: Blob, fileName: string) => {
    // Generate a URL for the Blob object
    const fileURL = URL.createObjectURL(blob)

    // Create an anchor tag to trigger download
    const link = document.createElement("a")
    link.href = fileURL
    link.setAttribute("download", fileName)
    document.body.appendChild(link)
    link.click()

    // Clean up by removing the link and revoking the Blob URL
    link.parentNode?.removeChild(link)
    URL.revokeObjectURL(fileURL)
}
