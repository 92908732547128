import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"
import { Box, Fab } from "@mui/material"
import { ArticleService } from "domain/content/ArticleService"
import React, { useEffect, useRef, useState } from "react"
import Spinner from "shared/component/Spinner"
import UrlService from "shared/service/url.service"
import { log } from "shared/util/log"

type Props = {
    path: string
    title: string
    onClick: (path: string) => boolean
}

type ScrollTopProps = {
    anchorRef?: React.RefObject<HTMLDivElement>
}

const ScrollToTop = ({ anchorRef }: ScrollTopProps) => {
    const scrollToTop = () => {
        anchorRef?.current?.scrollIntoView({
            behavior: "smooth",
            block: "center",
        })
    }

    return (
        <Box
            onClick={scrollToTop}
            role="presentation"
            sx={{
                position: "fixed",
                bottom: 32,
                right: 32,
                zIndex: 1,
            }}
        >
            <Fab size="small" aria-label="scroll back to top" color="primary">
                <KeyboardArrowUpIcon />
            </Fab>
        </Box>
    )
}

export const ArticlePage = (props: Props) => {
    const [htmlContent, setHtmlContent] = useState("")
    const [spinning, setSpinning] = useState(true)
    const anchorRef = useRef<HTMLDivElement>(null)

    /**
     * Loads article html content, if the url path changes.
     */
    useEffect(() => {
        setSpinning(true)
        fetchContent()
        // TODO: is it safe to add the missing dependencies?
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.path])

    /**
     * Adds click event listener to all links on the article page
     * and removes the listener, when the component is unmounted
     */
    useEffect(() => {
        executeActionOnEachArticleLink("addEventListener")

        return () => executeActionOnEachArticleLink("removeEventListener")
    })

    /**
     * Adds or removes click listener on all links on the article page
     *
     * @param action
     */
    const executeActionOnEachArticleLink = (action: "addEventListener" | "removeEventListener") => {
        const elements = document.querySelectorAll(".article-content a")

        if (elements && elements.length > 0) {
            elements.forEach((element) => {
                element[action]("click", clickOnInternalLink)
            })
        }
    }

    /**
     * Callback for the click event. It reads the filename of the link and
     * opens the corresponded menu item with the required filename.
     *
     * @param event
     */
    const clickOnInternalLink = (event) => {
        const filename = event.target.href.split("/").pop()

        // try to find the filename in the menu configuration
        const articleFound = props.onClick(filename)

        if (articleFound) {
            event.preventDefault()
            return false
        } else {
            // if article was not found in the menu configuration
            log.debug(`Article '${filename}' was not found`)

            return true
        }
    }

    /**
     * Loads the html content of the article from the backend service
     * and saves it in the [htmlContent] state
     */
    const fetchContent = () => {
        ArticleService.loadArticleContent(props.path).then((content: string) => {
            setHtmlContent(appendBaseUrlBackend(content))
            setSpinning(false)
        })
    }

    /**
     * Changes urls in the img tags of the html htmlContent to absolute paths of the backend service
     *
     * @param htmlContent
     */
    const appendBaseUrlBackend = (htmlContent: string): string => {
        return (
            htmlContent
                .split('src="')
                .join(`src="${UrlService.getUiServiceApiUrl()}/static${props.path}/`)
                // append _blank target, so that external links open in the new window
                .split('href="http')
                .join('target="_blank" href="http')
                .split("href='http")
                .join('target="_blank" href=\'http')
                // append _self target, so that anchor links open in the same window
                .split('href="#')
                .join('target="_self" href="#')
                .split("href='#")
                .join('target="_self" href=\'#')
        )
    }

    return (
        <Spinner spinning={spinning}>
            {spinning ? (
                <div className={"article-content"} />
            ) : (
                <div className={"article-content"}>
                    <div ref={anchorRef} />
                    <ScrollToTop anchorRef={anchorRef} />
                    <Box sx={{ padding: "20px 0 0 10px" }}>
                        <h1>{props.title}</h1>
                        <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
                    </Box>
                </div>
            )}
        </Spinner>
    )
}
