import { ArrowBack } from "@mui/icons-material"
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined"
import { Alert, AlertColor, Button, Container, InputAdornment, TextField } from "@mui/material"
import Grid from "@mui/material/Grid2"
import { passwordResetService } from "domain/password/service"
import { useFormik } from "formik"
import { PasswordFormResponseDTO } from "generated/models"
import PublicLayout from "layout/PublicLayout/PublicLayout"
import React, { useState } from "react"
import { useNavigationContext } from "shared/NavigationContext"
import * as Yup from "yup"
import { ObjectSchema } from "yup"

interface PasswordResetRequestValues {
    userNameOrEmail: string
}

type PasswordResetRequestState =
    | { initiated: false }
    | { initiated: true; successful: boolean; message: React.ReactNode; alertColor: AlertColor }

const passwordResetRequestSchema: ObjectSchema<PasswordResetRequestValues> = Yup.object().shape({
    userNameOrEmail: Yup.string()
        .required("Please input your username or email address")
        .max(255, "Username or email address must not be longer than 255 characters"),
})

const PasswordResetRequestFormPage = () => {
    const navigationContext = useNavigationContext()
    const [passwordResetRequestState, setPasswordResetRequestState] = useState<PasswordResetRequestState>({
        initiated: false,
    })

    const formik = useFormik<PasswordResetRequestValues>({
        initialValues: { userNameOrEmail: "" },
        validationSchema: passwordResetRequestSchema,
        onSubmit: (values) =>
            passwordResetService.requestReset(values.userNameOrEmail).then(handleFormResponse, handleFormResponse),
    })

    const handleFormResponse = (formResponse: PasswordFormResponseDTO) => {
        if (formResponse.successful) {
            setPasswordResetRequestState({
                initiated: true,
                successful: true,
                message: <PasswordResetEmailSent />,
                alertColor: "info",
            })
        } else {
            setPasswordResetRequestState({
                initiated: true,
                successful: false,
                message: <PasswordResetErrorOccurred />,
                alertColor: "error",
            })
        }
    }

    const handleBackToLogin = () => {
        navigationContext.redirectToReferrer(false)
    }

    return (
        <PublicLayout showLogo={true} showFooter={true}>
            <Container>
                <div className={"content-body standalone-form password-reset-request-form"}>
                    {passwordResetRequestState.initiated && (
                        <Alert
                            className="form-response-alert"
                            severity={passwordResetRequestState.alertColor}
                            icon={false}
                        >
                            {passwordResetRequestState.message}
                        </Alert>
                    )}

                    {!passwordResetRequestState.initiated && (
                        <>
                            <h3>Reset Your Password</h3>
                            <div>Please enter your username or email address to reset your password:</div>
                        </>
                    )}

                    <form onSubmit={formik.handleSubmit}>
                        <Grid container justifyContent="center" spacing={2}>
                            <Grid size={12}>
                                {!passwordResetRequestState.initiated && (
                                    <TextField
                                        name="userNameOrEmail"
                                        value={formik.values.userNameOrEmail}
                                        onChange={(event) => {
                                            formik.handleChange(event)
                                            formik.handleBlur(event)
                                        }}
                                        onPaste={(event) => {
                                            setTimeout(() => {
                                                formik.handleChange(event)
                                                formik.handleBlur(event)
                                            }, 300)
                                        }}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.userNameOrEmail && Boolean(formik.errors.userNameOrEmail)}
                                        helperText={formik.touched.userNameOrEmail && formik.errors.userNameOrEmail}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <PersonOutlineOutlinedIcon style={{ color: "rgba(0,0,0,.25)" }} />
                                                </InputAdornment>
                                            ),
                                        }}
                                        disabled={formik.isSubmitting}
                                        placeholder="Username or email address"
                                        fullWidth
                                        autoFocus
                                    />
                                )}
                            </Grid>

                            <Grid size={6}>
                                <Button
                                    fullWidth
                                    variant="outlined"
                                    size="large"
                                    onClick={handleBackToLogin}
                                    className={"btn-back-to-login"}
                                >
                                    <ArrowBack />
                                    Back to Login
                                </Button>
                            </Grid>

                            <Grid size={6}>
                                {!passwordResetRequestState.initiated && (
                                    <Button
                                        fullWidth
                                        type="submit"
                                        variant="contained"
                                        loading={formik.isSubmitting}
                                        size={"large"}
                                        disabled={
                                            !formik.touched.userNameOrEmail || Boolean(formik.errors.userNameOrEmail)
                                        }
                                    >
                                        Reset Password
                                    </Button>
                                )}
                            </Grid>
                        </Grid>
                    </form>
                </div>
            </Container>
        </PublicLayout>
    )
}

const PasswordResetEmailSent = () => {
    return (
        <div>
            <p style={{ padding: "15px 0" }}>
                <b>Please check your inbox for the reset e-mail.</b>
            </p>
            <hr style={{ borderTopStyle: "dashed" }} />
            <p>
                If you <strong>did not</strong> receive the e-mail, please consider the following:
            </p>
            <ul style={{ textAlign: "left" }}>
                <li>Is your username or e-mail address spelled correctly?</li>
                <li>Please ensure your username is case-correct.</li>
                <li>
                    If you entered an e-mail address which is used for multiple user accounts, you will not be able to
                    receive the reset mail. Please enter your username instead.
                </li>
            </ul>
            <p>
                Our help center provides more details about{" "}
                <a
                    target={"_blank"}
                    href={"https://ticket.exactag.com/hc/en-us/articles/4403793146002"}
                    rel="noreferrer"
                >
                    why you might be unable to log in
                </a>{" "}
                and{" "}
                <a
                    target={"_blank"}
                    href={"https://ticket.exactag.com/hc/en-us/articles/4403793305106"}
                    rel="noreferrer"
                >
                    why you might not be receiving the reset mail
                </a>
                . If you keep experiencing issues, please contact{" "}
                <a href="mailto:support@exactag.com">support@exactag.com</a>.
            </p>
        </div>
    )
}

const PasswordResetErrorOccurred = () => {
    return (
        <div>
            <strong>An error occurred while trying to request a password reset.</strong>
            <p>
                This could be caused by requesting a reset too frequently. If that is the case, please wait a little
                while before your next attempt or contact our <a href="mailto:support@exactag.com">support team</a>.
            </p>
        </div>
    )
}

export default PasswordResetRequestFormPage
