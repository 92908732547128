import { DialogContentText } from "@mui/material"
import { AuthenticationState } from "domain/authentication/redux/authentication.slice"
import { passwordExpirationActions } from "domain/password/redux/load.password.expiration.actions"
import { PasswordExpirationState } from "domain/password/redux/password.expiration.slice"
import { UserProfileAction } from "layout/MainLayout/UserMenu/UserProfileAction"
import { CustomDialog } from "layout/components/CustomDialog"
import React, { type JSX } from "react"
import { useSelector } from "react-redux"
import store, { RootState } from "shared/redux/store"

export const PasswordExpirationDialog = (): JSX.Element => {
    const authenticationState: AuthenticationState = useSelector((state: RootState) => state.authentication)
    const passwordExpirationState: PasswordExpirationState = useSelector((state: RootState) => state.passwordExpiration)

    const closeModal = () => {
        passwordExpirationActions.closeDialog()(store.dispatch)
    }

    const openUserSettings = () => {
        closeModal()

        const userId = authenticationState?.user?.id
        UserProfileAction.open(userId)
    }

    return (
        <CustomDialog
            header={"Your password is expiring soon"}
            content={
                <DialogContentText>
                    Your password will expire on {passwordExpirationState.passwordExpirationDate}. Do you want to update
                    it now?
                </DialogContentText>
            }
            footer={{
                kind: "yesNoButton",
                noText: "No",
                yesText: "Yes",
                onYesButtonClick: openUserSettings,
                onNoButtonClick: closeModal,
                submitting: false,
            }}
            onClose={closeModal}
            open={passwordExpirationState.passwordExpirationDate !== undefined}
        />
    )
}
