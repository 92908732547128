import { BarChart, HorizontalRule, TrendingDown, TrendingUp } from "@mui/icons-material"
import {
    Box,
    FormControl,
    InputAdornment,
    Stack,
    Switch,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
} from "@mui/material"
import { LocalizationProvider } from "@mui/x-date-pickers-pro"
import { AdapterMoment } from "@mui/x-date-pickers-pro/AdapterMoment"
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker"
import { SingleInputDateRangeField } from "@mui/x-date-pickers-pro/SingleInputDateRangeField"
import { CAMPAIGN_DIMENSION_IDENTIFIER } from "domain/adspend-optimizer/context/AdSpendOptimizerContext"
import { FormikProps } from "formik"
import moment, { Moment } from "moment"
import React, { useEffect, useState } from "react"
import * as R from "remeda"
import { dateString, numberInputParser } from "shared/util/util"

type Props = {
    formikProps: FormikProps<any>
    index: number
    status: number
    type: string
    id: number
    name: string
    adspend_budget: number
    scenarioPeriod: string[]
    mediaPlanPeriod: string[]
    flighting: string
    weeks: number
    mode: "ADD" | "EDIT"
    optimizationLevel: string
}

const MediaPlanRow: React.FunctionComponent<Props> = React.memo((props: Props) => {
    const formik = props.formikProps

    const getFieldValue = (name: string, index: number) => {
        return formik.values[name]?.[index]
    }

    const setFieldsValue = (fields: Record<string, any>) => {
        // Convert fields to Formik format (replace bracket notation with dot notation)
        Object.entries(fields).forEach(([key, value]) => {
            const formikKey = key.replace(/\[(\w+)\]/, ".$1")
            formik.setFieldValue(formikKey, value)
        })
    }

    const [periodStart, setPeriodStart] = useState(props.mediaPlanPeriod[0])
    const [periodEnd, setPeriodEnd] = useState(props.mediaPlanPeriod[1])
    const [scenarioPeriod, setScenarioPeriod] = useState(props.scenarioPeriod)
    const [periodWeeks, setPeriodWeeks] = useState(props.weeks)

    // Initialize with a simple value first
    const [rowActive, setRowActive] = useState(props.status === 1)

    // After mounting, update from form values if available
    useEffect(() => {
        // const active = formik?.values?.["adspend_status_id"]?.[props.index]
        const active = getFieldValue("adspend_status_id", props.index)

        if (active !== undefined) {
            setRowActive(!!active)
        }
    }, [formik, props.index])

    // calculate period weeks when new end date has been set
    useEffect(() => {
        if (periodStart && periodEnd) {
            const duration = moment.parseZone(periodEnd).add(1, "day").diff(moment.parseZone(periodStart), "week")
            setPeriodWeeks(duration)
        } else {
            setPeriodWeeks(0)
        }
    }, [periodEnd, periodStart])

    useEffect(() => {
        if (props.scenarioPeriod[0] && props.scenarioPeriod[1]) {
            if (!periodStart && !periodEnd && !props.mediaPlanPeriod[0] && !props.mediaPlanPeriod[1]) {
                setPeriodStart(props.scenarioPeriod[0])
                setPeriodEnd(props.scenarioPeriod[1])

                const periodFieldName = `adspend_period[${props.index}]`
                const fields = {}
                fields[periodFieldName] = [
                    moment.parseZone(props.scenarioPeriod[0]),
                    moment.parseZone(props.scenarioPeriod[1]),
                ]
                setFieldsValue({ ...fields })
            } else if (R.difference(scenarioPeriod, [periodStart, periodEnd]).length === 0) {
                setPeriodStart(props.scenarioPeriod[0])
                setPeriodEnd(props.scenarioPeriod[1])

                const periodFieldName = `adspend_period[${props.index}]`
                const fields = {}
                fields[periodFieldName] = [
                    moment.parseZone(props.scenarioPeriod[0]),
                    moment.parseZone(props.scenarioPeriod[1]),
                ]
                setFieldsValue({ ...fields })
            } else {
                const currentStart = periodStart
                const currentEnd = periodEnd
                const receivedStart = props.scenarioPeriod[0]
                const receivedEnd = props.scenarioPeriod[1]
                let newStart = currentStart
                    ? dateString(moment.max(moment.parseZone(currentStart), moment.parseZone(receivedStart)))
                    : receivedStart
                let newEnd = currentEnd
                    ? dateString(moment.min(moment.parseZone(currentEnd), moment.parseZone(receivedEnd)))
                    : receivedEnd

                if (moment.parseZone(newEnd).isBefore(moment.parseZone(newStart))) {
                    newStart = props.scenarioPeriod[0]
                    newEnd = props.scenarioPeriod[1]
                }

                if (currentStart && currentEnd && (newStart !== currentStart || newEnd !== currentEnd)) {
                    setPeriodStart(newStart)
                    setPeriodEnd(newEnd)
                    const periodFieldName = `adspend_period[${props.index}]`
                    const fields = {}
                    fields[periodFieldName] = [moment.parseZone(newStart), moment.parseZone(newEnd)]
                    setFieldsValue({ ...fields })
                }
            }
            setScenarioPeriod(props.scenarioPeriod)
        }
    }, [props.scenarioPeriod[0], props.scenarioPeriod[1]])

    // update period weeks state
    useEffect(() => {
        if (!periodStart && !periodEnd) setPeriodWeeks(props.weeks)
    }, [props.weeks, periodStart, periodEnd])

    // update budgets
    useEffect(() => {
        const active = getFieldValue("adspend_status_id", props.index)
        setRowActive(active)
        if (active) {
            const weeks = Math.max(periodWeeks, 1)
            const budget = Math.floor(props.adspend_budget)
            const currentBudget = getFieldValue("adspend_planned_budget", props.index)
            const field = {}
            field[`adspend_planned_budget.${props.index}`] =
                props.mode === "ADD" ? (currentBudget % budget === 0 ? budget * weeks : currentBudget) : budget || null
            setFieldsValue({ ...field })
        } else {
            const field = {}
            field[`adspend_planned_budget.${props.index}`] = 0
            setFieldsValue({ ...field })
        }
    }, [getFieldValue("adspend_status_id", props.index), periodWeeks, props.adspend_budget, props.mode, props.index])

    const disabledDate = (value: Moment, position: "start" | "end"): boolean => {
        const min = moment.parseZone(props.scenarioPeriod[0]).subtract(1, "day")
        const max = moment.parseZone(props.scenarioPeriod[1]).add(1, "day")
        return position === "end"
            ? moment(value).day() !== 0 ||
                  moment(value).isBefore(moment.parseZone(periodStart)) ||
                  moment(value).isAfter(max)
            : moment(value).day() !== 1 || moment(value).isBefore(min) || moment(value).isAfter(max)
    }

    const handleRangePickerChange = (values: Moment[]) => {
        if (values.length === 0) {
            setPeriodStart(null)
            setPeriodEnd(null)
        } else {
            setPeriodStart(values[0] ? dateString(values[0]) : null)
            setPeriodEnd(values[1] ? dateString(values[1]) : null)

            const periodFieldName = `adspend_period.${props.index}`
            const fields = {}
            fields[periodFieldName] = [moment.parseZone(values[0]), moment.parseZone(values[1])]
            setFieldsValue({ ...fields })
        }
    }

    return (
        <Stack direction="row" className={`media-plan-row ${!rowActive ? "media-plan-row-disabled" : ""}`}>
            {props.type === CAMPAIGN_DIMENSION_IDENTIFIER && (
                <Box sx={{ width: 190 }} className="media-plan-column">
                    <FormControl className="item-hidden">
                        <TextField
                            type="hidden"
                            sx={{ display: "none" }}
                            name={`adspend_mediaplan_type.${props.index}`}
                            value={props.type}
                        />
                    </FormControl>
                    <FormControl className="item-hidden">
                        <TextField
                            type="hidden"
                            sx={{ display: "none" }}
                            name={`adspend_${props.type}_id.${props.index}`}
                            value={props.id}
                        />
                    </FormControl>
                    <FormControl className="item-hidden">
                        <TextField
                            type="hidden"
                            sx={{ display: "none" }}
                            name={`adspend_status_id.${props.index}`}
                            value={true}
                        />
                    </FormControl>
                    <FormControl>
                        <Box sx={{ mt: "6px" }}>
                            <Typography>{props.name}</Typography>
                        </Box>
                    </FormControl>
                </Box>
            )}
            {props.type !== CAMPAIGN_DIMENSION_IDENTIFIER && (
                <>
                    <Box sx={{ width: 45 }} className="media-plan-column">
                        <FormControl className="item-hidden">
                            <TextField
                                type="hidden"
                                sx={{ display: "none" }}
                                name={`adspend_mediaplan_type.${props.index}`}
                                value={props.type}
                            />
                        </FormControl>
                        <FormControl className="item-hidden">
                            <TextField
                                type="hidden"
                                sx={{ display: "none" }}
                                name={`adspend_${props.type}_id.${props.index}`}
                                value={props.id}
                            />
                        </FormControl>
                        <FormControl sx={{ mt: "3px" }}>
                            <Switch
                                size={"small"}
                                name={`adspend_status_id.${props.index}`}
                                checked={
                                    getFieldValue("adspend_status_id", props.index) !== undefined
                                        ? getFieldValue("adspend_status_id", props.index)
                                        : props.status === 1
                                }
                                onChange={(e) => {
                                    formik.setFieldValue(`adspend_status_id.${props.index}`, e.target.checked)
                                }}
                            />
                        </FormControl>
                    </Box>
                    <Box sx={{ width: 145, mt: 0.5, pl: 0.375 }} className="media-plan-column">
                        <FormControl>
                            <Typography>{props.name}</Typography>
                        </FormControl>
                    </Box>
                </>
            )}
            <Box sx={{ width: 130 }} className="media-plan-column">
                <FormControl className="media-plan-budget">
                    <TextField
                        name={`adspend_planned_budget.${props.index}`}
                        fullWidth
                        disabled={!rowActive}
                        placeholder="Budget"
                        value={getFieldValue("adspend_planned_budget", props.index)}
                        onChange={(e) => {
                            // Parse the input value to a number before setting it in Formik
                            const numericValue = numberInputParser(e.target.value)
                            formik?.setFieldValue(`adspend_planned_budget.${props.index}`, Number(numericValue))
                        }}
                        slotProps={{
                            input: {
                                startAdornment: <InputAdornment position="start">€</InputAdornment>,
                            },
                        }}
                        error={
                            formik.touched?.[`adspend_planned_budget.${props.index}`] &&
                            Boolean(formik.errors?.[`adspend_planned_budget.${props.index}`])
                        }
                        helperText={
                            formik.touched?.[`adspend_planned_budget.${props.index}`]
                                ? (formik.errors?.[`adspend_planned_budget.${props.index}`] as string) || ""
                                : ""
                        }
                    />
                </FormControl>
            </Box>
            <Box sx={{ width: 190 }} className="media-plan-column">
                {props.type === CAMPAIGN_DIMENSION_IDENTIFIER && (
                    <FormControl>
                        <Box sx={{ ml: 0.5, mt: "6px" }}>
                            {periodStart && periodEnd && (
                                <Typography sx={{ letterSpacing: 0.3 }}>
                                    {dateString(moment.parseZone(periodStart), "DD.MM.YYYY")}&nbsp;to&nbsp;
                                    {dateString(moment.parseZone(periodEnd), "DD.MM.YYYY")}
                                </Typography>
                            )}
                        </Box>
                    </FormControl>
                )}
                {props.type !== CAMPAIGN_DIMENSION_IDENTIFIER && (
                    <FormControl
                        sx={{
                            width: "172px",
                            marginTop: "7px",
                            marginLeft: "5px",
                            marginRight: "0px",
                            paddingRight: "0px",
                        }}
                    >
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DateRangePicker
                                shouldDisableDate={disabledDate}
                                onChange={handleRangePickerChange}
                                slots={{ field: SingleInputDateRangeField }}
                                slotProps={{
                                    textField: {
                                        sx: {
                                            padding: "0px",
                                            "& .MuiInputBase-root": {
                                                paddingRight: "0px",
                                            },
                                            "& .MuiInputBase-input": {
                                                paddingRight: "0px",
                                            },
                                        },
                                        error:
                                            Boolean(formik.touched.adspend_period) &&
                                            Boolean(formik.errors.adspend_period),
                                    },
                                    actionBar: { actions: [], sx: { padding: "0px" } },
                                    popper: {
                                        sx: {
                                            zIndex: "20000",
                                        },
                                    },
                                }}
                                disablePast
                                format={"DD.MM.YYYY"}
                                defaultValue={[moment.parseZone(periodStart), moment.parseZone(periodEnd)]}
                                value={[moment.parseZone(periodStart), moment.parseZone(periodEnd)]}
                            />
                        </LocalizationProvider>
                    </FormControl>
                )}
            </Box>
            <Box sx={{ width: 130 }} className="media-plan-column flighting-patterns">
                <FormControl>
                    <ToggleButtonGroup
                        size="small"
                        id={`adspend_flighting_pattern.${props.index}`}
                        disabled={!rowActive}
                        value={
                            formik?.values?.[`adspend_flighting_pattern`]?.[props.index] || props.flighting || "even"
                        }
                        onChange={(event, newValue) => {
                            if (formik && newValue) {
                                formik.setFieldValue(
                                    `adspend_flighting_pattern.${props.index}`,
                                    // @ts-expect-error - newValue contains the combined value of all toggle buttons
                                    event.currentTarget.value,
                                )
                            }
                        }}
                    >
                        <ToggleButton value="even" title="Even" color={"info"}>
                            <HorizontalRule fontSize={"small"} />
                        </ToggleButton>
                        <ToggleButton value="back" title="Back" color={"info"}>
                            <TrendingDown fontSize={"small"} />
                        </ToggleButton>
                        <ToggleButton value="front" title="Front" color={"info"}>
                            <TrendingUp fontSize={"small"} />
                        </ToggleButton>
                        <ToggleButton value="burst" title="Burst" color={"info"}>
                            <BarChart fontSize={"small"} />
                        </ToggleButton>
                    </ToggleButtonGroup>
                </FormControl>
            </Box>
        </Stack>
    )
})

export default MediaPlanRow
