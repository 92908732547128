import { createSvgIcon } from "@mui/material"
import React from "react"

const ChartBarIcon = createSvgIcon(
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.20001 4.5C1.20001 3.62478 1.54769 2.78541 2.16656 2.16654C2.78543 1.54767 3.6248 1.2 4.50001 1.2H19.5C20.3752 1.2 21.2146 1.54767 21.8335 2.16654C22.4523 2.78541 22.8 3.62478 22.8 4.5V19.5C22.8 20.3752 22.4523 21.2146 21.8335 21.8334C21.2146 22.4523 20.3752 22.8 19.5 22.8H4.50001C3.6248 22.8 2.78543 22.4523 2.16656 21.8334C1.54769 21.2146 1.20001 20.3752 1.20001 19.5V4.5ZM4.50001 3C3.67201 3 3.00001 3.672 3.00001 4.5V19.5C3.00001 20.328 3.67201 21 4.50001 21H19.5C20.328 21 21 20.328 21 19.5V4.5C21 3.672 20.328 3 19.5 3H4.50001ZM12 6C12.2387 6 12.4676 6.09482 12.6364 6.2636C12.8052 6.43238 12.9 6.6613 12.9 6.9V17.1C12.9 17.3387 12.8052 17.5676 12.6364 17.7364C12.4676 17.9052 12.2387 18 12 18C11.7613 18 11.5324 17.9052 11.3636 17.7364C11.1948 17.5676 11.1 17.3387 11.1 17.1V6.9C11.1 6.6613 11.1948 6.43238 11.3636 6.2636C11.5324 6.09482 11.7613 6 12 6ZM8.10001 9.6C8.33871 9.6 8.56763 9.69482 8.73641 9.8636C8.90519 10.0324 9.00001 10.2613 9.00001 10.5V17.1C9.00001 17.3387 8.90519 17.5676 8.73641 17.7364C8.56763 17.9052 8.33871 18 8.10001 18C7.86132 18 7.6324 17.9052 7.46362 17.7364C7.29483 17.5676 7.20001 17.3387 7.20001 17.1V13.8V10.5C7.20001 10.2613 7.29483 10.0324 7.46362 9.8636C7.6324 9.69482 7.86132 9.6 8.10001 9.6ZM15.9 12C16.1387 12 16.3676 12.0948 16.5364 12.2636C16.7052 12.4324 16.8 12.6613 16.8 12.9V17.1C16.8 17.3387 16.7052 17.5676 16.5364 17.7364C16.3676 17.9052 16.1387 18 15.9 18C15.6613 18 15.4324 17.9052 15.2636 17.7364C15.0948 17.5676 15 17.3387 15 17.1V12.9C15 12.6613 15.0948 12.4324 15.2636 12.2636C15.4324 12.0948 15.6613 12 15.9 12Z"
            fill="currentColor"
        />
    </svg>,
    "Chart Bar",
)

export default ChartBarIcon
