import { Alert, AlertTitle } from "@mui/material"
import CircularProgress from "@mui/material/CircularProgress"
import OtpCode from "domain/onetimepassword/OtpCode"
import mfaService from "domain/onetimepassword/service/mfaService"
import { OtpSetupResponseFormDTO } from "generated/models"
import React, { type JSX, useEffect, useState } from "react"
import QRCode from "react-qr-code"

type Props = { success: () => void }

const AuthenticatorSetup: (props: Props) => JSX.Element = (props: Props): JSX.Element => {
    const [setupResponse, setSetupResponse] = useState(undefined)
    const [codeRejected, setCodeRejected] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [setupError, setSetupError] = useState(undefined)

    useEffect(() => {
        setIsLoading(true)
        mfaService.AUTHENTICATOR.setupSecret().then(
            (setupResponse: OtpSetupResponseFormDTO) => {
                setIsLoading(false)
                setSetupResponse({
                    type: "info",
                    secret: setupResponse.secret,
                    qrCodeContent: setupResponse.qrCodeContent,
                })
            },
            (_) => {
                setIsLoading(false)
                setSetupError({
                    errorMessage: <h4>Setup Failed</h4>,
                    description: (
                        <p>
                            We were unable to set up your authenticator account. Please try again later. If you keep
                            receiving this error, please contact our{" "}
                            <a href="mailto:support@exactag.com">support team</a>.
                        </p>
                    ),
                })
            },
        )
    }, [])

    return (
        <>
            {setupError && (
                <Alert severity={"error"}>
                    <AlertTitle>{setupError.errorMessage}</AlertTitle>
                    {setupError.description}
                </Alert>
            )}
            {!setupError && (
                <>
                    <div className={"mfa-form-segment"}>
                        <h3>Step 2: Add Your Secret Key to Your Authenticator App</h3>
                        <p>Scan the QR code below with your authenticator app:</p>

                        {isLoading && (
                            <div className={"generic-page-main-spinner"}>
                                <CircularProgress />
                            </div>
                        )}

                        {!isLoading && (
                            <>
                                {setupResponse?.qrCodeContent && (
                                    <div className={"qr-code"}>
                                        <QRCode value={setupResponse.qrCodeContent} />
                                    </div>
                                )}
                                <p>
                                    If you are not able to scan the QR code, you can enter your personal secret key
                                    manually:
                                    <br />
                                    <span style={{ fontFamily: '"Courier New", monospace' }}>
                                        {setupResponse?.secret}
                                    </span>
                                    <br />
                                    This key is time-based and Base32 encoded.
                                </p>
                            </>
                        )}
                    </div>

                    {!isLoading && (
                        <>
                            <div className={"mfa-form-segment"}>
                                <h3>Step 3: Verify Setup</h3>
                                <p>
                                    After you have successfully added your secret key to the authenticator app, you will
                                    see a 6 digit code. Please enter your code here to complete the setup:
                                </p>
                                {codeRejected && (
                                    <Alert className="form-response-alert" severity="error">
                                        <AlertTitle>
                                            Something went wrong. Please try entering the current code from your
                                            authenticator app again.
                                        </AlertTitle>
                                    </Alert>
                                )}
                                <p style={{ marginTop: 16 }}>Enter code to complete setup:</p>
                                <OtpCode
                                    codeCheck={mfaService.AUTHENTICATOR.confirmSetup}
                                    rememberMeDays={0}
                                    onSuccess={props?.success}
                                    onError={() => setCodeRejected(true)}
                                />
                            </div>
                        </>
                    )}
                </>
            )}
        </>
    )
}

export default AuthenticatorSetup
