import mfaService from "./service/mfaService"
import { ArrowBack } from "@mui/icons-material"
import { Alert, AlertTitle, CircularProgress } from "@mui/material"
import AuthenticationService from "domain/authentication/service/authentication.service"
import AuthenticatorSetup from "domain/onetimepassword/AuthenticatorSetup"
import EmailSetup from "domain/onetimepassword/EmailSetup"
import MfaCodeVerificationForm from "domain/onetimepassword/MfaCodeVerificationForm"
import MfaFactorSelectionForm from "domain/onetimepassword/MfaFactorSelectionForm"
import { MfaFactor } from "domain/types/backend/mfa.types"
import { AvailableFactorsResponseDTO, MfaFactorConfigDTO } from "generated/models"
import PublicLayout from "layout/PublicLayout/PublicLayout"
import React, { type JSX, useEffect, useState } from "react"
import { useNavigationContext } from "shared/NavigationContext"

const MfaForm: React.FC = (): JSX.Element => {
    const navigationContext = useNavigationContext()
    const [availableFactors, setAvailableFactors] = useState<MfaFactorConfigDTO[]>(undefined)
    const [setUpFactors, setSetUpFactors] = useState<MfaFactorConfigDTO[]>(undefined)
    const [factorToSetUp, setFactorToSetUp] = useState<MfaFactorConfigDTO>(undefined)
    const [mfaComplete, setMfaComplete] = useState(false)

    useEffect(() => {
        mfaService.availableFactors().then((availableFactorsResponseDTO: AvailableFactorsResponseDTO) => {
            const setUpFactors = availableFactorsResponseDTO.availableFactors.filter((config) => config.setupComplete)
            setSetUpFactors(setUpFactors)
            setAvailableFactors(availableFactorsResponseDTO.availableFactors)
        })
    }, [])

    const selectFactorToSetUp = (factor: MfaFactor) =>
        setFactorToSetUp(availableFactors.find((el) => el.factor === factor))

    const onMfaComplete = () => {
        setMfaComplete(true)
        navigationContext.redirectToReferrer(true)
    }

    const abortMfa = () => {
        AuthenticationService.logout().then(() => {
            navigationContext.redirectToLogin()
        })
    }

    return (
        <PublicLayout>
            {availableFactors === undefined && (
                <div className="screen-centered">
                    <CircularProgress />
                </div>
            )}

            {availableFactors !== undefined && (
                <div className={"content-body standalone-form mfa-form"}>
                    {!mfaComplete && (
                        <>
                            {!factorToSetUp && (
                                <>
                                    {setUpFactors?.length > 0 && (
                                        <MfaCodeVerificationForm
                                            factorConfig={setUpFactors[0]}
                                            success={onMfaComplete}
                                        />
                                    )}
                                    {setUpFactors?.length <= 0 && availableFactors && (
                                        <MfaFactorSelectionForm
                                            factorConfigs={availableFactors}
                                            select={selectFactorToSetUp}
                                        />
                                    )}
                                </>
                            )}

                            {factorToSetUp && (
                                <>
                                    {
                                        {
                                            [MfaFactor.Authenticator]: <AuthenticatorSetup success={onMfaComplete} />,
                                            [MfaFactor.Email]: (
                                                <EmailSetup factorConfig={factorToSetUp} success={onMfaComplete} />
                                            ),
                                        }[factorToSetUp.factor]
                                    }
                                </>
                            )}

                            <div className={"mfa-form-footer"}>
                                Need some help?
                                <br />
                                Email our <a href="mailto:support@exactag.com">support team</a> or have a look at the{" "}
                                <a
                                    href="https://ticket.exactag.com/hc/en-us/articles/5042492615708"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    help center
                                </a>
                                .
                            </div>
                        </>
                    )}

                    {mfaComplete &&
                        (factorToSetUp ? (
                            <>
                                <h3>Setup Complete</h3>
                                <Alert severity={"success"}>
                                    <p>
                                        You have successfully completed the multi-factor authentication setup.
                                        <br />
                                        You will be logged in shortly.
                                    </p>
                                </Alert>
                            </>
                        ) : (
                            <>
                                <h3>Authentication completed successfully</h3>
                                <Alert severity={"success"}>
                                    <AlertTitle>
                                        <p>You will be logged in shortly.</p>
                                    </AlertTitle>
                                </Alert>
                            </>
                        ))}

                    <div className={"footer"}>
                        <a onClick={abortMfa}>
                            <ArrowBack />
                            Back to Login
                        </a>
                    </div>
                </div>
            )}
        </PublicLayout>
    )
}

export default MfaForm
