import { ActionType } from "domain/types"
import { ActionDTO, ActionDTOIdentifierEnum, DimensionDTO, FormConfigDTOTypeEnum } from "generated/models"
import { PathsDTO } from "generated/models"
import ActionService from "shared/service/ActionService"
import UrlService from "shared/service/url.service"

export const userDimension = { identifier: "user_id" } as DimensionDTO
const userProfileModal = {
    modalWidth: 500,
    modalMinHeight: 300,
    additionalFooterElements: [],
}

export const UserProfileAction = {
    /**
     * Opens modal window for editing user profile
     */
    open: (userId: number) => {
        if (!userId) {
            throw new Error("Required parameter 'userId' is missing.")
        }

        const action: ActionDTO = {
            supportedAdditionalFilters: new Set(),
            identifier: ActionDTOIdentifierEnum.EDIT,
            type: ActionType.OPEN_POPUP,
            formType: FormConfigDTOTypeEnum.EDIT,
            selectionConfig: { minSelected: 1, maxSelected: null },
            modalConfig: userProfileModal,
        }

        const paths: PathsDTO = {
            serviceContextPath: UrlService.getUiServiceApiUrl(),
            base: "/user-profile",
            form: "/loadformconfig",
            data: "/loaddata",
            filter: "/filter",
        }

        const selected = [
            {
                user_id: { value: userId },
            },
        ]

        ActionService.invokeAction(action, [], paths, userDimension, selected)
    },
}
