import PasswordService from "domain/password/service/PasswordService"
import { PasswordFormResponseDTO } from "generated/models"
import { post } from "shared/service"
import UrlService from "shared/service/url.service"

const requestReset = (userNameOrEmail: string): Promise<PasswordFormResponseDTO> => {
    return post(UrlService.getUserServiceApiUrl() + "/password/request-reset", {
        userNameOrEmail,
    })
}

const reset = (token: string, password1: string, password2: string): Promise<PasswordFormResponseDTO> => {
    return post(UrlService.getUserServiceApiUrl() + "/password/reset", {
        token: token,
        password1Hash: PasswordService.hash(password1),
        password2Hash: PasswordService.hash(password2),
    })
}

const PasswordResetService = {
    requestReset,
    reset,
}

export default PasswordResetService
