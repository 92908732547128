import { createSvgIcon } from "@mui/material"
import React from "react"

const ChartUpIcon = createSvgIcon(
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.20001 4.5C1.20001 3.62478 1.54769 2.78541 2.16656 2.16654C2.78543 1.54767 3.6248 1.2 4.50001 1.2H19.5C20.3752 1.2 21.2146 1.54767 21.8335 2.16654C22.4523 2.78541 22.8 3.62478 22.8 4.5V19.5C22.8 20.3752 22.4523 21.2146 21.8335 21.8334C21.2146 22.4523 20.3752 22.8 19.5 22.8H4.50001C3.6248 22.8 2.78543 22.4523 2.16656 21.8334C1.54769 21.2146 1.20001 20.3752 1.20001 19.5V4.5ZM4.50001 3C3.67201 3 3.00001 3.672 3.00001 4.5V19.5C3.00001 20.328 3.67201 21 4.50001 21H19.5C20.328 21 21 20.328 21 19.5V4.5C21 3.672 20.328 3 19.5 3H4.50001Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.8875 6.26501C15.3657 5.98955 15.9766 6.15348 16.2526 6.63131L18.5847 10.6684C18.8609 11.1467 18.6972 11.7583 18.219 12.0345C17.7407 12.3108 17.1291 12.1471 16.8528 11.6688L15.0204 8.4966L11.8377 10.3301C11.3592 10.6058 10.7477 10.4413 10.472 9.96276C10.1964 9.48421 10.3608 8.87277 10.8394 8.59708L14.8875 6.26501Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.2081 6.73833C15.759 6.77849 16.1729 7.25758 16.1328 7.8084L15.1354 7.73568C16.1328 7.8084 16.1327 7.80893 16.1327 7.80949L16.1326 7.81076L16.1324 7.81385L16.1317 7.82225L16.1295 7.84769C16.1277 7.86845 16.125 7.89673 16.1212 7.93207C16.1136 8.00272 16.1018 8.10175 16.0838 8.22542C16.0479 8.47255 15.9874 8.81953 15.8875 9.23636C15.6885 10.0673 15.3293 11.1916 14.6858 12.3598C13.3833 14.7244 10.9219 17.2422 6.41695 17.8592C5.86977 17.9342 5.36544 17.5514 5.2905 17.0042C5.21555 16.457 5.59837 15.9527 6.14555 15.8777C9.91145 15.3619 11.8772 13.3134 12.934 11.3948C13.4702 10.4214 13.7738 9.47487 13.9426 8.7704C14.0266 8.41953 14.0763 8.13266 14.1046 7.93786C14.1187 7.84057 14.1275 7.76661 14.1325 7.71953C14.135 7.696 14.1366 7.67922 14.1375 7.66963L14.1382 7.66071L14.1382 7.66133L14.1381 7.66205C14.1787 7.11168 14.6576 6.69818 15.2081 6.73833ZM14.1383 7.66042C14.1383 7.66005 14.1383 7.66015 14.1382 7.66071Z"
            fill="currentColor"
        />
    </svg>,
    "Chart Up",
)

export default ChartUpIcon
