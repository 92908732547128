import { AnalyticsContext } from "./AnalyticsContext"
import { AnalyticsService, noopAnalyticsService } from "./AnalyticsService"
import { AppContextDTO, LightweightUserInfoDTO } from "generated/models"
import jsSHA from "jssha"
import React from "react"
import { useSelector } from "react-redux"
import { trackExactagEvent } from "shared/analytics/ExactagTag"
import { RootState } from "shared/redux/store"
import { useUiConfigQuery } from "shared/uiconfig/useUiConfigQuery"
import { log as logUtil } from "shared/util/log"

const IGNORED_USERS: readonly string[] = ["OTTO-SLA-Login-Test"]

export interface ExactagTrackingContextProviderProps {
    children: React.ReactNode
}

export const ExactagTrackingContextProvider = ({ children }: ExactagTrackingContextProviderProps) => {
    // Step 1: Is the user logged in via first factor? We don't want to initialize tracking before that.
    const loggedInViaFirstFactor = useSelector((state: RootState) => state.authentication.loggedInViaFirstFactor)
    const skipUiConfigQuery = !loggedInViaFirstFactor

    // Step 2: Get the tracking config from the backend if the user is logged in.
    const uiConfig = useUiConfigQuery({ skip: skipUiConfigQuery })

    const userInfo = useSelector((state: RootState) => state.user?.userInfo)
    const appContext = useSelector((state: RootState) => state.appContext?.appContext)

    const [initializationRequested, setInitializationRequested] = React.useState<
        false | "withLoginEvent" | "withoutLoginEvent"
    >(false)
    const [dispatchIdentifyEvent, setDispatchIdentifyEvent] = React.useState(false)
    const [dispatchLoginEvent, setDispatchLoginEvent] = React.useState(false)
    const [currentMenuPath, setCurrentMenuPath] = React.useState("")

    // Only proceed if we have all the data we need
    if (!uiConfig.isSuccess || !userInfo || !appContext) {
        log("Not all data available, not initializing")
        return (
            // We don't know yet if user tracking is enabled, but calls to initialize() might already be made, so we need to make sure we don't lose them.
            <AnalyticsContext.Provider
                value={{
                    async initialize(trackLoginEvent: boolean) {
                        setInitializationRequested(trackLoginEvent ? "withLoginEvent" : "withoutLoginEvent")
                    },
                    trackMenuNavigation(rootNode: string, path: string) {},
                    trackButtonClick(buttonName: string) {},
                    trackCustomEvent(eventName: string, eventData: object) {},
                }}
            >
                {children}
            </AnalyticsContext.Provider>
        )
    }

    // If user tracking is disabled, we provide a dummy AnalyticsService that does nothing
    if (!uiConfig.data.userTrackingEnabled) {
        log("User tracking is disabled, not initializing")
        return <AnalyticsContext.Provider value={noopAnalyticsService}>{children}</AnalyticsContext.Provider>
    }

    // Step 3: Initialize Tracking
    log("All data available, proceeding")
    const { userTrackingTrackInternalUsers, exactagCampaignEncId } = uiConfig.data

    const isIgnoredUserName = IGNORED_USERS.includes(userInfo.loginName)
    const isIgnoredUser = isIgnoredUserName || (!userTrackingTrackInternalUsers && userInfo.internalUser)

    const initialize = async (trackLoginEvent = false) => {
        if (isIgnoredUser) {
            log("User is ignored, not initializing")
            return
        }

        setDispatchIdentifyEvent(true)
        if (trackLoginEvent) {
            setDispatchLoginEvent(true)
            trackLogin()
        }
    }

    const trackLogin = async () => {
        return trackCustomEvent("Login", {})
    }

    const trackMenuNavigation = async (rootNode: string, path: string) => {
        setCurrentMenuPath(path)
        trackCustomEvent("Select Menu", {
            rootNode: rootNode,
            menuPath: path,
        }).catch((err) => logUtil.debug("Failed to track menu navigation", err))
    }

    const trackButtonClick = async (buttonName: string) => {
        trackCustomEvent("Click Button", {
            buttonName: buttonName,
        }).catch((err) => logUtil.debug("Failed to track button click event", err))
    }

    const trackCustomEvent = async (eventName: string, eventData: { [key: string]: any } = {}) => {
        if (!isIgnoredUser) {
            eventData.urlPath = getPath()
            await callExactagTrack(eventName, eventData, userInfo, appContext)
        }
    }

    function getPath(): string {
        let urlPath = window.location.pathname
        if (urlPath.startsWith("/ui/")) {
            urlPath = urlPath.substring(4)
        }
        return urlPath
    }

    const callExactagTrack = async (
        eventName: string,
        eventData: object,
        userInfo: LightweightUserInfoDTO,
        appContext: AppContextDTO,
    ) => {
        try {
            const menuPath = eventData["menuPath"] || currentMenuPath
            const hash1 = new jsSHA("SHA-1", "TEXT")
            hash1.update(userInfo.id.toString())

            // List of keys to exclude from additionalParameters
            const excludedKeys = ["urlPath", "menuPath", "rootNode"]

            // Filter, sort, and create a string of key=value pairs
            const additionalParameters = Object.entries(eventData)
                .filter(([key]) => !excludedKeys.includes(key)) // Exclude specified keys
                .sort(([keyA], [keyB]) => keyA.localeCompare(keyB)) // Sort by key in ascending order
                .map(([key, value]) => `${key}=${value}`) // Create key=value pairs
                .join(", ") // Join pairs with a comma and space

            const customParams = {
                host: window.location.host,
                site: window.location.pathname,
                protocol: window.location.protocol,
                event: eventName,
                uk: `${hash1.getHash("HEX")}`,
                url_path: eventData["urlPath"],
                root_node: eventData["rootNode"],
                menu_path: menuPath,
                // we're only tracking the ID to avoid any potential issues related to personal data
                user_id: `${userInfo.id}`,
                user_agency_id: `${userInfo.agencyId}`,
                user_agency: userInfo.agencyName,
                selected_campaign: `${appContext.campaignName}`,
                selected_campaign_id: `${appContext.campaignId}`,
                selected_advertiser: `${appContext.advertiserName}`,
                selected_advertiser_id: `${appContext.advertiserId}`,
                additional_parameters: `${additionalParameters}`,
                sitegroup: "generic",
            }

            await trackExactagEvent({
                campaign: exactagCampaignEncId,
                pitype: "Content",
                customParams: customParams,
            })
        } catch (e) {
            log("Error calling pi.aspx tracking", e)
        }
    }

    // If initialization was requested before we had all the data, we can now proceed
    if (initializationRequested) {
        log("Initialization requested, proceeding")
        const trackLoginEvent = initializationRequested === "withLoginEvent"
        initialize(trackLoginEvent)
        setInitializationRequested(false)
    }

    // Once everything is properly initialized, we can dispatch the identify and login events
    if (!isIgnoredUser) {
        if (dispatchIdentifyEvent) {
            log("Dispatching identify event")
            setDispatchIdentifyEvent(false)
        }
        if (dispatchLoginEvent) {
            log("Dispatching login event")
            setDispatchLoginEvent(false)
        }
    }

    const analyticsService: AnalyticsService = {
        initialize,
        trackMenuNavigation,
        trackButtonClick,
        trackCustomEvent,
    }

    return <AnalyticsContext.Provider value={analyticsService}>{children}</AnalyticsContext.Provider>
}

export const log = (...msg) => {
    logUtil.info("[ExactagTrackingContextProvider] ", ...msg)
}
