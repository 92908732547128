import { GlobalStyles } from "@mui/material"
import { useMutation } from "@tanstack/react-query"
import React, { PropsWithChildren, useRef } from "react"

type MutationVariables = {
    type: "image" | "pdf"
    element: HTMLDivElement
}

export const usePrintHtml = () => {
    const element = useRef<HTMLDivElement>(undefined)

    const mutation = useMutation({
        mutationKey: [],
        mutationFn: async (variables: MutationVariables) => {
            const { type, element } = variables
            switch (type) {
                case "image": {
                    const { toBlob } = await import("html-to-image")
                    /**
                     * html-to-image is blocking the UI and cannot be moved to a worker,
                     * so we sleep for a while so the user gets a chance to see the loading spinner
                     */
                    await new Promise((r) => setTimeout(r, 200))
                    const blob = await toBlob(element, {
                        pixelRatio: 2,
                        style: {
                            position: "static",
                            background: "white",
                        },
                    })
                    if (!blob) throw new Error("Could not create printable image")
                    window.open(URL.createObjectURL(blob))
                    return
                }
                case "pdf": {
                    const [jsPDF, toPng] = await Promise.all([
                        import("jspdf").then(({ jsPDF }) => jsPDF),
                        import("html-to-image").then(({ toPng }) => toPng),
                    ])
                    const { width, height } = element.getBoundingClientRect()
                    await new Promise((r) => setTimeout(r, 200))
                    const dataUri = await toPng(element, {
                        pixelRatio: 2,
                        style: {
                            position: "static",
                            background: "white",
                        },
                    })
                    const pdf = new jsPDF({
                        orientation: "landscape",
                        unit: "px",
                        format: [width * 2, height * 2],
                    })
                    pdf.addImage(dataUri, "PNG", 0, 0, width * 2, height * 2)
                    window.open(pdf.output("bloburl"))
                    return
                }
                default:
                    throw new Error("Unknown type")
            }
        },
    })

    return { ref: element, mutation }
}

export const HiddenOnPrint = ({ children }: PropsWithChildren) => {
    return (
        <>
            <GlobalStyles
                styles={{
                    ".printing .hidden-on-print": {
                        display: "none",
                    },
                }}
            />
            <span className="hidden-on-print">{children}</span>
        </>
    )
}

export const VisibleOnPrint = ({ children }: PropsWithChildren) => {
    return (
        <>
            <GlobalStyles
                styles={{
                    ".visible-on-print": {
                        display: "none",
                    },
                    ".printing .visible-on-print": {
                        display: "initial",
                    },
                }}
            />
            <span className="visible-on-print">{children}</span>
        </>
    )
}
