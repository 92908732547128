import CheckIcon from "@mui/icons-material/Check"
import { Button, FormControlLabel } from "@mui/material"
import Checkbox from "@mui/material/Checkbox"
import { OtpFormResponseDTO } from "generated/models"
import React, { type JSX, useRef, useState } from "react"
import AuthCode, { AuthCodeRef } from "react-auth-code-input"

type Props = {
    codeCheck: (string, boolean) => Promise<OtpFormResponseDTO>
    rememberMeDays: number
    onSuccess: () => void
    onError: () => void
}

const OtpCode: (props: Props) => JSX.Element = (props: Props): JSX.Element => {
    const authCodeRef = useRef<AuthCodeRef>(null)

    const [isSubmitting, setIsSubmitting] = useState(false)
    const [touched, setTouched] = useState(false)
    const [enteredCode, setEnteredCode] = useState("")
    const [rememberMe, setRememberMe] = useState(true)

    const handleSubmit = () => {
        setIsSubmitting(true)
        if (props?.codeCheck) {
            props
                .codeCheck(enteredCode, rememberMe)
                .then((otpFormResponse: OtpFormResponseDTO) => {
                    if (otpFormResponse.successful) {
                        props?.onSuccess()
                    } else {
                        props?.onError()
                        authCodeRef.current?.clear()
                    }
                })
                .finally(() => {
                    setIsSubmitting(false)
                    authCodeRef.current?.focus()
                })
        }
    }

    const onButtonClick = handleSubmit

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        handleSubmit()
    }

    const onCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setRememberMe(checked)
    }

    return (
        <form onSubmit={onSubmit}>
            <div className={"otp-code"}>
                <AuthCode
                    ref={authCodeRef}
                    containerClassName={"otp-code-container"}
                    inputClassName={"otp-code-input"}
                    onChange={(code: string) => {
                        setEnteredCode(code)
                        setTouched(true)
                    }}
                    allowedCharacters="numeric"
                    autoFocus={true}
                    length={6}
                    disabled={isSubmitting}
                />
            </div>

            <div>
                <Button
                    type="submit"
                    variant="contained"
                    onClick={onButtonClick}
                    loadingPosition="start"
                    startIcon={<CheckIcon />}
                    loading={isSubmitting}
                    size={"large"}
                    disabled={!touched}
                >
                    Verify Code
                </Button>
            </div>

            {props?.rememberMeDays > 0 && (
                <FormControlLabel
                    control={<Checkbox checked={rememberMe} onChange={onCheckboxChange} />}
                    label={`Don't ask again for ${props?.rememberMeDays} ${
                        props?.rememberMeDays === 1 ? "day" : "days"
                    }`}
                />
            )}
        </form>
    )
}

export default OtpCode
