// import React from "react"
// import { useMousePosition } from "./useMousePosition"
//
// export const useMouseMoving = () => {
//     const { x: mouseX, y: mouseY } = useMousePosition()
//     const [previousMouseCoordinates, setPreviousMouseCoordinates] = React.useState({ x: mouseX, y: mouseY })
//     const [isMouseMovingRight, setIsMouseMovingRight] = React.useState(false)
//
//     React.useEffect(() => {
//         setIsMouseMovingRight(mouseX >= previousMouseCoordinates.x)
//         setPreviousMouseCoordinates({ x: mouseX, y: mouseY })
//     }, [mouseX, mouseY, previousMouseCoordinates])
//
//     return { isMouseMovingRight }
// }
import { useMousePosition } from "./useMousePosition"
import React from "react"

export const useMouseMoving = () => {
    const { x: mouseX, y: mouseY } = useMousePosition()
    const previousMouseCoordinates = React.useRef({ x: mouseX, y: mouseY })
    const [isMouseMovingRight, setIsMouseMovingRight] = React.useState(false)

    React.useEffect(() => {
        setIsMouseMovingRight(mouseX >= previousMouseCoordinates.current.x)
        // Update the ref without causing a re-render (unlike state, updating a ref does not trigger a re-render)
        previousMouseCoordinates.current = { x: mouseX, y: mouseY }
    }, [mouseX, mouseY])

    return { isMouseMovingRight }
}
