import { useDashboardLayout } from "../DashboardLayout/DashboardLayoutContext"
import { useDashboardSettings } from "../DashboardSettings/DashboardSettingsContext"
import { useDashboardData } from "./useDashboardData"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { DashboardDTO } from "generated/models"
import * as api from "shared/service"
import UrlService from "shared/service/url.service"
import UrlUtil from "shared/util/UrlUtil"

export const useSaveDashboard = () => {
    const { uuid: dashboardId } = useDashboardSettings()
    const queryClient = useQueryClient()
    const { readOnly, layoutForm, widgetForm } = useDashboardLayout()
    const query = useDashboardData(dashboardId)

    const url = UrlUtil.joinUrl(UrlService.getReportingServiceApiUrl(), "/dashboards/update")
    return useMutation({
        mutationKey: [url, dashboardId],
        mutationFn: () => {
            if (query.status !== "success") {
                throw new Error("Cannot save because dashboard hasn't finished loading")
            }
            const dashboard: DashboardDTO = {
                ...query.data,
                readOnly: readOnly ?? false,
                layout: layoutForm.state.values.layout,
                widgets: widgetForm.state.values.widgets,
            }
            return api.post(url, dashboard) as Promise<DashboardDTO>
        },
        onSuccess: (data) => {
            queryClient.setQueryData(["/api/reporting/dashboards/view", dashboardId], data)
        },
    })
}
