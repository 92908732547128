import { KeyboardArrowRight } from "@mui/icons-material"
import { ListItem, ListItemButton, ListItemIcon, ListItemText, useMediaQuery, useTheme } from "@mui/material"
import { MenuLeafDTO } from "domain/types"
import { MenuNodeDTO } from "generated/models"
import { MenuIcon } from "layout/MainLayout/Menu/MenuIcon"
import React from "react"
import { assertExhaustive } from "shared/util/TypeUtil"

export interface NodeListItemProps {
    type: "node"
    menuNodeDTO: MenuNodeDTO
    level: number
    isExpanded: boolean
    onClick: () => void
}

export interface LeafListItemProps {
    type: "leaf"
    menuLeafDTO: MenuLeafDTO
    level: number
    isSelected: boolean
    onClick: (event: React.MouseEvent) => void
}

export type MenuListItemProps = NodeListItemProps | LeafListItemProps

export const MenuListItem = (props: MenuListItemProps) => {
    const theme = useTheme()

    switch (props.type) {
        case "node": {
            return <NodeListItem {...props} theme={theme} />
        }
        case "leaf": {
            return <LeafListItem {...props} theme={theme} />
        }
        default: {
            assertExhaustive(props)
        }
    }
}

function getItemPaddingLeft(level: number) {
    const basePadding = 28
    const paddingPerLevel = 15
    return isZeroLevel(level) ? "15px" : basePadding + paddingPerLevel * level + "px"
}

function collapseIcon(level: number, isExpanded: boolean, theme: any): React.ReactNode {
    if (level > 0) {
        return (
            <div
                style={{
                    color: theme.palette.secondaryTitleColor,
                    height: "14px",
                    width: "14px",
                    transition: "all 0.2s ease-in-out",
                    marginTop: "0px",
                    marginRight: "4px",
                    transform: isExpanded ? "rotate(90deg)" : "rotate(0deg)",
                    transformOrigin: "center center",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <KeyboardArrowRight fontSize={"small"} />
            </div>
        )
    } else {
        return null
    }
}

const isZeroLevel = (level: number) => level === 0

const NodeListItem = ({ menuNodeDTO, level, isExpanded, onClick, theme }: NodeListItemProps & { theme: any }) => {
    const { title, path, icon } = menuNodeDTO

    return (
        <ListItem key={path} className={"menu-list-item menu-list-item-node level-" + level} disablePadding>
            <ListItemButton selected={false} disableRipple={true} onClick={onClick} sx={getItemSx(level)}>
                {icon && (
                    <ListItemIcon>
                        <MenuIcon icon={menuNodeDTO.icon} />
                    </ListItemIcon>
                )}
                <ListItemText primary={title} />
                {collapseIcon(level, isExpanded, theme)}
            </ListItemButton>
        </ListItem>
    )
}

const LeafListItem = ({ menuLeafDTO, level, isSelected, onClick, theme }: LeafListItemProps & { theme: any }) => {
    const { title, path } = menuLeafDTO

    return menuLeafDTO.hidden ? (
        <div key={path} />
    ) : (
        <ListItem
            key={path}
            disablePadding
            className={"menu-list-item menu-list-item-leaf" + (isSelected ? " selected" : "") + " level-" + level}
        >
            <ListItemButton
                selected={isSelected}
                component={"a"}
                href={path}
                disableRipple={true}
                onClick={onClick}
                sx={getItemSx(level)}
            >
                <ListItemText primary={title} />
            </ListItemButton>
        </ListItem>
    )
}

const getItemSx = (level: number) => {
    return {
        paddingLeft: getItemPaddingLeft(level),
        height: isZeroLevel(level) ? "40px" : "28.5px",
    }
}
