import { ClosableSnackbar } from "domain/notification/ClosableSnackbar"
import React, { type JSX } from "react"
import { useSelector } from "react-redux"
import { RootState } from "shared/redux/store"
import { useUiConfigQuery } from "shared/uiconfig/useUiConfigQuery"

export const AfterLoginNotification = (): JSX.Element => {
    // Step 1: Is the user logged in via second factor? We don't want to show the notification before that.
    const loggedInViaSecondFactor = useSelector((state: RootState) => state.authentication.loggedInViaSecondFactor)
    const skipUiConfigQuery = !loggedInViaSecondFactor

    // Step 2: Get the config from the backend if the user is logged in.
    const uiConfig = useUiConfigQuery({ skip: skipUiConfigQuery })

    const configuredMessage = uiConfig?.data?.afterLoginNotification
    return (
        typeof configuredMessage === "string" &&
        configuredMessage.length > 0 && (
            <ClosableSnackbar autoHideDuration={15000} severity={"info"} message={configuredMessage} />
        )
    )
}
