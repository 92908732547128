import { useDashboardLayoutForms } from "../hooks/useDashboardLayoutForms"
import { DashboardLayoutContext } from "./DashboardLayoutContext"
import { FormApi, ReactFormApi, Validator } from "@tanstack/react-form"
import { DashboardLayoutItemProps } from "domain/dashboard/DashboardLayout/DashboardLayoutItem"
import { useGridStackIntegration } from "domain/dashboard/DashboardLayout/useGridStackIntegration"
import { WidgetMap, WidgetProps, WipWidgetValues } from "domain/widget/WidgetRenderer"
import { usePrintHtml } from "domain/widget/hooks/usePrintHtml"
import {
    ContainerWidgetDTO,
    DashboardDTO,
    DashboardDTOWidgetsValue,
    TabbedWidgetDTO,
    WidgetDTOTypeEnum,
} from "generated/models"
import { nanoid } from "nanoid"
import React, { PropsWithChildren } from "react"

type Form<TFormData, TFormValidator extends Validator<TFormData, unknown> | undefined = undefined> = FormApi<
    TFormData,
    TFormValidator
> &
    ReactFormApi<TFormData, TFormValidator>

export type WidgetValues = {
    widgets: Record<string, DashboardDTOWidgetsValue | WipWidgetValues>
}
export type LayoutValues = Pick<DashboardDTO, "layout">
export type WidgetForm = Form<WidgetValues>
export type LayoutForm = Form<LayoutValues>

export type GridStackConfig = {
    disableDrag?: boolean
    disableResize?: boolean
    readOnly?: boolean
}

type DashboardLayoutBaseProviderProps = GridStackConfig &
    ReturnType<typeof useDashboardLayoutForms> & {
        setLayout: (value: DashboardLayoutItemProps[]) => void
        pushLayout: (value: DashboardLayoutItemProps) => void
        removeFromLayout: (id: string) => void
        readOnly?: boolean
    }

export const DashboardLayoutBaseProvider = (props: PropsWithChildren<DashboardLayoutBaseProviderProps>) => {
    const {
        disableDrag,
        disableResize,
        readOnly,
        widgetForm,
        layoutForm,
        setLayout,
        pushLayout,
        removeFromLayout,
        children,
    } = props

    const gridstackIntegration = useGridStackIntegration({
        onUpdate: setLayout,
        disableDrag,
        disableResize,
        readOnly: readOnly ?? true,
    })

    const addWidget = <Type extends keyof WidgetMap>(
        widget: WidgetProps<Type>,
        options?: Partial<DashboardLayoutItemProps>,
    ) => {
        const id = nanoid(10)
        widgetForm.setFieldValue(`widgets.${id}`, {
            id,
            settings: widget.settings,
            // TODO: Need an distincion between widgets and wip widgets
            type: widget.type as WidgetDTOTypeEnum,
        })
        pushLayout({
            id,
            h: 4,
            w: 4,
            x: 0,
            y: 0,
            ...options,
        })
    }

    const htmlToImage = usePrintHtml()

    const removeWidget = (id: string) => {
        removeFromLayout(id)
        const widget = widgetForm.getFieldValue(`widgets.${id}`)
        widgetForm.deleteField(`widgets.${id}`)

        if (widget.type === WidgetDTOTypeEnum.CONTAINER_WIDGET) {
            for (const child of (widget as ContainerWidgetDTO).settings.layout) {
                widgetForm.deleteField(`widgets.${child.id}`)
            }
        }
        if (widget.type === WidgetDTOTypeEnum.TABBED_WIDGET) {
            for (const tab of (widget as TabbedWidgetDTO).settings.tabs) {
                for (const child of tab.layout) {
                    widgetForm.deleteField(`widgets.${child.id}`)
                }
            }
        }
    }

    return (
        <DashboardLayoutContext.Provider
            value={{
                ...gridstackIntegration,
                widgetForm,
                layoutForm,
                addWidget,
                removeWidget,
                htmlToImage,
                readOnly: readOnly ?? true,
            }}
        >
            {children}
        </DashboardLayoutContext.Provider>
    )
}
