import { Box } from "@mui/material"
import {
    ADSPEND_SCENARIO_DIMENSION_IDENTIFIER,
    HISTORIC_SCENARIO_ID,
    RECOMMENDED_SCENARIO_ID,
} from "domain/adspend-optimizer/context/AdSpendOptimizerContext"
import DimensionService, { asDataColumnIdentifier } from "domain/dimension/service/DimensionService"
import { SelectComponent } from "domain/filter/component/SelectComponent"
import { FilterState, Scenario } from "domain/types"
import { ColumnResponseDTO } from "generated/models"
import React, { CSSProperties } from "react"

type Props = {
    initValue: number | string
    scenarios: Scenario[]
    callback: (dimensionId: string, scenarioId: number) => void
    size?: string
    style?: CSSProperties
    selectStyle?: CSSProperties
    arrowColor?: string
    inputWidth?: string
}

export default (props: Props) => {
    const defaultScenarios: ColumnResponseDTO[] = [
        { name: "Historic Performance", value: HISTORIC_SCENARIO_ID },
        { name: "Recommended Scenario", value: RECOMMENDED_SCENARIO_ID },
    ]

    const scenario = {
        value: props.initValue,
        selectFormElement: {
            formFieldConfig: {
                dimensionIdentifier: DimensionService.getValueColumn(
                    asDataColumnIdentifier(ADSPEND_SCENARIO_DIMENSION_IDENTIFIER),
                ),
            },
            selectConfig: {
                multiSelect: false,
            },
        },
        filterEntries: {
            entries: [...defaultScenarios],
        },
    } as FilterState

    if (props.scenarios && props.scenarios.length) {
        scenario.filterEntries.entries = [
            ...defaultScenarios,
            ...props.scenarios.map(
                (scenario) =>
                    ({
                        value: scenario.adspend_scenario_id,
                        name: scenario.adspend_scenario_name,
                    }) as ColumnResponseDTO,
            ),
        ]
    }

    return (
        <Box sx={{ display: "inline-block", verticalAlign: "middle", width: "200px" }}>
            <SelectComponent
                size={props.size ? props.size : "small"}
                componentStyle={props.style}
                selectStyle={props.selectStyle}
                filter={scenario}
                label={""}
                disableClearButton={true}
                onChange={props.callback}
                multiple={false}
                arrowColor={props.arrowColor}
                inputWidth={props.inputWidth}
            />
        </Box>
    )
}
