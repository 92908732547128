import { LayoutContextProvider } from "./LayoutContext"
import { Box } from "@mui/material"
import { ColumnConfiguratorDialog } from "domain/ColumnConfigurator/components/ColumnConfiguratorDialog"
import { ColumnConfiguratorContextProvider } from "domain/ColumnConfigurator/context/ColumnConfiguratorContextProvider"
import { authenticationService } from "domain/authentication/service"
import { useGetUsersQuery, useSwitchUserMutation } from "domain/authentication/service/userswitch.service"
import { ArticleLayoutContextProvider } from "domain/content/ArticleLayoutContext"
import { CopyToClipboardContextProvider } from "domain/copytoclipboard/CopyToClipboardContext"
import { CopyToClipboardHint } from "domain/copytoclipboard/CopyToClipboardHint"
import { ReportingConfigurationContextProvider } from "domain/reporting/ReportingConfigurationContext"
import { actions } from "domain/user/redux/load.user.data.actions"
import { UserSettingsContextProvider } from "domain/user/settings/context/UserSettingsContextProvider"
import { DrawerContextProvider } from "layout/MainLayout/Drawer/DrawerContext"
import { ExportCenterContextProvider } from "layout/MainLayout/ExportCenter/ExportCenterContext"
import Main from "layout/MainLayout/Main/Main"
import SideBar from "layout/MainLayout/SideBar/SideBar"
import TopBar from "layout/MainLayout/TopBar/TopBar"
import { NotificationContextProvider } from "layout/MainLayout/UserMenu/Notification/NotificationContext"
import React, { useCallback, useMemo } from "react"
import { useSelector } from "react-redux"
import { useNavigationContext } from "shared/NavigationContext"
import { useAnalytics } from "shared/analytics/AnalyticsContext"
import ErrorBoundary from "shared/component/ErrorBoundary"
import { RootState, useAppDispatch } from "shared/redux/store"

export const MainLayout = () => {
    return (
        <Box
            className={"main-layout"}
            sx={{
                display: "flex",
                width: "100%",
                height: "100%",
            }}
        >
            <ErrorBoundary>
                <TopBar />
            </ErrorBoundary>
            <ErrorBoundary>
                <SideBar />
            </ErrorBoundary>
            <ErrorBoundary>
                <Main />
            </ErrorBoundary>
        </Box>
    )
}

export const MainLayoutWrapper = () => {
    const appContext = useSelector((state: RootState) => state.appContext)
    const userConfigs = useSelector((state: RootState) => state.user)
    const authentication = useSelector((state: RootState) => state.authentication)

    const dispatch = useAppDispatch()
    const { navigate, redirectToUrlPath, location } = useNavigationContext()
    const { pathname } = location

    const onAdvertiserCampaignSelected = useCallback(
        (advertiserId: number, campaignId?: number) => {
            actions.updateSelectedAdvertiserCampaign(advertiserId, campaignId)(dispatch)
        },
        [dispatch],
    )

    const handleLogout = useCallback(() => {
        authenticationService.logout().then(redirectToUrlPath)
    }, [redirectToUrlPath])

    const layoutContext = useMemo(() => {
        return {
            navigate: navigate,
            pathname: pathname,
            userConfigs: userConfigs,
            appContextState: appContext,
            authenticationState: authentication,
            onAdvertiserCampaignSelected: onAdvertiserCampaignSelected,
            handleLogout: handleLogout,
            useGetUsersQuery: useGetUsersQuery,
            useSwitchUserMutation: useSwitchUserMutation,
            redirectAfterUserSwitch: redirectToUrlPath,
        }
    }, [
        navigate,
        pathname,
        userConfigs,
        appContext,
        authentication,
        onAdvertiserCampaignSelected,
        handleLogout,
        redirectToUrlPath,
    ])

    return (
        <UserSettingsContextProvider>
            <ReportingConfigurationContextProvider>
                <ArticleLayoutContextProvider>
                    <LayoutContextProvider value={layoutContext}>
                        <CopyToClipboardContextProvider>
                            {/* this tag is used to render the copy-to-clipboard button */}
                            <CopyToClipboardHint />

                            <ColumnConfiguratorContextProvider>
                                <NotificationContextProvider>
                                    <ExportCenterContextProvider>
                                        <DrawerContextProvider>
                                            <MainLayout />
                                        </DrawerContextProvider>
                                    </ExportCenterContextProvider>
                                </NotificationContextProvider>

                                <ColumnConfiguratorDialog />
                            </ColumnConfiguratorContextProvider>
                        </CopyToClipboardContextProvider>
                    </LayoutContextProvider>
                </ArticleLayoutContextProvider>
            </ReportingConfigurationContextProvider>
        </UserSettingsContextProvider>
    )
}

export default MainLayoutWrapper
