import { SvgIconProps, createSvgIcon } from "@mui/material"
import React, { FC } from "react"
import SVG from "react-inlinesvg"

interface DynamicIconProps extends SvgIconProps {
    svgString: string
    displayName?: string
}

export const DynamicIcon: FC<DynamicIconProps> = React.memo(({ svgString, displayName = "Icon" }) => {
    const Icon = createSvgIcon(<SVG src={svgString} />, displayName)
    return <Icon />
})
