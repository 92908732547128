import { AuthenticationState } from "domain/authentication/redux/authentication.slice"
import { GetUsersQueryHook, SwitchUserMutationHook } from "domain/authentication/service/userswitch.api"
import { AppContextState } from "domain/core/redux/appcontext.slice"
import { UserConfigs } from "domain/user/redux/load.user.data.actions"
import { createContext, useContext } from "react"
import { NavigateFunction } from "react-router"

export interface LayoutContextData {
    // Navigation functions taken from NavigationContext
    navigate: NavigateFunction
    pathname: string

    // User data bridged from redux
    userConfigs: UserConfigs
    authenticationState: AuthenticationState
    appContextState: AppContextState

    // Functions to update redux state
    onAdvertiserCampaignSelected: (advertiserId: number, campaignId?: number) => void

    // Other side-effecting functions
    handleLogout: () => void
    useGetUsersQuery: GetUsersQueryHook
    useSwitchUserMutation: SwitchUserMutationHook
    redirectAfterUserSwitch: () => void
}

/**
 * LayoutContext has to be used as a child of NavigationContext.
 */
const LayoutContext = createContext<LayoutContextData>(undefined)

export const LayoutContextProvider = LayoutContext.Provider

export const useLayoutContext = () => {
    return useContext(LayoutContext)
}
