import { createSvgIcon } from "@mui/material"
import React from "react"

const WrenchIcon = createSvgIcon(
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_2_917)">
            <path
                d="M14.2441 6.56861C14.0495 6.76721 13.9404 7.03421 13.9404 7.3123C13.9404 7.59039 14.0495 7.85739 14.2441 8.05599L15.944 9.75585C16.1426 9.95051 16.4096 10.0595 16.6877 10.0595C16.9658 10.0595 17.2328 9.95051 17.4314 9.75585L21.4367 5.75055C21.9709 6.93109 22.1327 8.24641 21.9004 9.52121C21.6681 10.796 21.0528 11.9698 20.1366 12.886C19.2203 13.8023 18.0466 14.4175 16.7718 14.6498C15.497 14.8821 14.1817 14.7203 13.0011 14.1861L5.65984 21.5274C5.23719 21.95 4.66394 22.1875 4.06622 22.1875C3.4685 22.1875 2.89525 21.95 2.4726 21.5274C2.04994 21.1047 1.8125 20.5315 1.8125 19.9338C1.8125 19.336 2.04994 18.7628 2.4726 18.3402L9.81388 10.9989C9.27966 9.81833 9.11791 8.50302 9.35018 7.22821C9.58245 5.95341 10.1977 4.77967 11.114 3.86341C12.0302 2.94715 13.204 2.33188 14.4788 2.09961C15.7536 1.86734 17.0689 2.02909 18.2494 2.56331L14.2548 6.55799L14.2441 6.56861Z"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
        <defs>
            <clipPath id="clip0_2_917">
                <rect width="24" height="24" fill="currentColor" />
            </clipPath>
        </defs>
    </svg>,
    "Wrench",
)

export default WrenchIcon
