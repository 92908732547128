/* eslint-disable @typescript-eslint/no-unused-expressions */
import { DialogContentText } from "@mui/material"
import { DataGridPro, GridColDef, GridRenderCellParams, GridRowParams } from "@mui/x-data-grid-pro"
import ScenarioGridContextMenu from "domain/adspend-optimizer/component/scenario-grid/ScenarioGridContextMenu"
import ScenarioGridInlineMenu from "domain/adspend-optimizer/component/scenario-grid/ScenarioGridInlineMenu"
import {
    ADSPEND_SCENARIO_DIMENSION_IDENTIFIER,
    AdSpendOptimizerContext,
    COMMENT_DIMENSION_IDENTIFIER,
    END_DATE_DIMENSION_IDENTIFIER,
    MEDIAPLAN_GROUP_BY_DIMENSION_IDENTIFIER,
    START_DATE_DIMENSION_IDENTIFIER,
} from "domain/adspend-optimizer/context/AdSpendOptimizerContext"
import { CONTEXT_MENU_FIELD_ID } from "domain/datagrid/component/DataGrid"
import DataGridService from "domain/datagrid/service/datagrid.service"
import { GridDataRowDTO } from "domain/types"
import { ColumnConfigDTO } from "generated/models"
import { CustomDialog } from "layout/components/CustomDialog"
import React, { ReactElement, useContext, useState } from "react"
import { contextMenu as CM } from "react-contexify"
import { agnosticRenderer } from "shared/component/renderers/renderers"
import { v1 as uuid } from "uuid"

/**
 * Renders our default header layout for non sortable columns
 */
const defaultHeader = (label: string): ReactElement<any> => {
    return (
        <span key={uuid()} data-tip={label} data-force-tooltip={true}>
            {label}
        </span>
    )
}

type ScenarioGridType = {
    dataRows: GridDataRowDTO[]
    showGrid: number
}

type DeleteDialogState = {
    isOpen: boolean
    scenarioId: number | null
    scenarioName: string | null
}

export default (scenarioGridType: ScenarioGridType) => {
    const asoContext = useContext(AdSpendOptimizerContext)
    const [deleteDialog, setDeleteDialog] = useState<DeleteDialogState>({
        isOpen: false,
        scenarioId: null,
        scenarioName: null,
    })

    const handleContextMenu = (event: React.MouseEvent) => {
        event.preventDefault()
        const rowId = (event.currentTarget as HTMLElement).dataset.id
        if (rowId) {
            const rowData = data[parseInt(rowId)]
            CM.show({
                id: "scenario-grid-context-menu",
                event,
                props: {
                    item: rowData[`${ADSPEND_SCENARIO_DIMENSION_IDENTIFIER}.value`],
                },
            })
        }
    }

    type InlineAction = {
        icon: string
        action: { identifier: "SELECT" | "EDIT" | "DELETE"; name: string }
        item: { id: number }
    }

    const invokeAction = (button: InlineAction) => {
        switch (button.action.identifier) {
            case "SELECT":
                onSelect(button.item.id)
                break
            case "EDIT":
                onEdit(button.item.id)
                break
            case "DELETE":
                onDelete(button.item.id)
                break
        }
    }

    const onSelect = (id: number | string) => {
        const select = (scenarioId: number | string) =>
            scenarioGridType.showGrid === 1
                ? asoContext.selectFirstScenario(scenarioId)
                : asoContext.selectSecondScenario(scenarioId)
        asoContext && select(id)
    }

    const onEdit = (id: number) => {
        asoContext && asoContext.toggleScenarioForm(null, id)
    }

    const onDelete = (id: number) => {
        const scenario = asoContext.allScenarios.find((s) => s.adspend_scenario_id === id)
        setDeleteDialog({
            isOpen: true,
            scenarioId: id,
            scenarioName: scenario ? scenario.adspend_scenario_name : String(id),
        })
    }

    const handleDeleteConfirm = () => {
        if (deleteDialog.scenarioId !== null) {
            asoContext && asoContext.deleteScenario(deleteDialog.scenarioId)
        }
        setDeleteDialog({ isOpen: false, scenarioId: null, scenarioName: null })
    }

    const handleDeleteCancel = () => {
        setDeleteDialog({ isOpen: false, scenarioId: null, scenarioName: null })
    }

    const inlineMenu = (entry: GridDataRowDTO) => {
        const buttons: InlineAction[] = [
            {
                icon: "check",
                action: { identifier: "SELECT", name: "Select this Scenario for Comparison" },
                item: { id: entry?.value as number },
            },
            {
                icon: "edit",
                action: { identifier: "EDIT", name: "Edit" },
                item: { id: entry?.value as number },
            },
            {
                icon: "delete",
                action: { identifier: "DELETE", name: "Delete" },
                item: { id: entry?.value as number },
            },
        ]
        return <ScenarioGridInlineMenu buttons={buttons} handleAction={invokeAction} />
    }

    const columns: GridColDef[] = asoContext.scenarioGridColumns
        ? asoContext.scenarioGridColumns.map((column: ColumnConfigDTO) => ({
              field: column.columnIdentifier,
              headerName: column.gridColumnProperties.columnHeader,
              flex: 1,
              sortable: column.gridColumnProperties.sortable ?? false,
              renderCell: (params: GridRenderCellParams) => {
                  if (column.columnIdentifier === CONTEXT_MENU_FIELD_ID) {
                      return inlineMenu(params.value)
                  }
                  if (column.gridColumnProperties.renderer) {
                      return agnosticRenderer(column)(params.value, params.row)
                  }
                  // Special handling for optimization level badges
                  if (column.columnIdentifier === MEDIAPLAN_GROUP_BY_DIMENSION_IDENTIFIER) {
                      const value = params.value?.toLowerCase()
                      return (
                          <div
                              style={{
                                  backgroundColor:
                                      value === "campaign"
                                          ? "#B8E4FF"
                                          : value === "sub_campaign"
                                            ? "#4B9FE1"
                                            : value === "channel"
                                              ? "#4355E7"
                                              : "#ccc",
                                  color: value === "campaign" ? "#000" : "#fff",
                                  padding: "4px 8px",
                                  borderRadius: "4px",
                                  textTransform: "capitalize",
                              }}
                          >
                              {value?.replace("_", " ")}
                          </div>
                      )
                  }
                  return params.value
              },
              renderHeader: () => defaultHeader(column.gridColumnProperties.columnHeader),
          }))
        : []

    const rows = scenarioGridType.dataRows
        ? scenarioGridType.dataRows.map(
              (s) =>
                  ({
                      ...s,
                  }) as GridDataRowDTO,
          )
        : []

    const data = rows.map((row, index) => {
        const transformedRow = DataGridService.convertRowToTableStructure(row)

        return {
            id: index,
            [ADSPEND_SCENARIO_DIMENSION_IDENTIFIER]: {
                value: row[ADSPEND_SCENARIO_DIMENSION_IDENTIFIER]?.value,
                name: row[ADSPEND_SCENARIO_DIMENSION_IDENTIFIER]?.name,
            },
            [START_DATE_DIMENSION_IDENTIFIER]: row[START_DATE_DIMENSION_IDENTIFIER]?.value,
            [END_DATE_DIMENSION_IDENTIFIER]: row[END_DATE_DIMENSION_IDENTIFIER]?.value,
            [COMMENT_DIMENSION_IDENTIFIER]: row[COMMENT_DIMENSION_IDENTIFIER]?.value,
            [MEDIAPLAN_GROUP_BY_DIMENSION_IDENTIFIER]: row[MEDIAPLAN_GROUP_BY_DIMENSION_IDENTIFIER]?.value,
            ...transformedRow,
        }
    })

    return (
        <>
            <div className={"scenario-grid"}>
                {scenarioGridType.showGrid > 0 && (
                    <div>
                        <ScenarioGridContextMenu
                            menuId="scenario-grid-context-menu"
                            handleSelect={onSelect}
                            handleEdit={onEdit}
                            handleDelete={onDelete}
                        />
                        <div style={{ display: "flex", flexDirection: "column", minHeight: "550px" }}>
                            <DataGridPro
                                rows={data}
                                columns={columns}
                                density="standard"
                                disableColumnSorting
                                disableColumnFilter
                                disableColumnSelector
                                hideFooter
                                getRowHeight={() => "auto"}
                                onRowDoubleClick={(params: GridRowParams) => {
                                    onSelect(params.row[`${ADSPEND_SCENARIO_DIMENSION_IDENTIFIER}.value`])
                                }}
                                slotProps={{
                                    row: {
                                        onContextMenu: handleContextMenu,
                                        style: { cursor: "context-menu" },
                                    },
                                }}
                                sx={{
                                    "& .MuiDataGrid-row": {
                                        cursor: "context-menu",
                                    },
                                }}
                            />
                        </div>
                    </div>
                )}
            </div>
            <CustomDialog
                open={deleteDialog.isOpen}
                header="Please Confirm Deletion"
                content={
                    <DialogContentText>
                        Are you sure you want to delete '{deleteDialog.scenarioName}'?
                    </DialogContentText>
                }
                footer={{
                    kind: "yesNoButton",
                    yesText: "Yes",
                    noText: "No",
                    onYesButtonClick: handleDeleteConfirm,
                    onNoButtonClick: handleDeleteCancel,
                    submitting: false,
                }}
                onClose={handleDeleteCancel}
                sx={{ zIndex: 10300 }}
            />
        </>
    )
}
