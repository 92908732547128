import { useDashboardLayoutForms } from "../hooks/useDashboardLayoutForms"
import { DashboardLayoutItemProps } from "./DashboardLayoutItem"
import { useGridStackIntegration } from "./useGridStackIntegration"
import { WidgetMap, WidgetProps } from "domain/widget/WidgetRenderer"
import { usePrintHtml } from "domain/widget/hooks/usePrintHtml"
import { createContext, useContext } from "react"

export type DashboardLayoutProviderType = ReturnType<typeof useGridStackIntegration> &
    ReturnType<typeof useDashboardLayoutForms> & {
        addWidget: <Type extends keyof WidgetMap>(
            widget: WidgetProps<Type>,
            options?: Partial<DashboardLayoutItemProps>,
        ) => void
        removeWidget: (id: string) => void
        htmlToImage: ReturnType<typeof usePrintHtml>
        readOnly: boolean
    }

export const DashboardLayoutContext = createContext<DashboardLayoutProviderType | undefined>(undefined)

export const useDashboardLayout = () => {
    const context = useContext(DashboardLayoutContext)
    if (context === undefined) {
        throw new Error("useDashboardLayout must be used within a DashboardLayoutProvider")
    }
    return context
}

export const useHasParentDashboardLayout = () => {
    const context = useContext(DashboardLayoutContext)
    return context !== undefined
}
