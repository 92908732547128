import * as React from "react"
import { log } from "shared/util/log"

type Props = {
    api: () => Promise<any>

    onData?: (data: any) => void
    onError: (error: any) => void

    ms: number
    initialDelay?: number
}

type State = {
    mounted: boolean
    data: any // TODO: type me
}

/**
 * Component for polling the given API. Calls the onData and onError callbacks once data/errors arrive.
 *
 * After 10 consecutive errors this component abort polling the given api.
 */
export class Polling extends React.Component<Props, State> {
    mounted: boolean
    timeout: any // has been: TimeoutID
    errorCount: number

    static defaultProps = {
        ms: 60000, // 60 secs
        initialDelay: undefined, // 60 secs
    }

    constructor(props: Props) {
        super(props)

        this.state = { mounted: false, data: null }
        this.errorCount = 0
    }

    componentDidMount() {
        this.mounted = true
        const initDelay = this.props.initialDelay
        if (initDelay && initDelay > 0) {
            setTimeout(this.getData, initDelay)
        } else {
            this.getData()
        }
    }

    componentWillUnmount() {
        this.mounted = false
        clearTimeout(this.timeout)
    }

    getData = () => {
        // short-circuit abort if component is not mounted anymore
        if (!this.mounted) return

        const { api, onData, onError } = this.props

        // upper limit for errors, if we reach 10 consecutive errors we will stop polling.
        if (this.errorCount >= 10) {
            log.error("Stopping polling after 10 consecutive errors!")
            return
        }
        api()
            .then((data) => {
                this.errorCount = 0

                if (onData) {
                    onData(data)
                }

                this.nextReq()
            })
            .catch((err) => {
                onError(err)

                this.errorCount++

                this.nextReq()
            })
    }

    nextReq = () => {
        const { ms } = this.props
        if (this.mounted) {
            this.timeout = setTimeout(this.getData, ms)
        }
    }

    render() {
        return null
    }
}
