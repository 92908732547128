import { CustomPopper } from "./CustomPopper"
import { SafeArea } from "./SafeArea"
import { KeyboardArrowRight } from "@mui/icons-material"
import { Box, Divider, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, useTheme } from "@mui/material"
import { MenuLeafDTO } from "domain/types"
import { MenuDTO, MenuNodeDTO } from "generated/models"
import { useLayoutContext } from "layout/MainLayout/LayoutContext"
import { useFloatingMenuContext } from "layout/MainLayout/Menu/CollapsedMenu/FloatingMenuContextProvider"
import { MenuIcon } from "layout/MainLayout/Menu/MenuIcon"
import React, { Fragment, useRef } from "react"
import { isMenuLeaf, isNode } from "shared/service/MenuUtil"
import { assertExhaustive } from "shared/util/TypeUtil"

export interface NodeListItemProps {
    type: "node"
    menuNodeDTO: MenuNodeDTO
    level: number
    globalOnClickHandler: (path: string, event: React.MouseEvent) => void
}

export interface LeafListItemProps {
    type: "leaf"
    menuLeafDTO: MenuLeafDTO
    level: number
    isSelected: boolean
    globalOnClickHandler: (path: string, event: React.MouseEvent) => void
}

export type MenuListItemProps = NodeListItemProps | LeafListItemProps

export const FloatingMenuListItem = (props: MenuListItemProps) => {
    switch (props.type) {
        case "node": {
            return <NodeListItem {...props} />
        }
        case "leaf": {
            return <LeafListItem {...props} />
        }
        default: {
            assertExhaustive(props)
        }
    }
}

function nextLevelArrowIcon(level: number): React.ReactNode {
    if (level > 0) {
        return (
            <div
                style={{
                    height: "16px",
                    width: "16px",
                    marginLeft: "5px",
                }}
            >
                <KeyboardArrowRight fontSize={"small"} sx={{ color: "rgb(68, 81, 128) !important" }} />
            </div>
        )
    } else {
        return null
    }
}

const isZeroLevel = (level: number) => level === 0

const NodeListItem = ({ menuNodeDTO, level, globalOnClickHandler }: NodeListItemProps) => {
    const { title, path, icon } = menuNodeDTO
    const { isPathHovered, addMenuPathToHoveredList, removeMenuPathFromHoveredList } = useFloatingMenuContext()
    const theme = useTheme()
    const { pathname } = useLayoutContext()

    const parent = useRef<HTMLLIElement | null>(null)
    const child = useRef<HTMLDivElement | null>(null)

    const onMouseEnter = () => {
        addMenuPathToHoveredList(path, level)
    }

    const onMouseLeave = () => {
        removeMenuPathFromHoveredList(level)
    }

    const renderMenuDTO = (level: number, item: MenuDTO) => {
        if (isMenuLeaf(item)) {
            return renderLeaf(level, item, globalOnClickHandler)
        } else if (isNode(item)) {
            return renderNode(level, item, globalOnClickHandler)
        }
    }

    const renderLeaf = (
        level: number,
        item: MenuLeafDTO,
        onClickCallback: (path: string, event: React.MouseEvent) => void,
    ) => {
        return (
            <FloatingMenuListItem
                key={item.title}
                type="leaf"
                menuLeafDTO={item}
                level={level}
                isSelected={item.path === pathname}
                globalOnClickHandler={onClickCallback}
            />
        )
    }

    const renderNode = (
        level: number,
        item: MenuNodeDTO,
        onClickCallback: (path: string, event: React.MouseEvent) => void,
    ) => {
        return (
            <FloatingMenuListItem
                key={item.title}
                type="node"
                menuNodeDTO={item}
                level={level}
                globalOnClickHandler={onClickCallback}
            />
        )
    }

    const itemCount = menuNodeDTO.items.length

    // Adaptive shadows based on menu size (larger shadow looks strange on smaller menus)
    const shadowSmall = "0 15px 30px -10px rgba(30, 50, 120, 0.15), 0 20px 40px -15px rgba(25, 40, 100, 0.1)"
    const shadowMedium = "0 25px 50px -12px rgba(30, 50, 120, 0.2), 0 35px 70px -18px rgba(25, 40, 100, 0.15)"
    const shadowLarge = "0 30px 60px -15px rgba(30, 50, 120, 0.2), 0 40px 80px -20px rgba(25, 40, 100, 0.15)"

    return (
        <ListItem
            key={path + "_" + level + "_ListItem"}
            ref={parent}
            className={"menu-list-item menu-list-item-node level-" + level}
            disablePadding
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        >
            <ListItemButton selected={pathname.indexOf(path) >= 0} disableRipple={true} sx={getItemSx(level)}>
                {icon && (
                    <ListItemIcon>
                        <MenuIcon icon={menuNodeDTO.icon} />
                    </ListItemIcon>
                )}
                <ListItemText primary={title} sx={[{ opacity: level > 0 ? 1 : 0 }]} />
                {nextLevelArrowIcon(level)}
                {level >= 0 && isPathHovered(path) && parent?.current && child.current && (
                    <SafeArea submenu={child.current} />
                )}
                <CustomPopper anchorEl={isPathHovered(path) ? parent?.current : undefined} submenu={child.current}>
                    <Box
                        sx={{
                            boxShadow: itemCount <= 3 ? shadowSmall : itemCount <= 5 ? shadowMedium : shadowLarge,
                        }}
                    >
                        <Paper
                            key={path + "_" + level + "_StyledPaper"}
                            ref={child}
                            sx={{
                                boxShadow: "0 25px 50px -12px rgba(53, 98, 227, 0.125)",
                                border: `1px solid ${theme.palette.primaryShades[50]}`,
                                // marginLeft: level > 0 ? "9px" : "0px",
                                marginTop: level > 0 ? "7px" : "0px",
                                p: 1,
                                overflow: "hidden",
                            }}
                        >
                            <Box
                                component="ul"
                                sx={{
                                    minWidth: "150px",
                                    listStyle: "none",
                                    padding: 0,
                                    margin: 0,
                                }}
                            >
                                {isZeroLevel(level) && (
                                    <Fragment>
                                        <ListItemText
                                            sx={{ m: 0 }}
                                            primary={title}
                                            primaryTypographyProps={{ variant: "subtitle2" }}
                                        />
                                        <Divider />
                                    </Fragment>
                                )}
                                {menuNodeDTO.items.map((item) => (
                                    <Fragment key={item.title}>{renderMenuDTO(level + 1, item)}</Fragment>
                                ))}
                            </Box>
                        </Paper>
                    </Box>
                </CustomPopper>
            </ListItemButton>
        </ListItem>
    )
}

const LeafListItem = ({ menuLeafDTO, level, isSelected, globalOnClickHandler }: LeafListItemProps) => {
    const { closeHoveredList } = useFloatingMenuContext()
    const { title, path } = menuLeafDTO

    const onClick = (event: React.MouseEvent) => {
        closeHoveredList()
        globalOnClickHandler(path, event)
    }

    return menuLeafDTO.hidden ? (
        <div key={path} />
    ) : (
        <ListItem
            key={path}
            disablePadding
            className={"menu-list-item menu-list-item-leaf" + (isSelected ? " selected" : "") + " level-" + level}
        >
            <ListItemButton
                sx={{ paddingLeft: "10px" }}
                selected={isSelected}
                component={"a"}
                href={path}
                disableRipple={true}
                onClick={onClick}
            >
                <ListItemText primary={title} sx={[{ opacity: level > 0 ? 1 : 0 }]} />
            </ListItemButton>
        </ListItem>
    )
}

const getItemSx = (level: number) => {
    return {
        paddingLeft: "10px",
        height: isZeroLevel(level) ? "40px" : "32px",
    }
}

export default FloatingMenuListItem
