import mfaService from "./service/mfaService"
import ReplayIcon from "@mui/icons-material/Replay"
import { Alert, AlertTitle, Button } from "@mui/material"
import OtpCode from "domain/onetimepassword/OtpCode"
import RequestEmailCodeButton from "domain/onetimepassword/RequestEmailCodeButton"
import { MfaFactor } from "domain/types/backend/mfa.types"
import { MfaFactorConfigDTO } from "generated/models"
import React, { type JSX, useState } from "react"

type Props = {
    factorConfig: MfaFactorConfigDTO
    success: () => void
}

type VerificationResponseType = {
    type: "error"
    heading?: string
    button?: JSX.Element
    message: JSX.Element
}

const MfaCodeVerificationForm: (props: Props) => JSX.Element = (props: Props): JSX.Element => {
    const [codeVerificationResponse, setCodeVerificationResponse] = useState<VerificationResponseType>(undefined)
    const [emailCodeGenerationResponse, setEmailCodeGenerationResponse] = useState<VerificationResponseType>(undefined)

    const CODE_REJECTED_ERROR_MSG: VerificationResponseType = {
        type: "error",
        heading: "Verification Failed",
        button: (
            <Button
                variant="outlined"
                startIcon={<ReplayIcon />}
                onClick={() => setCodeVerificationResponse(undefined)}
            >
                Try Again
            </Button>
        ),
        message: (
            <div>
                The provided code was invalid.
                <br />
            </div>
        ),
    }

    const EMAIL_CODE_REQUEST_ERROR_MSG: VerificationResponseType = {
        type: "error",
        message: (
            <div>
                If you did not receive an email with your code try to resend another code. If you keep getting this
                error, please reach out to our support team: support@exactag.com
            </div>
        ),
    }

    const callToAction = (factor: "AUTHENTICATOR" | "EMAIL") =>
        ({
            [MfaFactor.Authenticator]: "Please enter the 6-digit code that is displayed in your authenticator app:",
            [MfaFactor.Email]: `We sent an e-mail containing a 6-digit code to ${props?.factorConfig?.userName}. Please enter the Code here:`,
        })[factor]

    return (
        <>
            {!codeVerificationResponse && (
                <>
                    <div className={"mfa-form-segment"}>
                        <h3>
                            <strong>Two-Factor Authentication</strong>
                        </h3>
                        <p>{callToAction(props?.factorConfig?.factor)}</p>

                        {emailCodeGenerationResponse && (
                            <Alert severity={emailCodeGenerationResponse.type} className="form-response-alert">
                                {emailCodeGenerationResponse.message}
                            </Alert>
                        )}

                        <OtpCode
                            codeCheck={mfaService[props?.factorConfig?.factor].verifyCode}
                            rememberMeDays={props?.factorConfig?.rememberMeDays || 0}
                            onSuccess={props?.success}
                            onError={() => setCodeVerificationResponse(CODE_REJECTED_ERROR_MSG)}
                        />
                    </div>

                    {props?.factorConfig?.factor === MfaFactor.Email && (
                        <div className={"mfa-form-segment"}>
                            <p>You didn't receive the previous e-mail?</p>
                            <RequestEmailCodeButton
                                sendOnMount={true}
                                onSuccess={() => {
                                    return
                                }}
                                onError={() => setEmailCodeGenerationResponse(EMAIL_CODE_REQUEST_ERROR_MSG)}
                            />
                        </div>
                    )}
                </>
            )}
            {codeVerificationResponse && (
                <div className={"mfa-form-segment"}>
                    <Alert severity={codeVerificationResponse.type} className="form-response-alert">
                        <AlertTitle>{codeVerificationResponse.heading}</AlertTitle>
                        {codeVerificationResponse.message}
                    </Alert>
                    <br />
                    {codeVerificationResponse.button}
                </div>
            )}
        </>
    )
}

export default MfaCodeVerificationForm
