import { useQuery } from "@tanstack/react-query"
import { PeriodsContextProvider } from "domain/widget/periods/PeriodsContext"
import MainContentAreaLoadingMask from "layout/MainLayout/Main/MainContentAreaLoadingMask"
import React, { PropsWithChildren, createContext, useContext } from "react"
import { useSelector } from "react-redux"
import { RootState } from "shared/redux/store"

type DashboardSettingsContextProviderType = {
    uuid: string
    campaign: CampaignSettings
}

type CampaignSettings = {
    currency: string
    locale: string
}

const DashboardSettingsContext = createContext<DashboardSettingsContextProviderType | undefined>(undefined)

type DashboardSettingsContextProviderProps = {
    uuid: string
}

export const DashboardSettingsContextProvider = (props: PropsWithChildren<DashboardSettingsContextProviderProps>) => {
    const { uuid, children } = props

    const appContext = useSelector((state: RootState) => state.appContext)

    const { data: campaignSettings, isSuccess: campaignSettingsSuccess } = useQuery({
        queryKey: ["campaignSettings", appContext.appContext],
        queryFn: () => {
            // TODO: we don't have campaign settings yet
            return Promise.resolve({
                currency: "EUR",
                locale: "de-DE",
            })
        },
    })

    return (
        <>
            {campaignSettingsSuccess ? (
                <DashboardSettingsContext.Provider
                    value={{
                        uuid: uuid,
                        campaign: campaignSettings,
                    }}
                >
                    <PeriodsContextProvider>{children}</PeriodsContextProvider>
                </DashboardSettingsContext.Provider>
            ) : (
                <MainContentAreaLoadingMask withAdditionalOffset={true} />
            )}
        </>
    )
}

export const useDashboardSettings = () => {
    const context = useContext(DashboardSettingsContext)
    if (context === undefined) {
        throw new Error("useDashboardSettings must be used within a DashboardSettingsContextProvider")
    }
    return context
}
