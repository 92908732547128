import { createSvgIcon } from "@mui/material"
import React from "react"

const CirclesIcon = createSvgIcon(
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_12_4)">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.9999 5.33394C13.2154 5.33394 14.2008 4.36273 14.2008 3.16474C14.2008 1.96673 13.2154 0.995544 11.9999 0.995544C10.7844 0.995544 9.79902 1.96673 9.79902 3.16474C9.79902 4.36273 10.7844 5.33394 11.9999 5.33394Z"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.7181 8.58784C18.3259 9.62536 19.6719 9.98084 20.7246 9.38182C21.7773 8.78279 22.1379 7.45617 21.5302 6.41864C20.9224 5.38112 19.5764 5.02564 18.5237 5.62466C17.471 6.22369 17.1103 7.55031 17.7181 8.58784Z"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.7181 15.0954C17.1103 16.1329 17.471 17.4596 18.5237 18.0586C19.5764 18.6576 20.9224 18.3021 21.5302 17.2646C22.1379 16.2271 21.7773 14.9005 20.7246 14.3014C19.6719 13.7024 18.3259 14.0579 17.7181 15.0954Z"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.9999 18.3491C10.7844 18.3491 9.79902 19.3203 9.79902 20.5183C9.79902 21.7163 10.7844 22.6875 11.9999 22.6875C13.2154 22.6875 14.2008 21.7163 14.2008 20.5183C14.2008 19.3203 13.2154 18.3491 11.9999 18.3491Z"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.28188 15.0954C5.67411 14.0579 4.3281 13.7024 3.27543 14.3014C2.22276 14.9005 1.86209 16.2271 2.46985 17.2646C3.0776 18.3021 4.42361 18.6576 5.4763 18.0586C6.52899 17.4596 6.88966 16.1329 6.28188 15.0954Z"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.28188 8.58784C6.88966 7.55031 6.52899 6.22369 5.4763 5.62466C4.42361 5.02564 3.0776 5.38112 2.46985 6.41864C1.86209 7.45617 2.22276 8.78279 3.27543 9.38182C4.3281 9.98084 5.67411 9.62536 6.28188 8.58784Z"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
        <defs>
            <clipPath id="clip0_12_4">
                <rect width="24" height="24" fill="white" />
            </clipPath>
        </defs>
    </svg>,

    "Circles",
)

export default CirclesIcon
