import { useDashboardSettings } from "../DashboardSettings/DashboardSettingsContext"
import { useDashboardData } from "../hooks/useDashboardData"
import { Button, Card, Modal, Stack, TextareaAutosize, Typography } from "@mui/material"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import AddWidgetModal from "domain/dashboard/DashboardLayout/AddWidgetModal"
import { useDashboardLayout } from "domain/dashboard/DashboardLayout/DashboardLayoutContext"
import { DownloadButton } from "domain/dashboard/DashboardLayout/DownloadButton"
import { DashboardDTO, LegacyDashboardConfigResponseDTO } from "generated/models"
import React, { useState } from "react"
import * as api from "shared/service"
import UrlService from "shared/service/url.service"
import UrlUtil from "shared/util/UrlUtil"
import { triggerDownloadDialog } from "utils/download"

export const DashboardConfigurationToolActions = () => {
    const { readOnly, setFloat } = useDashboardLayout()

    const convertDashboard = useLegacyDashboardConverter()
    const downloadDashboard = useDownloadDashboard()
    const [textareaValue, setTextareaValue] = useState("")
    const [isImportOpen, setIsImportOpen] = useState(false)
    const [importError, setImportError] = useState<string | undefined>(undefined)

    const errorMessage = convertDashboard.error?.message || importError

    return (
        <Stack direction="row" gap={1} justifyContent="flex-end">
            {!readOnly && (
                <>
                    <AddWidgetModal />
                    <Button
                        variant="contained"
                        onClick={async () => {
                            setIsImportOpen(true)
                        }}
                        loading={convertDashboard.isPending}
                    >
                        Import
                    </Button>
                    <Modal
                        open={isImportOpen}
                        onClose={() => {
                            setIsImportOpen(false)
                        }}
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <Card sx={{ px: 4, py: 2, minWidth: 640 }}>
                            <TextareaAutosize
                                maxRows={36}
                                style={{ width: "100%", height: "300px", maxHeight: "300px" }}
                                value={textareaValue}
                                onChange={(e) => setTextareaValue(e.target.value)}
                            />
                            <Stack direction="row" gap={1} justifyContent="flex-end">
                                {errorMessage && <Typography color="error">{errorMessage}</Typography>}
                                <Button variant="contained" onClick={() => setIsImportOpen(false)}>
                                    Cancel
                                </Button>

                                <Button
                                    variant="contained"
                                    onClick={async () => {
                                        let dashboardJson
                                        try {
                                            dashboardJson = JSON.parse(
                                                textareaValue,
                                            ) as LegacyDashboardConfigResponseDTO
                                            setImportError(undefined)
                                        } catch {
                                            setImportError("Error during parsing, make sure the input is valid JSON")
                                            return
                                        }

                                        /**
                                         * We have to toggle float before importing since a registered widget
                                         * moves away other widgets before they are ready to register
                                         */
                                        setFloat(true)
                                        await convertDashboard.mutate(dashboardJson)
                                        setTimeout(() => setFloat(false), 400) // The duration of the animation
                                        setIsImportOpen(false)
                                    }}
                                >
                                    Import
                                </Button>
                            </Stack>
                        </Card>
                    </Modal>
                    <Button
                        variant="contained"
                        onClick={() => downloadDashboard.mutate()}
                        loading={downloadDashboard.isPending}
                    >
                        Download json
                    </Button>
                </>
            )}
            <DownloadButton />
        </Stack>
    )
}

const useLegacyDashboardConverter = () => {
    const { uuid: dashboardId } = useDashboardSettings()
    const queryClient = useQueryClient()
    const { widgetForm, layoutForm } = useDashboardLayout()

    const url = UrlUtil.joinUrl(UrlService.getReportingServiceApiUrl(), "/dashboards/convertLegacyToNew")
    return useMutation({
        mutationKey: [url, dashboardId],
        mutationFn: (dashboard: LegacyDashboardConfigResponseDTO) => {
            return api.post(url, dashboard) as Promise<DashboardDTO>
        },
        onSuccess: (data) => {
            widgetForm.reset()
            layoutForm.reset()
            queryClient.setQueryData(["/api/reporting/dashboards/view", dashboardId], data)
        },
    })
}

const useDownloadDashboard = () => {
    const { uuid: dashboardId } = useDashboardSettings()
    const { readOnly, layoutForm, widgetForm } = useDashboardLayout()
    const query = useDashboardData(dashboardId)

    const url = UrlUtil.joinUrl(UrlService.getReportingServiceApiUrl(), "/dashboards/convertToLegacy")
    return useMutation({
        mutationKey: [url, dashboardId],
        mutationFn: async () => {
            if (query.status !== "success") {
                throw new Error("Cannot save because dashboard hasn't finished loading")
            }
            const dashboard: DashboardDTO = {
                ...query.data,
                readOnly: readOnly ?? false,
                layout: layoutForm.state.values.layout,
                widgets: widgetForm.state.values.widgets,
                // TODO: use user defined timespan
                timespan: query.data?.timespan,
            }

            const data = (await api.post(url, dashboard, {
                responseType: "blob",
            })) as Promise<LegacyDashboardConfigResponseDTO>

            let jsonString
            try {
                jsonString = JSON.stringify(data)
            } catch (error) {
                throw new Error("Server did not return a valid JSON response, cannot save dashboard")
            }
            const blob = new Blob([jsonString], { type: "application/json" })
            triggerDownloadDialog(blob, `${dashboardId}.json`)
        },
    })
}
