import { useForm } from "@tanstack/react-form"
import type { DashboardDTO } from "generated/models"

export type WidgetValues = Pick<DashboardDTO, "widgets">
export type LayoutValues = Pick<DashboardDTO, "layout">

export const useDashboardLayoutForms = (data: Pick<DashboardDTO, "widgets" | "layout"> | undefined) => {
    const widgetForm = useForm<WidgetValues>({
        defaultValues: {
            widgets: data?.widgets ?? {},
        },
    })

    const layoutForm = useForm<LayoutValues>({
        defaultValues: {
            layout: data?.layout ?? [],
        },
    })
    return { widgetForm, layoutForm }
}
