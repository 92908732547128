import { createSvgIcon } from "@mui/material"
import React from "react"

const BellIcon = createSvgIcon(
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 4C11.7348 4 11.4804 4.10536 11.2929 4.29289C11.1054 4.48043 11 4.73478 11 5C11 5.38669 10.7771 5.73873 10.4275 5.90403C9.44312 6.36948 8.60398 7.09402 8 8C7.40019 8.89972 7.05474 9.94469 7 11.0244V14C7 14.0402 6.99758 14.0803 6.99275 14.1202C6.9125 14.7832 6.7004 15.4222 6.37021 16H17.6298C17.2996 15.4222 17.0875 14.7832 17.0072 14.1202C17.0024 14.0803 17 14.0402 17 14V11.0244C16.9453 9.9447 16.5998 8.89972 16 8C15.396 7.09402 14.5569 6.36948 13.5725 5.90403C13.2229 5.73873 13 5.38669 13 5C13 4.73478 12.8946 4.48043 12.7071 4.29289C12.5196 4.10536 12.2652 4 12 4ZM9.87868 2.87868C10.4413 2.31607 11.2044 2 12 2C12.7956 2 13.5587 2.31607 14.1213 2.87868C14.5295 3.28688 14.8079 3.80059 14.93 4.35572C16.026 4.97224 16.9636 5.83991 17.6641 6.8906C18.4694 8.09857 18.9308 9.50291 18.9989 10.9531C18.9996 10.9687 19 10.9844 19 11V13.9354C19.0625 14.3815 19.2247 14.8082 19.4749 15.1834C19.7354 15.5742 20.084 15.8984 20.4928 16.1298C20.8879 16.3536 21.0827 16.8156 20.967 17.2548C20.8513 17.694 20.4542 18 20 18H4C3.54584 18 3.14872 17.694 3.033 17.2548C2.91729 16.8156 3.11205 16.3536 3.50725 16.1298C3.91595 15.8984 4.2646 15.5742 4.52514 15.1834C4.77531 14.8082 4.93752 14.3815 5 13.9353V11C5 10.9844 5.00037 10.9687 5.0011 10.9531C5.06917 9.50291 5.53059 8.09857 6.3359 6.8906C7.03636 5.83991 7.97403 4.97224 9.06999 4.35572C9.19207 3.80059 9.47048 3.28688 9.87868 2.87868Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9 16C9.55228 16 10 16.4477 10 17V18C10 18.5304 10.2107 19.0391 10.5858 19.4142C10.9609 19.7893 11.4696 20 12 20C12.5304 20 13.0391 19.7893 13.4142 19.4142C13.7893 19.0391 14 18.5304 14 18V17C14 16.4477 14.4477 16 15 16C15.5523 16 16 16.4477 16 17V18C16 19.0609 15.5786 20.0783 14.8284 20.8284C14.0783 21.5786 13.0609 22 12 22C10.9391 22 9.92172 21.5786 9.17157 20.8284C8.42143 20.0783 8 19.0609 8 18V17C8 16.4477 8.44772 16 9 16Z"
            fill="currentColor"
        />
    </svg>,
    "Bell",
)

export default BellIcon
