import React from "react"

interface SetDocumentTitleProps {
    pageTitle: string
    children?: React.ReactNode
}

export const SetDocumentTitle = ({ children, pageTitle }: SetDocumentTitleProps) => {
    React.useEffect(() => {
        document.title = makeDocumentTitle(pageTitle)
    }, [pageTitle])

    return <>{children}</>
}

/**
 * exactag.localhost          -> local
 * my-newui.exactag.com:3000  -> local
 * ui.exactag.com             -> prod
 * ui-dev.exactag.com         -> dev
 * ui-staging-exactag.com     -> staging
 */
const uiSubdomain = location.host.split(".").find((s) => s.startsWith("ui"))

const environment = uiSubdomain === "ui" ? "prod" : (uiSubdomain?.replace("ui-", "") ?? "local")

const makeEnvironmentPrefix = (): string => {
    return environment === "prod" ? "" : `[${environment.toUpperCase()}]`
}

const makeDocumentTitle = (pageTitle: string): string => {
    const envPrefix = makeEnvironmentPrefix()
    const pageTitleSuffix = pageTitle == "" ? "" : ` - ${pageTitle}`
    return `${envPrefix}Exactag${pageTitleSuffix}`
}
