import { EMPTY_DASHBOARD_UUID } from "../DashboardLayout/DashboardLayoutProvider"
import { skipToken, useQuery } from "@tanstack/react-query"
import { DashboardDTO, TimeSpanDTOTypeEnum } from "generated/models"
import * as api from "shared/service"
import UrlService from "shared/service/url.service"
import UrlUtil from "shared/util/UrlUtil"

export const useDashboardData = (dashboardId: string) => {
    const enabled = dashboardId !== EMPTY_DASHBOARD_UUID

    const url = UrlUtil.joinUrl(UrlService.getReportingServiceApiUrl(), "/dashboards/view", dashboardId)
    return useQuery({
        meta: {
            dashboard: true,
            enabled,
        },
        queryKey: [url, dashboardId],
        queryFn: enabled
            ? ({ signal }) => {
                  return api.get(url, {
                      signal,
                  }) as Promise<DashboardDTO>
              }
            : skipToken,
        staleTime: Infinity, // Since we work on this data, we don't want to refetch it

        // Needed so when we are on an empty dashboard, the query goes into the success state
        placeholderData: enabled
            ? undefined
            : {
                  widgets: {},
                  layout: [],
                  readOnly: false,
                  timespan: {
                      type: TimeSpanDTOTypeEnum.LAST_30_DAYS_EXCLUDING_TODAY,
                      includeTime: false,
                  },
              },
    })
}
