interface TrackingParams {
    campaign: string
    pitype: string
    convtype?: string
    customParams?: Record<string, string>
}

const createTrackingUrl = (baseUrl: string, params: Record<string, string | number>): string => {
    const url = new URL(baseUrl)
    Object.entries(params).forEach(([key, value]) => {
        url.searchParams.append(key, String(value))
    })
    return url.toString()
}

export const trackExactagEvent = async ({
    campaign,
    pitype,
    convtype = "",
    customParams = {},
}: TrackingParams): Promise<void> => {
    const trackingURL = "https://m.exactag.com/pi.aspx"

    // Default parameters for the tracking request
    const baseParams = {
        campaign,
        pitype,
        convtype,
        rnd: Math.random().toString(36).substring(2, 14), // Random ID
    }

    // Merge default and custom parameters into the `items` object
    const items = {
        ...baseParams,
        ...customParams, // Add custom parameters dynamically
    }

    const params = {
        ...baseParams,
        items: encodeURIComponent(JSON.stringify(items)), // Serialize `items`
    }

    const url = createTrackingUrl(trackingURL, params)

    try {
        const response = await fetch(url, {
            method: "GET", // Change to POST if required
        })

        if (!response.ok) {
            // throw new Error(`Tracking request failed with status: ${response.status}`)
        }
        // console.log("Tracking successful:", await response.text())
    } catch (error) {
        // console.error("Tracking error:", error)
    }
}
