import { IconButton, Popover, SvgIcon } from "@mui/material"
import * as React from "react"
import { PropsWithChildren, useState } from "react"

const BubbleSvg = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0)">
            <path
                d="M0.765384 18.2417L2.09106 14.5215C1.45344 13.2786 1.11622 11.8933 1.11396 10.4994C1.11011 8.09901 2.07326 5.82929 3.82607 4.10848C5.57914 2.38735 7.91523 1.41856 10.4039 1.38049C11.7078 1.3603 12.973 1.59197 14.1663 2.06829C15.3173 2.52773 16.3495 3.19324 17.2342 4.04629C18.1189 4.89935 18.809 5.89465 19.2855 7.00451C19.7794 8.1552 20.0194 9.37584 19.9988 10.6325C19.9594 13.0323 18.9547 15.2849 17.1697 16.9754C15.3886 18.6623 13.0409 19.5907 10.5569 19.5907C10.5521 19.5907 10.5469 19.5907 10.5421 19.5907C9.0965 19.5885 7.65986 19.2633 6.37092 18.6485L2.51284 19.9268C2.36446 19.976 2.21239 19.9999 2.06198 19.9999C1.70466 19.9999 1.35707 19.8646 1.09351 19.6104C0.719032 19.2493 0.593249 18.7248 0.765384 18.2417ZM1.90309 18.8297C1.94107 18.8664 2.02238 18.9219 2.14085 18.8827L6.22903 17.5282C6.28949 17.5082 6.35236 17.4982 6.41504 17.4982C6.50745 17.4982 6.59944 17.5198 6.68275 17.5623C7.86428 18.165 9.19942 18.4847 10.5439 18.4866C15.0528 18.4937 18.7826 14.9622 18.8541 10.615C18.89 8.42786 18.0272 6.37228 16.4246 4.82694C14.822 3.28165 12.6909 2.44983 10.4221 2.48441C5.91391 2.55333 2.25188 6.1481 2.2589 10.4977C2.26101 11.7942 2.5925 13.0816 3.21752 14.2208C3.29152 14.3557 3.30431 14.5141 3.2529 14.6584L1.84813 18.6005C1.80744 18.7146 1.86507 18.7932 1.90309 18.8297Z"
                fill="currentColor"
            />
            <path
                d="M9.68397 14.1698C9.37385 14.1698 9.07674 14.4425 9.09107 14.7627C9.10545 15.0839 9.35158 15.3556 9.68397 15.3556C9.99408 15.3556 10.2912 15.0829 10.2769 14.7627C10.2625 14.4414 10.0163 14.1698 9.68397 14.1698Z"
                fill="currentColor"
            />
            <path
                d="M10.1165 6.20691C8.72849 6.20691 7.59924 7.33615 7.59924 8.72417C7.59924 9.00358 7.82579 9.23013 8.1052 9.23013C8.38461 9.23013 8.61115 9.00358 8.61115 8.72417C8.61115 7.89414 9.28647 7.21881 10.1165 7.21881C10.9465 7.21881 11.6219 7.89414 11.6219 8.72417C11.6219 9.55421 10.9465 10.2295 10.1165 10.2295C9.8371 10.2295 9.61055 10.4561 9.61055 10.7355V12.7991C9.61055 13.0785 9.8371 13.305 10.1165 13.305C10.3959 13.305 10.6225 13.0785 10.6225 12.799V11.1903C11.7688 10.9555 12.6338 9.93895 12.6338 8.72417C12.6338 7.33615 11.5045 6.20691 10.1165 6.20691Z"
                fill="currentColor"
            />
        </g>
        <defs>
            <clipPath id="clip0">
                <rect width="20" height="20" fill="white" />
            </clipPath>
        </defs>
    </svg>
)

const BubbleIcon = (props) => (
    <SvgIcon {...props}>
        <BubbleSvg />
    </SvgIcon>
)

type Props = {
    title: string
}

export const HelpBubble = ({ title, children }: PropsWithChildren<Props>) => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
    const open = Boolean(anchorEl)

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    return (
        <>
            <IconButton onClick={handleClick} className="icon-helpbubble hover-color-primary" size="small">
                <BubbleIcon />
            </IconButton>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                className="popover-helpbubble"
            >
                <div style={{ maxWidth: 800, maxHeight: 800, overflowY: "auto" }}>
                    {title && (
                        <div style={{ padding: "8px 16px", borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}>
                            {title}
                        </div>
                    )}
                    <div style={{ padding: "8px 16px" }}>{children}</div>
                </div>
            </Popover>
        </>
    )
}
