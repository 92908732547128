/* eslint-disable no-constant-binary-expression */
import WarningIcon from "@mui/icons-material/Warning"
import Alert from "@mui/material/Alert"
import Box from "@mui/material/Box"
import CircularProgress from "@mui/material/CircularProgress"
import { NOT_AVAILABLE } from "Constants"
import ScenarioSelectDropdown from "domain/adspend-optimizer/component/ScenarioSelectDropdown"
import KPIEntry from "domain/adspend-optimizer/component/overview/KPIEntry"
import { SliceData } from "domain/adspend-optimizer/context/AdSpendOptimizerContext"
import { ContentBox } from "domain/core/component/ContentBox"
import { Scenario } from "domain/types"
import numbro from "numbro"
import React from "react"

type Props = {
    className?: string
    introText?: string
    scenarioId?: string | number
    scenarioJobStatus?: string
    scenarioPeriodInfo?: string
    scenarioTotalData?: SliceData
    scenarios: Scenario[]
    scenarioSelector: (dimensionId: string, scenarioId: number) => void
}

// see http://numbrojs.com/format.html and https://github.com/BenjaminVanRyseghem/numbro
export const formatNumber = (num: number, additionalConfigs?: any): string =>
    num === null
        ? NOT_AVAILABLE
        : numbro(num).format({ average: true, mantissa: 1, trimMantissa: true, ...additionalConfigs })

export const ScenarioInfoBox = (props: Props) => {
    const loadingState = ["unprocessed", "queued", "scheduled", "in_progress"].includes(props.scenarioJobStatus)
    const errorState = ["failed", "disabled"].includes(props.scenarioJobStatus)

    const additionalClass = loadingState ? "loading" : errorState ? "error" : ""

    return (
        <React.Fragment>
            {props && (
                <ContentBox className={"scenario-info-box subtle-edit " + props.className + " " + additionalClass}>
                    <Box sx={{ display: "flex", flexDirection: "row", width: "100%" }}>
                        <Box sx={{ flex: 2 }}>
                            <div className={"meta-data"}>
                                <div className={"intro-text"}>{props.introText}</div>
                                <ScenarioSelectDropdown
                                    size={"large"}
                                    selectStyle={{ marginTop: -1, height: 40 }}
                                    initValue={props.scenarioId}
                                    callback={props.scenarioSelector}
                                    scenarios={props.scenarios}
                                    arrowColor={"#FFF"}
                                    inputWidth={"277px"}
                                />
                                <div className={"period-info"}>{props.scenarioPeriodInfo}</div>
                            </div>
                        </Box>
                        <Box sx={{ flex: 1 }}>
                            <KPIEntry
                                formattedNumber={
                                    props.scenarioTotalData
                                        ? formatNumber(props.scenarioTotalData.data.adspend_roas?.value as number)
                                        : ""
                                }
                                label={"ROAS"}
                                additionalCssClass={"main-kpi"}
                            />
                        </Box>
                    </Box>

                    {!loadingState && !errorState && (
                        <Box
                            sx={{ display: "flex", flexDirection: "row", width: "100%", height: "49px" }}
                            className={"row-kpis"}
                        >
                            <Box sx={{ flex: 1 }}>
                                <KPIEntry
                                    formattedNumber={
                                        props.scenarioTotalData
                                            ? formatNumber(props.scenarioTotalData.data.adspend_costs?.value as number)
                                            : ""
                                    }
                                    icon={"coins-out"}
                                    label={"Budget"}
                                    currencyString={"€"}
                                />
                            </Box>
                            <Box sx={{ flex: 1 }}>
                                <KPIEntry
                                    formattedNumber={
                                        props.scenarioTotalData
                                            ? formatNumber(
                                                  props.scenarioTotalData.data.adspend_total_price?.value as number,
                                              )
                                            : ""
                                    }
                                    icon={"coins-in"}
                                    label={"Total Price"}
                                    currencyString={"€"}
                                />
                            </Box>
                            <Box sx={{ flex: 1 }}>
                                <KPIEntry
                                    formattedNumber={
                                        props.scenarioTotalData
                                            ? formatNumber(
                                                  props.scenarioTotalData.data.adspend_conversions?.value as number,
                                              )
                                            : ""
                                    }
                                    icon={"conversions"}
                                    label={"Conversions"}
                                />
                            </Box>
                        </Box>
                    )}

                    {loadingState && !errorState && (
                        <Box
                            sx={{ display: "flex", flexDirection: "row", width: "100%" }}
                            className={"row-kpis"}
                            style={{ height: "49px" }}
                        >
                            <Alert icon={<CircularProgress size={20} />} severity="info">
                                This scenario is currently being calculated.
                            </Alert>
                        </Box>
                    )}
                    {errorState && (
                        <Box
                            sx={{ display: "flex", flexDirection: "row", width: "100%" }}
                            className={"row-kpis"}
                            style={{ height: "49px" }}
                        >
                            <Alert icon={<WarningIcon />} severity="error">
                                An error occurred while calculating this scenario.
                            </Alert>
                        </Box>
                    )}
                </ContentBox>
            )}
        </React.Fragment>
    )
}
