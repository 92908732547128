import { createSvgIcon } from "@mui/material"
import React from "react"

const CircleAlertIcon = createSvgIcon(
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M9.99999 7.33325V10.6666M9.99999 13.9999H10.0083M18.3333 10.6666C18.3333 15.269 14.6024 18.9999 9.99999 18.9999C5.39762 18.9999 1.66666 15.269 1.66666 10.6666C1.66666 6.06421 5.39762 2.33325 9.99999 2.33325C14.6024 2.33325 18.3333 6.06421 18.3333 10.6666Z"
            stroke="currentColor"
            strokeWidth="1.66667"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>,
    "Circle Alert",
)

export default CircleAlertIcon
