import { useCopyToClipboardContext } from "domain/copytoclipboard/CopyToClipboardContext"
import { ClosableSnackbar } from "domain/notification/ClosableSnackbar"
import React, { type JSX } from "react"

export const CopyToClipboardNotification = (): JSX.Element => {
    const { showCopyToClipboardNotification } = useCopyToClipboardContext()

    if (showCopyToClipboardNotification) {
        return <ClosableSnackbar severity={"info"} message={"Cell content copied to clipboard"} />
    } else {
        return <></>
    }
}
