import { Alert, Snackbar } from "@mui/material"
import DimensionService, { asDataColumnIdentifier } from "domain/dimension/service/DimensionService"
import { DataRowDTO } from "domain/types"
import { CustomDialog } from "layout/components/CustomDialog"
import * as React from "react"
import type { JSX } from "react"
import { useSelector } from "react-redux"
import { getUniqueId } from "shared/component/forms/elements/primitives/util"
import { closeActionModal } from "shared/component/modals/action/ActionModal.slice"
import { useActionModalContext } from "shared/component/modals/action/ActionModalContext"
import { RootState, useAppDispatch } from "shared/redux/store"
import ActionService, { DIMENSION_STATUS } from "shared/service/ActionService"
import PopupTextUtil from "shared/util/PopupTextUtil"
import { log } from "shared/util/log"
import { getStatusId } from "shared/util/util"

export const ActionModal = (): JSX.Element => {
    const dispatch = useAppDispatch()
    const { setForceUpdate } = useActionModalContext()
    const [submitting, setSubmitting] = React.useState(false)
    const [error, setError] = React.useState<string | null>(null)
    const { actionModalConfig } = useSelector((state: RootState) => state.actionModal)
    const { current: id } = React.useRef(getUniqueId("action-modal"))

    const onClose = () => {
        dispatch(closeActionModal())
    }

    const handleErrorClose = () => {
        setError(null)
    }

    const onSubmit = () => {
        const {
            mainDimension: { identifier },
            action,
            selectedItems,
            apiPath,
        } = actionModalConfig
        const statusId = getStatusId(action.type)
        const submitData = selectedItems.map((row) => {
            return {
                [DimensionService.getValueColumn(asDataColumnIdentifier(identifier))]: row[identifier].value,
                [DimensionService.getValueColumn(asDataColumnIdentifier(DIMENSION_STATUS))]: statusId,
            } as DataRowDTO
        })

        setSubmitting(true)
        ActionService.statusPopupOnConfirm(submitData, `${apiPath}/updatedata`)
            .catch((response) => {
                if (response.message) {
                    setError("An error occurred.")
                    log.error(response.message)
                }
            })
            .finally(() => {
                setSubmitting(false)
                // update the state so that child elements (e.g. GenericDataGridWidget) can react to that change
                setForceUpdate((prev) => !prev)
                dispatch(closeActionModal())
            })
    }

    const header = actionModalConfig
        ? PopupTextUtil.createPopupHeader(
              actionModalConfig.action,
              actionModalConfig.selectedItems,
              actionModalConfig.mainDimension,
          )
        : ""
    const content = actionModalConfig ? (
        PopupTextUtil.createPopupItemSentence(
            actionModalConfig.action,
            actionModalConfig.selectedItems,
            actionModalConfig.mainDimension,
        )
    ) : (
        <></>
    )

    return (
        <>
            <CustomDialog
                open={Boolean(actionModalConfig)}
                key={id}
                onClose={onClose}
                footer={{
                    kind: "yesNoButton",
                    noText: "No",
                    yesText: "Yes",
                    onYesButtonClick: onSubmit,
                    onNoButtonClick: onClose,
                    submitting: submitting,
                }}
                content={content}
                header={header}
                modalMinHeight={50}
                modalWidth={500}
            />
            <Snackbar
                open={Boolean(error)}
                autoHideDuration={5000}
                onClose={handleErrorClose}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
                <Alert onClose={handleErrorClose} severity="error">
                    {error}
                </Alert>
            </Snackbar>
        </>
    )
}
