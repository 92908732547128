import styled from "@emotion/styled"
import { FormatListNumbered as ListIcon, Add as PlusIcon } from "@mui/icons-material"
import CloseIcon from "@mui/icons-material/Close"
import { LoadingButton } from "@mui/lab"
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Button as MuiButton,
} from "@mui/material"
import { width } from "@mui/system"
import { MultiBarChart } from "domain/adspend-optimizer/component/chart/MultiBarChart"
import { StackedBarChart } from "domain/adspend-optimizer/component/chart/StackedBarChart"
import ScenarioComparisonGrid from "domain/adspend-optimizer/component/comparison-grid/ScenarioComparisonGrid"
import ScenarioComparisonHeader from "domain/adspend-optimizer/component/comparison-grid/ScenarioComparisonGridHeader"
import { MediaPlanEfficiencyWrapper } from "domain/adspend-optimizer/component/efficiency"
import { ScenarioOverview } from "domain/adspend-optimizer/component/overview/ScenarioOverview"
import ScenarioForm from "domain/adspend-optimizer/component/scenario-form/ScenarioForm"
import ScenarioGrid from "domain/adspend-optimizer/component/scenario-grid/ScenarioGrid"
import AdSpendOptimizerProvider, {
    AdSpendOptimizerContext,
} from "domain/adspend-optimizer/context/AdSpendOptimizerContext"
import AdSpendOptimizerService from "domain/adspend-optimizer/service/adspend-optimizer.service"
import { ContentBox } from "domain/core/component/ContentBox"
import { HelpBubble } from "domain/core/component/HelpBubble"
import { PageErrorMessage } from "domain/core/component/PageErrorMessage"
import { selectors as appcontextSelectors } from "domain/core/redux/appcontext.slice"
import { DataGridContextProvider } from "domain/legacy/widget/generic/DataGridContext"
import { AppContextDTO } from "generated/models"
import React, { useContext, useEffect, useState } from "react"
import { connect } from "react-redux"
import { Polling } from "shared/component"
import { Pagination } from "shared/component/pagination/Pagination"
import { log } from "shared/util/log"
import { capitalizeEnum } from "shared/util/util"

const StyledButton = styled(MuiButton)`
    padding: 8px 19px;
    font-size: 14px;
    text-transform: none;
`

const StyledDialog = styled(Dialog)`
    .MuiDialog-paper {
        min-width: 1024px;
        padding: 20px;
    }
`

const LoadingOverlay = styled(Box)`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 9999;
`

const AboutAdspendOptimizer = () => (
    <HelpBubble title={"About the AdSpend Optimizer"}>
        <div>
            <p>
                The AdSpend Optimizer provides forecasts for future conversions, total price, and efficiency metrics.
                These forecasts will help you optimize your budget across channels and sub campaigns.
            </p>
            <p>
                You can create your <strong>own future budget scenarios</strong> or use our{" "}
                <strong>pre-computed recommended scenario</strong>, which is based your historical average budget.
            </p>
            <p>
                The tool is oriented around comparisons, either against historical performance or two different future
                scenarios to enable you to pick the outcome that is right for your business.
            </p>
        </div>
    </HelpBubble>
)

type Props = {
    appContext?: AppContextDTO
}

const AdSpendOptimizer = (props: Props) => {
    const asoContext = useContext(AdSpendOptimizerContext)

    const [appBusy, setAppBusy] = useState(false)
    const [formBusy, setFormBusy] = useState(false)

    const campaignName = asoContext && asoContext.campaignData ? asoContext.campaignData.campaignName : ""
    const formattedOptimizationLevel = asoContext
        ? capitalizeEnum(asoContext.comparisonOptimizationLevel).replace("-", " ")
        : ""

    useEffect(() => {
        if (props.appContext && props.appContext.campaignId) {
            asoContext.setAppContext(props.appContext)
        }
        // TODO: is it safe to add the missing dependencies?
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.appContext])

    useEffect(() => {
        setAppBusy(
            !(
                asoContext &&
                asoContext.appContext &&
                asoContext.allScenarios &&
                asoContext.comparisonData &&
                !asoContext.userNotAuthorized
            ),
        )
    }, [asoContext])

    useEffect(() => {
        setFormBusy(asoContext.formBusy || false)
    }, [asoContext.formBusy])

    const selectScenario1 = (_, value) => asoContext.selectFirstScenario(value)
    const selectScenario2 = (_, value) => asoContext.selectSecondScenario(value)

    const handleDialogClose =
        (callback: (...args: any[]) => void) => (_: {}, reason: "backdropClick" | "escapeKeyDown") => {
            callback()
        }

    return (
        <React.Fragment>
            <div className="datagrid-body adspend-optimizer">
                <div className={"title-bar"}>
                    <div className={"title-container"}>
                        <h3 className="widget-title">
                            AdSpend Optimization Results for <em>{campaignName}</em>
                        </h3>
                    </div>
                    <div className={"button-container"}>
                        <StyledButton
                            variant="outlined"
                            size="medium"
                            className={"button-scenario-list"}
                            onClick={() => asoContext.toggleScenarioGrid(2)}
                            disabled={appBusy}
                            startIcon={<ListIcon />}
                            style={{ marginRight: 5 }}
                        >
                            {campaignName + " Scenarios"}
                        </StyledButton>

                        <StyledButton
                            variant="contained"
                            size="medium"
                            className={"button-scenario-create"}
                            onClick={asoContext.toggleScenarioForm}
                            disabled={appBusy}
                            startIcon={<PlusIcon />}
                        >
                            Create Scenario
                        </StyledButton>
                        <AboutAdspendOptimizer />
                    </div>
                </div>

                {asoContext && asoContext.userNotAuthorized && (
                    <ContentBox>
                        <PageErrorMessage type={"info"} title={"Could not Access the AdSpend Optimizer"}>
                            We're sorry, but the campaign you've selected or your user account lack permission to the
                            AdSpend Optimizer.
                            <p>
                                We do not want you to be left out, so please contact your account manager to find out
                                how you can get access to this tool that will let you forecast future conversions and
                                total prices based on your budget scenarios.
                            </p>
                        </PageErrorMessage>
                    </ContentBox>
                )}

                {asoContext && !asoContext.userNotAuthorized && (
                    <React.Fragment>
                        <div
                            style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "flex-end",
                                alignItems: "flex-start",
                            }}
                        >
                            <Dialog
                                open={asoContext && asoContext.showScenarioForm && !asoContext.scenarioToEdit}
                                onClose={handleDialogClose(asoContext.toggleScenarioForm)}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                                sx={{
                                    ".MuiDialog-paper": {
                                        minWidth: "780px",
                                        minHeight: "610px",
                                        padding: "20px",
                                    },
                                }}
                            >
                                <DialogTitle id="alert-dialog-title">{"Add Scenario"}</DialogTitle>
                                <IconButton
                                    aria-label="close"
                                    onClick={asoContext.toggleScenarioForm}
                                    sx={(theme) => ({
                                        position: "absolute",
                                        right: 8,
                                        top: 8,
                                        color: theme.palette.grey[500],
                                    })}
                                >
                                    <CloseIcon />
                                </IconButton>
                                <DialogContent>
                                    {props.appContext && (
                                        <ScenarioForm
                                            mode="ADD"
                                            campaignId={props.appContext.campaignId}
                                            campaignName={campaignName}
                                            channelData={asoContext.channelData}
                                            subCampaignData={asoContext.subCampaignData}
                                        />
                                    )}
                                </DialogContent>
                                <DialogActions style={{ padding: "1px" }}>
                                    <Button onClick={asoContext.toggleScenarioForm} variant="outlined" color="primary">
                                        Cancel
                                    </Button>
                                    <LoadingButton
                                        onClick={() => asoContext.submitForm()}
                                        variant="contained"
                                        color="primary"
                                        autoFocus
                                        loading={formBusy}
                                        disabled={asoContext.invokeFormSubmit}
                                    >
                                        Save
                                    </LoadingButton>
                                </DialogActions>
                            </Dialog>
                        </div>

                        <Dialog
                            open={
                                asoContext &&
                                asoContext.scenarioGridColumns &&
                                asoContext.scenarios &&
                                asoContext.showGrid > 0
                            }
                            onClose={handleDialogClose(asoContext.hideScenarioGrid)}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                            sx={{
                                ".MuiDialog-paper": {
                                    minWidth: "1024px",
                                    padding: "20px",
                                },
                            }}
                        >
                            <DialogTitle id="alert-dialog-title">
                                <em>{campaignName}</em> Scenarios
                            </DialogTitle>
                            <IconButton
                                aria-label="close"
                                onClick={asoContext.hideScenarioGrid}
                                sx={(theme) => ({
                                    position: "absolute",
                                    right: 8,
                                    top: 8,
                                    color: theme.palette.grey[500],
                                })}
                            >
                                <CloseIcon />
                            </IconButton>
                            <DialogContent>
                                <ScenarioGrid showGrid={asoContext.showGrid} dataRows={asoContext.scenarios} />
                                {asoContext.showGrid > 0 && (
                                    <Pagination
                                        page={asoContext.scenarioGridPagination.page}
                                        pageSize={asoContext.scenarioGridPagination.pageSize}
                                        totalEntities={asoContext.scenarioGridPagination.totalEntities}
                                        entitiesOnPage={asoContext.scenarios?.length ?? 0}
                                        onPageChange={asoContext.handlePageChange}
                                    />
                                )}
                            </DialogContent>
                        </Dialog>

                        <Dialog
                            open={asoContext && asoContext.showScenarioForm && !!asoContext.scenarioToEdit}
                            onClose={handleDialogClose(asoContext.toggleScenarioForm)}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                            sx={{
                                ".MuiDialog-paper": {
                                    minWidth: "780px",
                                    minHeight: "610px",
                                    padding: "20px",
                                },
                            }}
                        >
                            <DialogTitle id="alert-dialog-title">{"Edit Scenario"}</DialogTitle>
                            <IconButton
                                aria-label="close"
                                onClick={asoContext.toggleScenarioForm}
                                sx={(theme) => ({
                                    position: "absolute",
                                    right: 8,
                                    top: 8,
                                    color: theme.palette.grey[500],
                                })}
                            >
                                <CloseIcon />
                            </IconButton>
                            <DialogContent>
                                {props.appContext && asoContext.scenarioToEdit && (
                                    <ScenarioForm
                                        mode="EDIT"
                                        campaignId={props.appContext.campaignId}
                                        campaignName={campaignName}
                                        scenario={asoContext.scenarioToEdit}
                                        channelData={asoContext.channelData}
                                        subCampaignData={asoContext.subCampaignData}
                                    />
                                )}
                            </DialogContent>
                            <DialogActions style={{ padding: "1px" }}>
                                <MuiButton onClick={asoContext.toggleScenarioForm} color="primary" variant={"outlined"}>
                                    Cancel
                                </MuiButton>
                                <MuiButton
                                    onClick={() => asoContext.submitForm()}
                                    color="primary"
                                    variant="contained"
                                    autoFocus
                                    disabled={asoContext.invokeFormSubmit}
                                >
                                    Save
                                </MuiButton>
                            </DialogActions>
                        </Dialog>

                        {asoContext &&
                            asoContext.scenariosToPoll &&
                            asoContext.scenariosToPoll.length > 0 &&
                            asoContext.scenariosToPoll.map((id) => {
                                return (
                                    <Polling
                                        key={id}
                                        api={() =>
                                            AdSpendOptimizerService.fetchScenario(id as number, asoContext.appContext)
                                        }
                                        onError={() => log.warn("error polling")}
                                        onData={asoContext.updateScenario}
                                        ms={10000}
                                    />
                                )
                            })}

                        {appBusy && (
                            <LoadingOverlay>
                                <CircularProgress size={40} />
                            </LoadingOverlay>
                        )}

                        <ScenarioOverview />

                        {asoContext &&
                            asoContext.comparisonData &&
                            asoContext.comparisonData.rows &&
                            asoContext.comparisonData.rows.length > 0 && (
                                <React.Fragment>
                                    <ScenarioComparisonHeader
                                        optimizationLevel={asoContext.comparisonOptimizationLevel}
                                        scenarios={asoContext.allScenarios}
                                        callback1={selectScenario1}
                                        callback2={selectScenario2}
                                        scenarioId1={asoContext.scenario1}
                                        scenarioId2={asoContext.scenario2}
                                        titlePrefix={"Budget Shifts per"}
                                    />
                                    <ContentBox>
                                        <Grid container>
                                            <Grid item xs={12} style={{ textAlign: "center" }}>
                                                <h3>Total Budget per {formattedOptimizationLevel} </h3>
                                            </Grid>
                                        </Grid>

                                        <MultiBarChart
                                            data={asoContext.comparisonData}
                                            scenario1={asoContext.scenario1data}
                                            scenario2={asoContext.scenario2data}
                                        />

                                        {asoContext &&
                                            asoContext.weeklyComparisonData &&
                                            asoContext.weeklyComparisonData.rows &&
                                            asoContext.weeklyComparisonData.rows.length > 0 && (
                                                <React.Fragment>
                                                    <Grid container justifyContent="center" style={{ marginTop: 85 }}>
                                                        <Grid item xs={12}>
                                                            <Grid container>
                                                                <Grid item xs={6} style={{ textAlign: "center" }}>
                                                                    <h3>
                                                                        {asoContext.scenario1data.adspend_scenario_name}
                                                                        : Weekly Budget per {formattedOptimizationLevel}
                                                                    </h3>
                                                                </Grid>
                                                                <Grid item xs={6} style={{ textAlign: "center" }}>
                                                                    <h3>
                                                                        {asoContext.scenario2data.adspend_scenario_name}
                                                                        : Weekly Budget per {formattedOptimizationLevel}
                                                                    </h3>
                                                                </Grid>
                                                            </Grid>
                                                            <StackedBarChart
                                                                data={asoContext.weeklyComparisonData}
                                                                scenario1={asoContext.scenario1data}
                                                                scenario2={asoContext.scenario2data}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </React.Fragment>
                                            )}
                                    </ContentBox>
                                </React.Fragment>
                            )}

                        {asoContext &&
                            asoContext.comparisonData &&
                            asoContext.comparisonData.rows &&
                            asoContext.comparisonData.rows.length > 0 && (
                                <React.Fragment>
                                    <ScenarioComparisonHeader
                                        optimizationLevel={asoContext.comparisonOptimizationLevel}
                                        scenarios={asoContext.allScenarios}
                                        callback1={selectScenario1}
                                        callback2={selectScenario2}
                                        scenarioId1={asoContext.scenario1}
                                        scenarioId2={asoContext.scenario2}
                                        onDownload={asoContext.downloadExcel}
                                        downloadTooltip="Download Scenario data as Excel"
                                        titlePrefix={"Shifts per"}
                                    />

                                    <ContentBox className={"datagrid-widget scenario-comparison-grid-wrapper"}>
                                        <DataGridContextProvider>
                                            <ScenarioComparisonGrid
                                                data={asoContext.comparisonData}
                                                requestData={asoContext.comparisonRequestData}
                                                optimizationLevel={asoContext.comparisonOptimizationLevel}
                                                scenario1Name={asoContext.scenario1data.adspend_scenario_name}
                                                scenario2Name={asoContext.scenario2data.adspend_scenario_name}
                                            />
                                        </DataGridContextProvider>
                                    </ContentBox>
                                </React.Fragment>
                            )}

                        {asoContext && asoContext.channelData && asoContext.subCampaignData && (
                            <MediaPlanEfficiencyWrapper
                                mediaPlanType={asoContext.comparisonOptimizationLevel.toLowerCase()}
                            />
                        )}
                    </React.Fragment>
                )}
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
        appContext: appcontextSelectors.getAppContext(state),
    }
}

const AdSpendOptimizerWithState = connect(mapStateToProps)(AdSpendOptimizer)

export default (props) => (
    <AdSpendOptimizerProvider>
        <AdSpendOptimizerWithState {...props} />
    </AdSpendOptimizerProvider>
)
