import { createSvgIcon } from "@mui/material"
import React from "react"

const SmallCheckmarkIcon = createSvgIcon(
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M1.75 5.94957L4.61814 8.73211L10.25 3.26782"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>,
    "Checkmark",
)

export default SmallCheckmarkIcon
