import { CheckCircleOutline, DeleteOutline, EditOutlined } from "@mui/icons-material"
import { ButtonGroup, IconButton, Tooltip } from "@mui/material"
import React from "react"

export const ScenarioListStandardIcons = {
    check: <CheckCircleOutline fontSize="inherit" sx={{ fontSize: 16 }} />,
    edit: <EditOutlined fontSize="inherit" sx={{ fontSize: 16 }} />,
    delete: <DeleteOutline fontSize="inherit" sx={{ fontSize: 16 }} />,
}

export const ScenarioGridInlineMenu = ({ buttons, handleAction }) => {
    return (
        <ButtonGroup size="small">
            {buttons &&
                buttons.length > 0 &&
                buttons.map((button, index) => (
                    <Tooltip key={index} title={button.action.name}>
                        <IconButton
                            className={`btn-${button.icon}`}
                            size="small"
                            sx={{ pl: 0.5, pr: 0.5, maxWidth: "70px" }}
                            onClick={() => handleAction(button)}
                        >
                            {button.icon && ScenarioListStandardIcons[button.icon]}
                        </IconButton>
                    </Tooltip>
                ))}
        </ButtonGroup>
    )
}

export default ScenarioGridInlineMenu
