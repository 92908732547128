import { TimeSpanDTO, TimeSpanDTOTypeEnum } from "generated/models"
import moment, { Moment } from "moment"

export type TimeSpanDates = [start: Moment, end: Moment]

export type TimeSpan = Omit<TimeSpanDTO, "includeTime"> & { includeTime?: boolean }

type TimeSpanTypeSetting = {
    longText: string
    shortText: string
    getValue: () => TimeSpanDates
    getPreviousValue?: () => TimeSpanDates
}

type TimeSpanTypeSettings = {
    [K in TimeSpanDTOTypeEnum]: TimeSpanTypeSetting
}

export const timespanTypeConfigs = {
    CUSTOM: {
        longText: "Fixed range",
        shortText: "Fixed range",
        getValue: () => {
            const today = moment()
            return [today.clone().startOf("day"), today.clone().endOf("day")]
        },
    },
    TODAY: {
        longText: "Today",
        shortText: "Today",
        getValue: () => {
            const today = moment()
            return [today.clone().startOf("day"), today.clone().endOf("day")]
        },
        getPreviousValue: () => {
            const yesterday = moment().subtract(1, "day")
            return [yesterday.clone().startOf("day"), yesterday.clone().endOf("day")]
        },
    },
    YESTERDAY: {
        longText: "Yesterday",
        shortText: "Yesterday",
        getValue: () => {
            const yesterday = moment().subtract(1, "day")
            return [yesterday.clone().startOf("day"), yesterday.clone().endOf("day")]
        },
    },
    THIS_WEEK_INCLUDING_TODAY: {
        longText: "This week",
        shortText: "week",
        getValue: () => {
            const today = moment()
            return [today.clone().startOf("week"), today.clone().endOf("day")]
        },
        getPreviousValue: () => {
            const lastWeek = moment().subtract(1, "week")
            return [lastWeek.clone().startOf("week"), lastWeek.clone().endOf("week")]
        },
    },
    THIS_WEEK_EXCLUDING_TODAY: {
        longText: "This week",
        shortText: "week",
        getValue: () => {
            const today = moment()
            const start = today.clone().startOf("week")

            if (today.isSame(start, "day")) {
                const lastWeek = today.clone().subtract(1, "week")
                return [lastWeek.clone().startOf("week"), lastWeek.clone().endOf("week")]
            }

            return [start, today.clone().subtract(1, "day").endOf("day")]
        },
        getPreviousValue: () => {
            const lastWeek = moment().subtract(1, "week")
            return [lastWeek.clone().startOf("week"), lastWeek.clone().endOf("week")]
        },
    },
    THIS_MONTH_INCLUDING_TODAY: {
        longText: "This month",
        shortText: "month",
        getValue: () => {
            const today = moment()
            return [today.clone().startOf("month"), today.clone().endOf("day")]
        },
        getPreviousValue: () => {
            const lastMonth = moment().subtract(1, "month")
            return [lastMonth.clone().startOf("month"), lastMonth.clone().endOf("month")]
        },
    },
    THIS_MONTH_EXCLUDING_TODAY: {
        longText: "This month",
        shortText: "month",
        getValue: () => {
            const today = moment()
            const start = today.clone().startOf("month")

            if (today.isSame(start, "day")) {
                const lastMonth = today.clone().subtract(1, "month")
                return [lastMonth.clone().startOf("month"), lastMonth.clone().endOf("month")]
            }

            return [start, today.clone().subtract(1, "day").endOf("day")]
        },
        getPreviousValue: () => {
            const lastMonth = moment().subtract(1, "month")
            return [lastMonth.clone().startOf("month"), lastMonth.clone().endOf("month")]
        },
    },
    THIS_QUARTER_INCLUDING_TODAY: {
        longText: "This quarter",
        shortText: "quarter",
        getValue: () => {
            const today = moment()
            return [today.clone().startOf("quarter"), today.clone().endOf("day")]
        },
        getPreviousValue: () => {
            const lastQuarter = moment().subtract(1, "quarter")
            return [lastQuarter.clone().startOf("quarter"), lastQuarter.clone().endOf("quarter")]
        },
    },
    THIS_QUARTER_EXCLUDING_TODAY: {
        longText: "This quarter",
        shortText: "quarter",
        getValue: () => {
            const today = moment()
            const start = today.clone().startOf("quarter")

            if (today.isSame(start, "day")) {
                const lastQuarter = today.clone().subtract(1, "quarter")
                return [lastQuarter.clone().startOf("quarter"), lastQuarter.clone().endOf("quarter")]
            }

            return [start, today.clone().subtract(1, "day").endOf("day")]
        },
        getPreviousValue: () => {
            const lastQuarter = moment().subtract(1, "quarter")
            return [lastQuarter.clone().startOf("quarter"), lastQuarter.clone().endOf("quarter")]
        },
    },
    THIS_YEAR_INCLUDING_TODAY: {
        longText: "This year",
        shortText: "year",
        getValue: () => {
            const today = moment()
            return [today.clone().startOf("year"), today.clone().endOf("day")]
        },
        getPreviousValue: () => {
            const lastYear = moment().subtract(1, "year")
            return [lastYear.clone().startOf("year"), lastYear.clone().endOf("year")]
        },
    },
    THIS_YEAR_EXCLUDING_TODAY: {
        longText: "This year",
        shortText: "year",
        getValue: () => {
            const today = moment()
            const start = today.clone().startOf("year")

            if (today.isSame(start, "day")) {
                const lastYear = today.clone().subtract(1, "year")
                return [lastYear.clone().startOf("year"), lastYear.clone().endOf("year")]
            }

            return [start, today.clone().subtract(1, "day").endOf("day")]
        },
        getPreviousValue: () => {
            const lastYear = moment().subtract(1, "year")
            return [lastYear.clone().startOf("year"), lastYear.clone().endOf("year")]
        },
    },
    LAST_WEEK: {
        longText: "Last week",
        shortText: "week",
        getValue: () => {
            const lastWeek = moment().subtract(1, "week")
            return [lastWeek.clone().startOf("week"), lastWeek.clone().endOf("week")]
        },
    },
    LAST_MONTH: {
        longText: "Last month",
        shortText: "month",
        getValue: () => {
            const lastMonth = moment().subtract(1, "month")
            return [lastMonth.clone().startOf("month"), lastMonth.clone().endOf("month")]
        },
    },
    LAST_7_DAYS_INCLUDING_TODAY: {
        longText: "Last 7 days",
        shortText: "7 days",
        getValue: () => {
            const today = moment()
            return [today.clone().subtract(6, "days").startOf("day"), today.clone().endOf("day")]
        },
        getPreviousValue: () => {
            const end = moment().subtract(7, "days").endOf("day")
            return [end.clone().subtract(6, "days").startOf("day"), end]
        },
    },
    LAST_7_DAYS_EXCLUDING_TODAY: {
        longText: "Last 7 days",
        shortText: "7 days",
        getValue: () => {
            const today = moment()
            const end = today.clone().subtract(1, "day").endOf("day")
            return [end.clone().subtract(6, "days").startOf("day"), end]
        },
        getPreviousValue: () => {
            const end = moment().subtract(8, "days").endOf("day")
            return [end.clone().subtract(6, "days").startOf("day"), end]
        },
    },
    LAST_30_DAYS_INCLUDING_TODAY: {
        longText: "Last 30 days",
        shortText: "30 days",
        getValue: () => {
            const today = moment()
            return [today.clone().subtract(29, "days").startOf("day"), today.clone().endOf("day")]
        },
        getPreviousValue: () => {
            const end = moment().subtract(30, "days").endOf("day")
            return [end.clone().subtract(29, "days").startOf("day"), end]
        },
    },
    LAST_30_DAYS_EXCLUDING_TODAY: {
        longText: "Last 30 days",
        shortText: "30 days",
        getValue: () => {
            const today = moment()
            const end = today.clone().subtract(1, "day").endOf("day")
            return [end.clone().subtract(29, "days").startOf("day"), end]
        },
        getPreviousValue: () => {
            const end = moment().subtract(31, "days").endOf("day")
            return [end.clone().subtract(29, "days").startOf("day"), end]
        },
    },
    LAST_QUARTER: {
        longText: "Last quarter",
        shortText: "quarter",
        getValue: () => {
            const lastQuarter = moment().subtract(1, "quarter")
            return [lastQuarter.clone().startOf("quarter"), lastQuarter.clone().endOf("quarter")]
        },
    },
    LAST_90_DAYS_INCLUDING_TODAY: {
        longText: "Last 90 days",
        shortText: "90 days",
        getValue: () => {
            const today = moment()
            return [today.clone().subtract(89, "days").startOf("day"), today.clone().endOf("day")]
        },
        getPreviousValue: () => {
            const end = moment().subtract(90, "days").endOf("day")
            return [end.clone().subtract(89, "days").startOf("day"), end]
        },
    },
    LAST_90_DAYS_EXCLUDING_TODAY: {
        longText: "Last 90 days",
        shortText: "90 days",
        getValue: () => {
            const today = moment()
            const end = today.clone().subtract(1, "day").endOf("day")
            return [end.clone().subtract(89, "days").startOf("day"), end]
        },
        getPreviousValue: () => {
            const end = moment().subtract(91, "days").endOf("day")
            return [end.clone().subtract(89, "days").startOf("day"), end]
        },
    },
    LAST_YEAR: {
        longText: "Last year",
        shortText: "year",
        getValue: () => {
            const lastYear = moment().subtract(1, "year")
            return [lastYear.clone().startOf("year"), lastYear.clone().endOf("year")]
        },
    },
    LAST_365_DAYS_INCLUDING_TODAY: {
        longText: "Last 365 days",
        shortText: "365 days",
        getValue: () => {
            const today = moment()
            return [today.clone().subtract(364, "days").startOf("day"), today.clone().endOf("day")]
        },
        getPreviousValue: () => {
            const end = moment().subtract(365, "days").endOf("day")
            return [end.clone().subtract(364, "days").startOf("day"), end]
        },
    },
    LAST_365_DAYS_EXCLUDING_TODAY: {
        longText: "Last 365 days",
        shortText: "365 days",
        getValue: () => {
            const today = moment()
            const end = today.clone().subtract(1, "day").endOf("day")
            return [end.clone().subtract(364, "days").startOf("day"), end]
        },
        getPreviousValue: () => {
            const end = moment().subtract(366, "days").endOf("day")
            return [end.clone().subtract(364, "days").startOf("day"), end]
        },
    },
} as const satisfies TimeSpanTypeSettings

export const formatDateRange = (start: Moment | null, end: Moment | null): string => {
    if (!start && !end) return ""
    if (!start && end) return `- ${end.format("DD.MM.YYYY")}`
    if (start && !end) return `${start.format("DD.MM.YYYY")} -`

    // If both dates exist and are the same day, return just one date
    if (start && end && start.isSame(end, "day")) {
        return start.format("DD.MM.YYYY")
    }

    return `${start!.format("DD.MM.YYYY")} - ${end!.format("DD.MM.YYYY")}`
}

export const formatTimeSpan = (timeSpan: TimeSpan): string => {
    if (timeSpan.type === "CUSTOM") {
        return formatDateRange(moment(timeSpan.startDate), moment(timeSpan.endDate))
    }

    const [start, end] = timespanTypeConfigs[timeSpan.type].getValue()
    return formatDateRange(start, end)
}

export const getDatesFromTimeSpan = (timeSpan: TimeSpan): TimeSpanDates => {
    if (timeSpan.type === "CUSTOM") {
        return [moment(timeSpan.startDate), moment(timeSpan.endDate)]
    }

    return timespanTypeConfigs[timeSpan.type].getValue()
}

export const toDateRange = (timespan: TimeSpan): [moment.Moment, moment.Moment] => {
    if (timespan.type === TimeSpanDTOTypeEnum.CUSTOM) {
        return [moment(timespan.startDate), moment(timespan.endDate)]
    }
    const config = timespanTypeConfigs[timespan.type]
    const range = config.getValue()
    return [range[0].clone(), range[1].clone()]
}

const periodUnits = ["year", "quarter", "month", "week"] as const

export const getNextTimeSpan = (timespan: TimeSpan): TimeSpan => {
    const today = moment().startOf("day")

    // For custom timespans, use the existing logic
    if (timespan.type === TimeSpanDTOTypeEnum.CUSTOM) {
        const [initialStart, initialEnd] = toDateRange(timespan)
        const diff = initialEnd.diff(initialStart, "days")

        const forwardStart = moment(initialEnd).add(1, "day").startOf("day")
        const forwardEnd = forwardStart.clone().add(diff, "days").endOf("day")

        // If any part of the next period would be in the future, return today's date range
        if (forwardEnd.isAfter(today)) {
            return {
                type: TimeSpanDTOTypeEnum.CUSTOM,
                startDate: today.clone().startOf("day").format(),
                endDate: today.clone().endOf("day").format(),
                includeTime: false,
            }
        }

        return {
            type: TimeSpanDTOTypeEnum.CUSTOM,
            startDate: forwardStart.format(),
            endDate: forwardEnd.format(),
            includeTime: false,
        }
    }

    // For predefined timespan types, use their specific configurations
    const config = timespanTypeConfigs[timespan.type]
    const [start, end] = config.getValue()

    // For types that include "today", return the current period
    if (timespan.type.includes("INCLUDING_TODAY")) {
        return {
            type: TimeSpanDTOTypeEnum.CUSTOM,
            startDate: start.format(),
            endDate: end.format(),
            includeTime: false,
        }
    }

    // For day-based types (LAST_X_DAYS)
    if (timespan.type.includes("DAYS")) {
        const days = end.diff(start, "days") + 1
        const nextStart = end.clone().add(1, "day").startOf("day")
        if (nextStart.isAfter(today)) {
            return {
                type: TimeSpanDTOTypeEnum.CUSTOM,
                startDate: today.clone().startOf("day").format(),
                endDate: today.clone().endOf("day").format(),
                includeTime: false,
            }
        }
        const nextEnd = nextStart
            .clone()
            .add(days - 1, "days")
            .endOf("day")
        if (nextEnd.isAfter(today)) {
            return {
                type: TimeSpanDTOTypeEnum.CUSTOM,
                startDate: nextStart.format(),
                endDate: today.clone().endOf("day").format(),
                includeTime: false,
            }
        }
        return {
            type: TimeSpanDTOTypeEnum.CUSTOM,
            startDate: nextStart.format(),
            endDate: nextEnd.format(),
            includeTime: false,
        }
    }

    // For standard periods (month, quarter, year, week)
    for (const unit of periodUnits) {
        if (start.isSame(start.clone().startOf(unit), "day") && end.isSame(end.clone().endOf(unit), "day")) {
            const nextStart = end.clone().add(1, "day").startOf("day")
            if (nextStart.isAfter(today)) {
                return {
                    type: TimeSpanDTOTypeEnum.CUSTOM,
                    startDate: today.clone().startOf("day").format(),
                    endDate: today.clone().endOf("day").format(),
                    includeTime: false,
                }
            }
            const nextEnd = nextStart.clone().endOf(unit)
            if (nextEnd.isAfter(today)) {
                return {
                    type: TimeSpanDTOTypeEnum.CUSTOM,
                    startDate: nextStart.format(),
                    endDate: today.clone().endOf("day").format(),
                    includeTime: false,
                }
            }
            return {
                type: TimeSpanDTOTypeEnum.CUSTOM,
                startDate: nextStart.format(),
                endDate: nextEnd.format(),
                includeTime: false,
            }
        }
    }

    // If no specific handling was found, return today's date range
    return {
        type: TimeSpanDTOTypeEnum.CUSTOM,
        startDate: today.clone().startOf("day").format(),
        endDate: today.clone().endOf("day").format(),
        includeTime: false,
    }
}

export const getPreviousTimeSpan = (timespan: TimeSpan): TimeSpan => {
    // For custom timespans, use day-difference-logic
    if (timespan.type === TimeSpanDTOTypeEnum.CUSTOM) {
        const [initialStart, initialEnd] = toDateRange(timespan)

        // Check if this is a month-long period
        if (
            initialStart.isSame(initialStart.clone().startOf("month"), "day") &&
            initialEnd.isSame(initialEnd.clone().endOf("month"), "day")
        ) {
            const prevMonth = initialStart.clone().subtract(1, "month")
            return {
                type: TimeSpanDTOTypeEnum.CUSTOM,
                startDate: prevMonth.startOf("month").format(),
                endDate: prevMonth.endOf("month").format(),
                includeTime: false,
            }
        }

        // For other custom timespans, use day-difference-logic
        const diff = initialEnd.diff(initialStart, "days")
        const backwardEnd = initialStart.clone().subtract(1, "day").endOf("day")
        const backwardStart = backwardEnd.clone().subtract(diff, "days").startOf("day")

        return {
            type: TimeSpanDTOTypeEnum.CUSTOM,
            startDate: backwardStart.format(),
            endDate: backwardEnd.format(),
            includeTime: false,
        }
    }

    // For predefined timespan types, use their specific configurations
    const config = timespanTypeConfigs[timespan.type]
    const [start, end] = config.getValue()

    // If there's a getPreviousValue method, use it
    if ("getPreviousValue" in config) {
        const [prevStart, prevEnd] = config.getPreviousValue()
        return {
            type: TimeSpanDTOTypeEnum.CUSTOM,
            startDate: prevStart.format(),
            endDate: prevEnd.format(),
            includeTime: false,
        }
    }

    // For day-based types (LAST_X_DAYS)
    if (timespan.type.includes("DAYS")) {
        const days = end.diff(start, "days") + 1
        const prevEnd = start.clone().subtract(1, "day").endOf("day")
        const prevStart = prevEnd
            .clone()
            .subtract(days - 1, "days")
            .startOf("day")
        return {
            type: TimeSpanDTOTypeEnum.CUSTOM,
            startDate: prevStart.format(),
            endDate: prevEnd.format(),
            includeTime: false,
        }
    }

    // For standard periods (month, quarter, year, week)
    for (const unit of periodUnits) {
        if (start.isSame(start.clone().startOf(unit), "day") && end.isSame(end.clone().endOf(unit), "day")) {
            const prevStart = start.clone().subtract(1, unit).startOf(unit)
            return {
                type: TimeSpanDTOTypeEnum.CUSTOM,
                startDate: prevStart.format(),
                endDate: prevStart.clone().endOf(unit).format(),
                includeTime: false,
            }
        }
    }

    // If no specific handling was found, return yesterday's date range
    const yesterday = moment().subtract(1, "day")
    return {
        type: TimeSpanDTOTypeEnum.CUSTOM,
        startDate: yesterday.clone().startOf("day").format(),
        endDate: yesterday.clone().endOf("day").format(),
        includeTime: false,
    }
}
