import { CheckCircleOutline, DeleteOutline, EditOutlined } from "@mui/icons-material"
import React from "react"
import { Item, Menu, animation } from "react-contexify"

type Props = {
    menuId: string
    handleSelect: (id: number | string) => void
    handleEdit: (id: number | string) => void
    handleDelete: (id: number | string) => void
}

const ScenarioGridContextMenu = ({ menuId, handleSelect, handleEdit, handleDelete }: Props) => {
    return (
        <Menu id={menuId} animation={animation.zoom} style={{ zIndex: 10001 }}>
            <Item onClick={({ props }) => handleSelect(props["item"]?.value)}>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <CheckCircleOutline fontSize={"small"} style={{ marginRight: 5 }} />
                    <span>Select this Scenario for Comparison</span>
                </div>
            </Item>
            <Item onClick={({ props }) => handleEdit(props["item"]?.value)}>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <EditOutlined fontSize={"small"} style={{ marginRight: 5 }} />
                    <span>Edit</span>
                </div>
            </Item>
            <Item onClick={({ props }) => handleDelete(props["item"]?.value)}>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <DeleteOutline fontSize={"small"} style={{ marginRight: 5 }} />
                    <span>Delete</span>
                </div>
            </Item>
        </Menu>
    )
}

export default ScenarioGridContextMenu
