/* eslint-disable @typescript-eslint/no-unused-expressions */
import { Box, FormControl, FormHelperText, MenuItem, Stack, TextField, Typography } from "@mui/material"
import Select from "@mui/material/Select"
import { LocalizationProvider } from "@mui/x-date-pickers-pro"
import { AdapterMoment } from "@mui/x-date-pickers-pro/AdapterMoment"
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker"
import { SingleInputDateRangeField } from "@mui/x-date-pickers-pro/SingleInputDateRangeField"
import { MediaPlanHeader } from "domain/adspend-optimizer/component/scenario-form/MediaPlanHeader"
import { MediaPlanHeadline } from "domain/adspend-optimizer/component/scenario-form/MediaPlanHeadline"
import MediaPlanRow from "domain/adspend-optimizer/component/scenario-form/MediaPlanRow"
import {
    ADSPEND_SCENARIO_DIMENSION_IDENTIFIER,
    AdSpendOptimizerContext,
    CAMPAIGN_DIMENSION_IDENTIFIER,
    CHANNEL_DIMENSION_IDENTIFIER,
    END_DATE_DIMENSION_IDENTIFIER,
    START_DATE_DIMENSION_IDENTIFIER,
    STATUS_DIMENSION_IDENTIFIER,
    SUB_CAMPAIGN_DIMENSION_IDENTIFIER,
} from "domain/adspend-optimizer/context/AdSpendOptimizerContext"
import AdSpendOptimizerService from "domain/adspend-optimizer/service/adspend-optimizer.service"
import DimensionService, { ColumnField, asDataColumnIdentifier } from "domain/dimension/service/DimensionService"
import { GridDataRowDTO, Scenario } from "domain/types"
import { FormikProps, useFormik } from "formik"
import moment, { Moment } from "moment"
import React, { type JSX, useContext, useEffect, useState } from "react"
import { FaHandPointRight } from "react-icons/fa"
import { dateString } from "shared/util/util"
import * as Yup from "yup"

const rangePickerFooter = () => (
    <span>
        <FaHandPointRight style={{ marginTop: "3px", marginRight: "7px" }} />
        Please select a forecast period starting on a <em>Monday</em> and ending on a <em>Sunday</em>.
    </span>
)

type Props = {
    mode: "ADD" | "EDIT"
    campaignId: number
    campaignName: string
    channelData: GridDataRowDTO[]
    subCampaignData: GridDataRowDTO[]
    scenario?: GridDataRowDTO
}

// Create validation schema
const ValidationSchema = Yup.object().shape({
    adspend_scenario_name: Yup.string().required("This field is required!"),
    adspend_scenario_period: Yup.array()
        .required("Please select a forecast period!")
        .length(2, "Please select both start and end dates!")
        .of(Yup.date().typeError("Invalid date format").required("Both dates are required")),
})

// We need to create a proper type for MediaPlanRow component to ensure it accepts formikProps
type MediaPlanRowProps = {
    form?: any
    formikProps?: FormikProps<any>
    index: number
    status: number
    type: string
    optimizationLevel: string
    id: number
    name: string
    adspend_budget: number
    mediaPlanPeriod: string[]
    scenarioPeriod: string[]
    flighting: string
    weeks: number
    mode: "ADD" | "EDIT"
}

const ScenarioForm: React.FC<Props> = React.memo<Props>((props: Props): JSX.Element => {
    const asoContext = useContext(AdSpendOptimizerContext)

    const campaignData: GridDataRowDTO = {
        campaign: {
            value: asoContext && asoContext.campaignData ? asoContext.campaignData.campaignId : props.campaignId,
            name: asoContext && asoContext.campaignData ? asoContext.campaignData.campaignName : props.campaignName,
        },
        status: { value: 1 },
        adspend_budget: {
            value: Math.floor(props.channelData.reduce((p, v) => p + (v.adspend_costs.value as number), 0)),
        },
        period: null,
        flighting_pattern: { value: "even" },
        type: { value: "CAMPAIGN" },
    }

    // construct media plan item array
    const mp: GridDataRowDTO[] =
        props.mode === "ADD"
            ? [
                  { ...campaignData },
                  ...props.channelData.map(
                      (item) =>
                          ({
                              ...item,
                              type: { value: "CHANNEL" },
                              status: { value: 1 },
                              adspend_budget: item.adspend_costs,
                          }) as GridDataRowDTO,
                  ),
                  ...props.subCampaignData.map(
                      (item) =>
                          ({
                              ...item,
                              type: { value: "SUB_CAMPAIGN" },
                              status: { value: 1 },
                              adspend_budget: item.adspend_costs,
                          }) as GridDataRowDTO,
                  ),
              ]
            : []
    if (props.mode === "EDIT") {
        if (props.scenario.mediaplan_group_by.value === "campaign_id") {
            mp.push(
                ...props.scenario.adspend_media_plans.data.map((item) => ({
                    ...item,
                    type: { value: "CAMPAIGN" },
                })),
            )
        } else {
            mp.push({ ...campaignData })
        }
        if (props.scenario.mediaplan_group_by.value === "channel_id") {
            mp.push(
                ...props.scenario.adspend_media_plans.data.map((item) => ({
                    ...item,
                    type: { value: "CHANNEL" },
                })),
            )
        } else {
            mp.push(
                ...props.channelData.map((channelData) => ({
                    ...channelData,
                    status: { value: 1 },
                    adspend_budget: channelData.adspend_costs,
                    type: { value: "CHANNEL" },
                })),
            )
        }
        if (props.scenario.mediaplan_group_by.value === "sub_campaign_id") {
            mp.push(
                ...props.scenario.adspend_media_plans.data.map((mediaPlan) => ({
                    ...mediaPlan,
                    type: { value: "SUB_CAMPAIGN" },
                })),
            )
        } else {
            mp.push(
                ...props.subCampaignData.map((subCampaignData) => ({
                    ...subCampaignData,
                    status: { value: 1 },
                    adspend_budget: subCampaignData.adspend_costs,
                    type: { value: "SUB_CAMPAIGN" },
                })),
            )
        }
    }

    const [periodStart, setPeriodStart] = useState(
        props.mode === "ADD" ? null : (props.scenario[START_DATE_DIMENSION_IDENTIFIER].value as string),
    )
    const [periodEnd, setPeriodEnd] = useState(
        props.mode === "ADD" ? null : (props.scenario[END_DATE_DIMENSION_IDENTIFIER].value as string),
    )
    const [optimizationLevel, setOptimizationLevel] = useState(
        props.mode === "ADD"
            ? "CAMPAIGN"
            : AdSpendOptimizerService.getOptimizationLevel(props.scenario.mediaplan_group_by.value as string),
    )
    const [mediaPlanItems] = useState([...mp])
    const [periodWeeks, setPeriodWeeks] = useState(0)

    useEffect(() => {
        if (periodStart && periodEnd) {
            const duration = moment.parseZone(periodEnd).add(1, "day").diff(moment.parseZone(periodStart), "week")
            setPeriodWeeks(duration)
        } else {
            setPeriodWeeks(0)
        }
    }, [periodStart, periodEnd])

    // Initial form values
    const initialValues = {
        adspend_scenario_id: props.mode === "EDIT" ? props.scenario[ADSPEND_SCENARIO_DIMENSION_IDENTIFIER].value : "",
        adspend_scenario_name: props.mode === "EDIT" ? props.scenario[ADSPEND_SCENARIO_DIMENSION_IDENTIFIER].name : "",
        adspend_scenario_period:
            props.mode === "EDIT" ? [moment.parseZone(periodStart), moment.parseZone(periodEnd)] : [null, null],
        adspend_scenario_comment: props.mode === "EDIT" ? props.scenario.comment.value : "",
        adspend_scenario_optimization_level: optimizationLevel,
        adspend_mediaplan_type: mediaPlanItems.map((item) => (item.type.value as string).toLowerCase()),
        adspend_campaign_id: mediaPlanItems.map((item) => item.campaign?.value),
        adspend_channel_id: mediaPlanItems.map((item) => item.channel?.value),
        adspend_sub_campaign_id: mediaPlanItems.map((item) => item.sub_campaign?.value),
        adspend_status_id: mediaPlanItems.map((item) => item[STATUS_DIMENSION_IDENTIFIER].value),
        adspend_planned_budget: mediaPlanItems.map((item) => item.adspend_budget.value),
        adspend_flighting_pattern: mediaPlanItems.map((item) => item.flighting_pattern?.value || "even"),
    }

    /**
     * Maps frontend keys to the backend keys
     *
     * @param key
     */
    const keyMapping = (key: string): string => {
        const mappings = {
            channel_id: DimensionService.getValueColumn(asDataColumnIdentifier(CHANNEL_DIMENSION_IDENTIFIER)),
            campaign_id: DimensionService.getValueColumn(asDataColumnIdentifier(CAMPAIGN_DIMENSION_IDENTIFIER)),
            sub_campaign_id: DimensionService.getValueColumn(asDataColumnIdentifier(SUB_CAMPAIGN_DIMENSION_IDENTIFIER)),
            flighting_pattern: DimensionService.getValueColumn(asDataColumnIdentifier("flighting_pattern")),
            status_id: DimensionService.getValueColumn(asDataColumnIdentifier(STATUS_DIMENSION_IDENTIFIER)),
        }

        return mappings[key]
    }

    const disabledDate = (value: Moment, position: "start" | "end"): boolean => {
        return position === "end"
            ? moment(value).day() !== 0 || moment(value).isBefore(moment.parseZone(periodStart))
            : moment(value).day() !== 1
    }

    const handleRangePickerChange = (values: Moment[]) => {
        if (!values || values.length === 0) {
            setPeriodStart(null)
            setPeriodEnd(null)
        } else {
            setPeriodStart(values[0] ? dateString(values[0]) : null)
            setPeriodEnd(values[1] ? dateString(values[1]) : null)
        }
    }

    // Handle form submission
    const handleSubmit = (values, { setSubmitting }) => {
        const prefix = `${optimizationLevel}`.toLowerCase()
        const mediaPlan = []

        // Find all indices by looking for adspend_mediaplan_type keys
        const indices = values["adspend_mediaplan_type"]

        indices.forEach((row, index) => {
            if (values[`adspend_mediaplan_type`]?.[index] === prefix) {
                const mediaPlanItem = {}
                mediaPlanItem[ColumnField.valueField(CAMPAIGN_DIMENSION_IDENTIFIER).toString()] = props.campaignId
                mediaPlanItem[ColumnField.valueField(STATUS_DIMENSION_IDENTIFIER).toString()] = values[
                    `adspend_status_id`
                ]?.[index]
                    ? 1
                    : 0
                mediaPlanItem[ColumnField.valueField("adspend_budget").toString()] = Math.floor(
                    Number(values[`adspend_planned_budget`]?.[index]),
                )
                mediaPlanItem[keyMapping(`${prefix}_id`)] = values[`adspend_${prefix}_id`]?.[index]
                mediaPlanItem[keyMapping("flighting_pattern")] = values[`adspend_flighting_pattern`]?.[index]
                mediaPlanItem[ColumnField.valueField(START_DATE_DIMENSION_IDENTIFIER).toString()] =
                    values["adspend_period"]?.[index] && values["adspend_period"]?.[index]?.[0]
                        ? dateString(values["adspend_period"]?.[index]?.[0])
                        : mediaPlanItems[index]?.[START_DATE_DIMENSION_IDENTIFIER]?.value
                          ? mediaPlanItems[index]?.[START_DATE_DIMENSION_IDENTIFIER]?.value
                          : periodStart
                mediaPlanItem[ColumnField.valueField(END_DATE_DIMENSION_IDENTIFIER).toString()] =
                    values["adspend_period"]?.[index] && values["adspend_period"]?.[index]?.[1]
                        ? dateString(values["adspend_period"]?.[index]?.[1])
                        : mediaPlanItems[index]?.[START_DATE_DIMENSION_IDENTIFIER]?.value
                          ? mediaPlanItems[index]?.[END_DATE_DIMENSION_IDENTIFIER]?.value
                          : periodEnd
                mediaPlan.push(mediaPlanItem)
            }
        })

        const scenarioData: Scenario = {
            adspend_scenario_id: values.adspend_scenario_id,
            adspend_scenario_name: values.adspend_scenario_name,
            comment: values.adspend_scenario_comment,
            optimizationLevel: values.adspend_scenario_optimization_level,
            start_date: periodStart,
            end_date: periodEnd,
            mediaPlan: mediaPlan,
        }

        // campaign_id can not be edited, so that is why we submit campaign_id only in the create mode
        if (props.mode === "ADD") {
            scenarioData.campaign_id = props.campaignId
        }

        props.mode === "ADD" ? asoContext.createScenario(scenarioData) : asoContext.editScenario(scenarioData)

        setSubmitting(false)
        asoContext.resetSubmitInvocation && asoContext.resetSubmitInvocation()
    }

    const formik = useFormik({
        initialValues,
        validationSchema: ValidationSchema,
        onSubmit: handleSubmit,
    })

    useEffect(() => {
        if (asoContext && asoContext.invokeFormSubmit) {
            formik.submitForm()
            asoContext.resetSubmitInvocation && asoContext.resetSubmitInvocation()
        }
    }, [asoContext?.invokeFormSubmit])

    useEffect(() => {
        if (asoContext?.showScenarioForm === false) {
            formik.resetForm()
        }
    }, [asoContext?.showScenarioForm])

    return (
        <form onSubmit={formik.handleSubmit} className="adspend-optimizer">
            <Stack spacing={2}>
                {props.mode === "EDIT" && (
                    <input type="hidden" name="adspend_scenario_id" value={formik.values.adspend_scenario_id} />
                )}

                <Box className="adspend-form-item" display="flex">
                    <Box className="adspend-label" width={130}>
                        <Typography>Scenario Name</Typography>
                    </Box>
                    <Box>
                        <FormControl
                            error={formik.touched.adspend_scenario_name && Boolean(formik.errors.adspend_scenario_name)}
                        >
                            <TextField
                                name="adspend_scenario_name"
                                placeholder="Scenario Name"
                                defaultValue={formik.values.adspend_scenario_name}
                                onChange={formik.handleChange}
                                style={{ width: 300 }}
                                error={
                                    formik.touched.adspend_scenario_name && Boolean(formik.errors.adspend_scenario_name)
                                }
                                helperText={
                                    formik.touched.adspend_scenario_name &&
                                    typeof formik.errors.adspend_scenario_name === "string"
                                        ? formik.errors.adspend_scenario_name
                                        : ""
                                }
                            />
                        </FormControl>
                    </Box>
                </Box>

                <Box className="adspend-form-item" marginTop="5px" marginBottom="5px" display="flex">
                    <Box className="adspend-label" width={130} marginTop="10px">
                        <Typography>Forecast Period</Typography>
                    </Box>
                    <Box>
                        <div className="scenario-period">
                            <FormControl
                                error={
                                    formik.touched.adspend_scenario_period &&
                                    Boolean(formik.errors.adspend_scenario_period)
                                }
                            >
                                <div style={{ width: "300px" }}>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <DateRangePicker
                                            shouldDisableDate={disabledDate}
                                            onChange={(newValue) => {
                                                handleRangePickerChange(newValue)
                                                formik.setFieldValue("adspend_scenario_period", newValue)
                                            }}
                                            slots={{ field: SingleInputDateRangeField }}
                                            slotProps={{
                                                textField: {
                                                    fullWidth: true,
                                                    size: "small",
                                                    error:
                                                        formik.touched.adspend_scenario_period &&
                                                        Boolean(formik.errors.adspend_scenario_period),
                                                },
                                                actionBar: { actions: [] },
                                                popper: {
                                                    sx: {
                                                        zIndex: "20000",
                                                    },
                                                },
                                            }}
                                            disablePast
                                            format={"DD.MM.YYYY"}
                                            label={"Forecast Period"}
                                            value={formik.values.adspend_scenario_period}
                                        />
                                    </LocalizationProvider>
                                </div>
                                {formik.touched.adspend_scenario_period &&
                                    typeof formik.errors.adspend_scenario_period === "string" && (
                                        <FormHelperText error>{formik.errors.adspend_scenario_period}</FormHelperText>
                                    )}
                            </FormControl>
                            {periodWeeks !== 0 && (
                                <div className={"scenario-period-hint"}>
                                    ({periodWeeks} week{periodWeeks > 1 && "s"})
                                </div>
                            )}
                        </div>
                    </Box>
                </Box>

                <Box className="adspend-form-item" display="flex">
                    <Box className="adspend-label" width={130}>
                        <Typography>Description</Typography>
                    </Box>
                    <Box>
                        <FormControl>
                            <TextField
                                name="adspend_scenario_comment"
                                placeholder="Description"
                                defaultValue={formik.values.adspend_scenario_comment}
                                onChange={formik.handleChange}
                                style={{ width: 300 }}
                                multiline
                                rows={2}
                                inputProps={{ maxLength: 500 }}
                            />
                        </FormControl>
                    </Box>
                </Box>

                <MediaPlanHeadline />

                <Box className="adspend-form-item" display="flex">
                    <Box className="adspend-label" width={130}>
                        <Typography>Optimization Level</Typography>
                    </Box>
                    <Box>
                        <FormControl>
                            <Select
                                name="adspend_scenario_optimization_level"
                                value={formik.values.adspend_scenario_optimization_level}
                                onChange={(e) => {
                                    formik.handleChange(e)
                                    setOptimizationLevel(e.target.value)
                                }}
                                style={{ width: 150 }}
                                MenuProps={{
                                    sx: { zIndex: 10001 },
                                }}
                            >
                                <MenuItem value="CAMPAIGN">Campaign</MenuItem>
                                <MenuItem value="CHANNEL">Channel</MenuItem>
                                <MenuItem value="SUB_CAMPAIGN">Sub Campaign</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                </Box>

                <Box className="media-plan-list">
                    {mediaPlanItems.length > 0 && (
                        <React.Fragment>
                            <MediaPlanHeader optimizationLevel={optimizationLevel.toLowerCase()} />
                            {mediaPlanItems.map((item, index) => {
                                const prefix = (item["type"].value as string).toLowerCase()
                                if (prefix === optimizationLevel.toLowerCase())
                                    return (
                                        <MediaPlanRow
                                            key={`${prefix}_item_${index}`}
                                            formikProps={formik}
                                            index={index}
                                            status={Number(item[STATUS_DIMENSION_IDENTIFIER].value)}
                                            type={prefix}
                                            optimizationLevel={optimizationLevel.toLowerCase()}
                                            id={item[prefix].value as number}
                                            name={item[prefix].name}
                                            adspend_budget={Number(item["adspend_budget"].value)}
                                            mediaPlanPeriod={[
                                                item[START_DATE_DIMENSION_IDENTIFIER]?.value as string,
                                                item[END_DATE_DIMENSION_IDENTIFIER]?.value as string,
                                            ]}
                                            scenarioPeriod={[periodStart || null, periodEnd || null]}
                                            flighting={item["flighting_pattern"]?.value as string}
                                            weeks={Number(periodWeeks)}
                                            mode={props.mode}
                                        />
                                    )
                            })}
                        </React.Fragment>
                    )}
                </Box>
            </Stack>
        </form>
    )
})

export default ScenarioForm
