import { Collapse } from "@mui/material"
import { styled } from "@mui/material/styles"
import { MenuLeafDTO } from "domain/types"
import { MenuNodeDTO } from "generated/models"
import { produce } from "immer"
import { useLayoutContext } from "layout/MainLayout/LayoutContext"
import { CommonMenuPaper } from "layout/MainLayout/Menu/CommonMenuPaper"
import { MenuListItem } from "layout/MainLayout/Menu/MenuListItem"
import { MenuRenderer } from "layout/MainLayout/Menu/MenuRenderer"
import { findPathInTree, findPrintablePathInTree } from "layout/MainLayout/Menu/menuUtils"
import React, { Fragment, useCallback, useEffect, useMemo, useState } from "react"
import { useAnalytics } from "shared/analytics/AnalyticsContext"
import DomUtil from "shared/util/DomUtil"
import { FONT_WEIGHT_BOLD, FONT_WEIGHT_SEMI_BOLD } from "styles/theme/constants"

export const ExpandedMenu = () => {
    const { userConfigs, navigate, pathname } = useLayoutContext()
    const menu = useMemo(() => userConfigs.menu ?? { root: [] }, [userConfigs])
    const analyticsService = useAnalytics()

    const getExpandedNodes = useCallback(() => {
        const result = new Set<string>()
        const path = findPathInTree(pathname, menu.root)
        if (path.length > 0) {
            path.pop() // remove the leaf
            path.forEach((nodePath) => result.add(nodePath))
        }
        return result
    }, [pathname, menu.root])

    const [expandedNodes, setExpandedNodes] = useState(getExpandedNodes)

    const getSelectedLeaf = useCallback(() => {
        const path = findPathInTree(pathname, menu.root)
        return path.length > 0 ? path[path.length - 1] : undefined
    }, [pathname, menu.root])

    const updateSelectedLeafIndicator = useCallback((animate: boolean) => {
        setTimeout(() => {
            const collapsibleNodeClassName = "MuiCollapse-root"
            const indicators = document.querySelectorAll(
                ".main-menu .selected-leaf-indicator",
            ) as NodeListOf<HTMLElement>
            indicators.forEach((indicator) => {
                const rootMenuNode = indicator.closest("." + collapsibleNodeClassName) as HTMLElement
                const selectedItem = rootMenuNode?.querySelector(".menu-list-item.selected") as HTMLElement
                let areAllParentNodesExpanded = true
                if (selectedItem) {
                    const collapsibleParentNodes = DomUtil.getParentsByClass(selectedItem, collapsibleNodeClassName)
                    areAllParentNodesExpanded = collapsibleParentNodes.every((parent) => parent.offsetHeight > 0)
                }
                if (selectedItem && areAllParentNodesExpanded) {
                    const previousPosition = getTranslate3dYValue(indicator)
                    const newPosition = selectedItem.offsetTop + 11
                    const difference = Math.abs(previousPosition - newPosition)
                    const transitionDurationMs = animate ? Math.min(750, difference * 8) : 0
                    indicator.style.transition = "all " + transitionDurationMs + "ms ease-in-out"
                    indicator.style.transform = "translate3d(0, " + newPosition + "px, 0)"
                    indicator.style.height = "5px"
                    indicator.style.opacity = "100%"
                } else {
                    indicator.style.transform = "translate3d(0, 0, 0)"
                    indicator.style.height = "0px"
                    indicator.style.opacity = "0%"
                }
            })
        }, 1)
    }, [])

    const getTranslate3dYValue = (element: HTMLElement): number => {
        try {
            const style = window.getComputedStyle(element)
            const transform = style.transform
            const matrix = transform.replace(/[^0-9\-.,]/g, "").split(",")
            return matrix && matrix[5] ? Number(matrix[5]) : 0
        } catch (e) {
            return 0
        }
    }

    const makeNodeClickHandler = (id: string) => () => {
        setExpandedNodes(
            produce((draft) => {
                if (draft.has(id)) {
                    draft.delete(id)
                } else {
                    draft.add(id)
                }
            }),
        )
        updateSelectedLeafIndicator(false)
    }

    const makeLeafClickHandler = (id: string, path: string) => (event: React.MouseEvent) => {
        event.preventDefault()
        navigate(path)
        updateSelectedLeafIndicator(true)
        const printablePath = findPrintablePathInTree(path, menu.root)
        analyticsService.trackMenuNavigation(printablePath[0], printablePath.join(" / "))
    }

    const renderLeaf = (level: number, item: MenuLeafDTO) => (
        <MenuListItem
            key={item.title}
            type="leaf"
            menuLeafDTO={item}
            level={level}
            isSelected={pathname === item.path}
            onClick={makeLeafClickHandler(item.path, item.path)}
        />
    )

    const renderNode = (level: number, item: MenuNodeDTO, children: React.ReactNode) => (
        <Fragment key={item.path + "_node"}>
            <MenuListItem
                key={item.title}
                type="node"
                menuNodeDTO={item}
                level={level}
                isExpanded={expandedNodes.has(item.path)}
                onClick={makeNodeClickHandler(item.path)}
            />
            <Collapse in={expandedNodes.has(item.path)} timeout={0} unmountOnExit sx={{ overflow: "hidden" }}>
                {children}
            </Collapse>
        </Fragment>
    )

    useEffect(() => {
        const selectedLeaf = getSelectedLeaf()
        if (selectedLeaf) {
            setExpandedNodes(getExpandedNodes())
            updateSelectedLeafIndicator(false)
        }
    }, [getExpandedNodes, getSelectedLeaf, updateSelectedLeafIndicator])

    return (
        <StyledPaper elevation={0} className="main-menu expanded-menu">
            <MenuRenderer level={0} menuItems={menu.root} renderLeaf={renderLeaf} renderNode={renderNode} />
        </StyledPaper>
    )
}

const StyledPaper = styled(CommonMenuPaper)(({ theme }) => ({
    "& .MuiListItemButton-root": {
        "&:hover": {
            backgroundColor: "transparent",
        },
    },
    "& .level-0 span": {
        fontWeight: FONT_WEIGHT_BOLD,
    },
    "& .selected > .MuiListItemButton-root": {
        marginRight: "8px",
        marginLeft: "0px",
        backgroundColor: theme.palette.primaryShades[50],
        borderRadius: "5px",
    },
    "& .selected span": {
        color: theme.palette.primary.main,
        fontWeight: FONT_WEIGHT_SEMI_BOLD,
    },
}))

export default ExpandedMenu
