import CustomTooltip from "layout/components/CustomTooltip"
import React, { ReactElement } from "react"

export interface Props {
    title: string
    children: ReactElement<any, any>
}

export const TopBarTooltip = ({ title, children }: Props) => {
    return (
        <CustomTooltip
            title={title}
            placement="bottom-start"
            enterDelay={0}
            offsetX={20}
            offsetY={-20}
            arrow={false}
            disableInteractive={true}
        >
            {children}
        </CustomTooltip>
    )
}
