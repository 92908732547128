import { log } from "shared/util/log"

// TODO: Not used anywhere, remove?

/**
 * Get's the closest parent element that matches the [parentSelector]
 * @param parentSelector
 * @param elementSelector selector of the element whose parent we want to find
 */
const getParentElement = (parentSelector: string, elementSelector: string): Element => {
    const element = selectElement(elementSelector)
    if (!element) {
        return null
    }

    const parent = element.closest(parentSelector)
    if (!parent) {
        log.warn("No parent element found matching selector '" + parentSelector + "'.")
    }
    return parent
}

const getParentsByClass = (element: HTMLElement, className: string): HTMLElement[] => {
    const parents: HTMLElement[] = []
    let currentElement = element.parentNode as HTMLElement
    while (currentElement !== null) {
        if (currentElement.classList && currentElement.classList.contains(className)) {
            parents.push(currentElement)
        }
        currentElement = currentElement.parentNode as HTMLElement
    }

    return parents
}

/**
 * Gets the height of the the first element that matches the [selector]
 * @param selector
 */
const getElementHeight = (selector: string): number => {
    const element = selectElement(selector)
    if (!element) {
        return 0
    } else {
        return element.clientHeight
    }
}

/**
 * Sets the height of the the first element that matches the [selector]
 * @param selector
 * @param height
 */
const setElementHeight = (selector: string, height: number) => {
    const element = selectElement(selector)
    if (element) {
        element.style.height = Math.round(height) + "px"
    }
}

/**
 * Gets the vertical position of the first element that matches the [selector]
 * @param selector
 */
const getElementVerticalPosition = (selector: string): number => {
    const element = selectElement(selector)
    if (!element) {
        return 0
    } else {
        return element.getBoundingClientRect().y
    }
}

/**
 * Gets the first element that matches the given [selector]
 *
 * @param selector
 */
const selectElement = (selector: string): HTMLElement => {
    const element = document.querySelector(selector)
    if (!element) {
        log.warn("No element found matching selector '" + selector + "'.")
        return null
    } else {
        // @ts-expect-error TODO element is of type Element, but HTMLElement (which is a subtype) is required
        return element
    }
}

const DomUtil = {
    getElementVerticalPosition: getElementVerticalPosition,
    getElementHeight: getElementHeight,
    setElementHeight: setElementHeight,
    getParentElement: getParentElement,
    getParentsByClass: getParentsByClass,
}

export default DomUtil
