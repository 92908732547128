import * as React from "react"

type Props = {
    children: React.ReactNode
    className?: string
}

type State = never

export class ContentBox extends React.Component<Props, State> {
    render() {
        return <div className={(this.props.className || "") + " content-box"}>{this.props.children}</div>
    }
}
