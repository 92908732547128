import { useCopyToClipboardContext } from "domain/copytoclipboard/CopyToClipboardContext"
import ReactHint from "domain/tooltip/ReactHint"
import React, { type JSX } from "react"

/**
 * Renders a copy to clipboard button on mouse over, using a single instance that is repositioned wherever it is needed in order to optimize performance
 */
export const CopyToClipboardHint = (): JSX.Element => {
    const { copyToClipboard } = useCopyToClipboardContext()

    const onRenderContent = (target: Element, _: string | null) => {
        const onClick = () => {
            const textToCopy = target.getAttribute("data-tip") // use the same text as in the tooltip, see props.attribute in ReactHint component
            copyToClipboard(textToCopy)
        }

        // SVG is shamelessly copied from antd website
        return (
            <div className={"react-hint__content"}>
                <button type="button" onClick={onClick} title="Copy to Clipboard" data-force-tooltip="true">
                    <span role="img">
                        <svg viewBox="64 64 896 896" width="1em" height="1em" fill="currentColor">
                            <path d="M832 112H724V72c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v40H500V72c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v40H320c-17.7 0-32 14.3-32 32v120h-96c-17.7 0-32 14.3-32 32v632c0 17.7 14.3 32 32 32h512c17.7 0 32-14.3 32-32v-96h96c17.7 0 32-14.3 32-32V144c0-17.7-14.3-32-32-32zM664 888H232V336h218v174c0 22.1 17.9 40 40 40h174v338zm0-402H514V336h.2L664 485.8v.2zm128 274h-56V456L544 264H360v-80h68v32c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-32h152v32c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-32h68v576z" />
                        </svg>
                    </span>
                </button>
            </div>
        )
    }

    return (
        <ReactHint
            attribute={"data-copy-to-clipboard"} // the copy-to-clipboard is added to all elements which have this attribute set
            autoPosition={false}
            className={"react-hint"}
            additionalClassNames={"copy-to-clipboard-hint hint-no-bg"}
            delay={0}
            events={true}
            onRenderContent={onRenderContent}
            persist={true} // true, otherwise the popup is not reachable with the mouse
            position={"left"}
        />
    )
}
