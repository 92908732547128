import { TimeSpanDTOTypeEnum } from "generated/models"
import React, { PropsWithChildren, Suspense, createContext, useCallback, useContext, useState } from "react"
import { TimeSpan, timespanTypeConfigs } from "shared/util/timespanUtil"

type PeriodsContextProviderType = {
    period: TimeSpan
    previousPeriod: TimeSpan
    setPeriod: React.Dispatch<React.SetStateAction<TimeSpan>>
    setPreviousPeriod: React.Dispatch<React.SetStateAction<TimeSpan>>
}

const PeriodsContext = createContext<PeriodsContextProviderType | undefined>(undefined)

type PeriodsContextProviderProps = {
    initialPeriod?: TimeSpan
}

const last30Days: TimeSpan = {
    type: TimeSpanDTOTypeEnum.LAST_30_DAYS_EXCLUDING_TODAY,
    includeTime: false,
}

export const PeriodsContextProvider = (props: PropsWithChildren<PeriodsContextProviderProps>) => {
    const { children, initialPeriod } = props

    const [period, setPeriod] = useState<TimeSpan>(initialPeriod ?? last30Days)
    const [start, end] = timespanTypeConfigs[period.type].getValue()

    const [previousPeriod, setPreviousPeriod] = useState<TimeSpan>({
        type: TimeSpanDTOTypeEnum.CUSTOM,
        startDate: start?.clone().subtract(30, "days").format(),
        endDate: end?.clone().subtract(30, "days").format(),
        includeTime: false,
    })
    const [hasPreviousPeriodChanged, setHasPreviousPeriodChanged] = useState(false)

    const setPeriodSync: React.Dispatch<React.SetStateAction<TimeSpan>> = useCallback(
        (value) => {
            const newPeriod = typeof value === "function" ? value(period) : value
            setPeriod(newPeriod)

            if (!hasPreviousPeriodChanged) {
                const [start, end] = timespanTypeConfigs[newPeriod.type].getValue()
                if (start && end) {
                    setPreviousPeriod({
                        type: TimeSpanDTOTypeEnum.CUSTOM,
                        startDate: start.clone().subtract(30, "days").format(),
                        endDate: end.clone().subtract(30, "days").format(),
                        includeTime: false,
                    })
                }
            }
        },
        [hasPreviousPeriodChanged, period, setPreviousPeriod, setPeriod],
    )

    const setPreviousPeriodSync: React.Dispatch<React.SetStateAction<TimeSpan>> = useCallback((value) => {
        setPreviousPeriod(value)
        setHasPreviousPeriodChanged(true)
    }, [])

    return (
        <PeriodsContext.Provider
            value={{
                period,
                previousPeriod: previousPeriod,
                setPeriod: (value) => {
                    setPeriodSync(value)
                },
                setPreviousPeriod: (value) => {
                    setPreviousPeriodSync(value)
                },
            }}
        >
            <Suspense>{children}</Suspense>
        </PeriodsContext.Provider>
    )
}

export const usePeriodsContext = () => {
    const context = useContext(PeriodsContext)
    if (context === undefined) {
        throw new Error("usePeriodsContext must be used within a PeriodsContextProvider")
    }
    return context
}
