import { useDashboardSettings } from "../DashboardSettings/DashboardSettingsContext"
import { useDashboardData } from "../hooks/useDashboardData"
import { useDashboardLayoutForms } from "../hooks/useDashboardLayoutForms"
import { DashboardLayoutBaseProvider } from "./DashboardLayoutBaseProvider"
import { DashboardLayoutContext } from "./DashboardLayoutContext"
import { DashboardLayoutItemProps } from "./DashboardLayoutItem"
import { Box } from "@mui/material"
import { useReportingConfigurationContext } from "domain/reporting/ReportingConfigurationContext"
import { ColorManagementProvider } from "domain/widget/ColorManagementContext"
import { ReportingToggleContextProvider } from "domain/widget/ReportingToggleContext"
import MainContentAreaLoadingMask from "layout/MainLayout/Main/MainContentAreaLoadingMask"
import React, { PropsWithChildren, useContext } from "react"
import { log } from "shared/util/log"

export const EMPTY_DASHBOARD_UUID = "empty_dashboard_uuid"

export const DashboardLayoutProvider = ({ children }: PropsWithChildren) => {
    const { uuid: dashboardId } = useDashboardSettings()

    const { dataDefinitions } = useReportingConfigurationContext()
    const { dimensions, metrics } = dataDefinitions

    const { data: dashboardData, status: dashboardsViewStatus } = useDashboardData(dashboardId)
    const { widgetForm, layoutForm } = useDashboardLayoutForms(dashboardData)

    return (
        <DashboardLayoutBaseProvider
            readOnly={dashboardData?.readOnly}
            layoutForm={layoutForm}
            widgetForm={widgetForm}
            setLayout={(items) => layoutForm.setFieldValue("layout", items)}
            pushLayout={(item) => layoutForm.pushFieldValue("layout", item)}
            removeFromLayout={(id: string) => {
                const index = layoutForm.getFieldValue("layout").findIndex((item) => item.id === id)
                if (index === -1) {
                    log.error(`Could not remove widget with id "${id}" because it wasn't found`)
                    return
                }
                layoutForm.removeFieldValue("layout", index)
            }}
        >
            {dashboardsViewStatus === "error" ? (
                // TODO: Add error component
                <Box></Box>
            ) : // TODO: Why do we need to check for the size?
            dashboardsViewStatus === "success" && dimensions.size > 0 && metrics.size > 0 ? (
                <ReportingToggleContextProvider reportingReplaceToggles={dashboardData?.reportingReplaceToggles}>
                    <ColorManagementProvider>{children}</ColorManagementProvider>
                </ReportingToggleContextProvider>
            ) : (
                <MainContentAreaLoadingMask withAdditionalOffset={true} />
            )}
        </DashboardLayoutBaseProvider>
    )
}

type DashboardLayoutNestedProviderProps = {
    layout: DashboardLayoutItemProps[]
    setLayout: (layout: DashboardLayoutItemProps[]) => void
    pushLayout: (layout: DashboardLayoutItemProps) => void
    removeFromLayout: (id: string) => void
    readOnly?: boolean
}

export const DashboardLayoutNestedProvider = (props: PropsWithChildren<DashboardLayoutNestedProviderProps>) => {
    const { layout, setLayout, pushLayout, removeFromLayout, readOnly } = props

    const parentContext = useContext(DashboardLayoutContext)

    const { widgetForm: nestedWidgetForm, layoutForm: nestedLayoutForm } = useDashboardLayoutForms({
        widgets: {},
        layout,
    })
    const widgetForm = parentContext?.widgetForm ?? nestedWidgetForm
    const layoutForm = parentContext?.layoutForm ?? nestedLayoutForm

    return (
        <DashboardLayoutBaseProvider
            {...props}
            readOnly={parentContext?.readOnly ?? readOnly}
            layoutForm={layoutForm}
            widgetForm={widgetForm}
            setLayout={setLayout}
            pushLayout={pushLayout}
            removeFromLayout={removeFromLayout}
        />
    )
}
