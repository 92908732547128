import { LicenseInfo } from "@mui/x-license"
import App from "App"
import { enableMapSet } from "immer"
import React from "react"
import ReactDOM from "react-dom/client"
import { Provider } from "react-redux"
import { BrowserRouter } from "react-router"
import store from "shared/redux/store"
import { log } from "shared/util/log"

LicenseInfo.setLicenseKey(
    "cc8ddfea5fd0e8114ddda9c320aae8b4Tz05MjY1NCxFPTE3NTAyNTc0NzYwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=",
)

// Enable Map/Set in immer
enableMapSet()

log.setDefaultLevel(process.env.NODE_ENV === "production" ? "silent" : "debug")

// React 18 mode
ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>,
)
