import {
    Timeline,
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineItem,
    TimelineSeparator,
    timelineItemClasses,
} from "@mui/lab"
import { Box, DialogContentText, Typography, useTheme } from "@mui/material"
import { styled } from "@mui/material/styles"
import { ArticleDTO, ArticleService } from "domain/content/ArticleService"
import { ContentType } from "domain/types"
import { useNotificationContext } from "layout/MainLayout/UserMenu/Notification/NotificationContext"
import { CustomDialog } from "layout/components/CustomDialog"
import React, { type JSX, useEffect, useState } from "react"
import { FONT_WEIGHT_BOLD, FONT_WEIGHT_MEDIUM, FONT_WEIGHT_SEMI_BOLD } from "styles/theme/constants"

const StyledCustomDialog = styled(CustomDialog)(({ theme }) => ({
    "& .MuiDialog-paper": {},
    "& .MuiTimeline-root": {
        paddingRight: 0,
    },
    "& .MuiTimelineConnector-root": {
        background: "none transparent",
        borderLeft: "2px dashed " + theme.palette.grey[200],
        width: "0px",
    },
    "& .MuiTimelineContent-root": {
        paddingTop: 0,
        margin: 0,
    },
    "& .MuiTimelineDot-root": {
        border: "3px solid " + theme.palette.primaryShades[100],
        margin: "2px 0 0 0",
        backgroundColor: theme.palette.primaryShades[600],
        padding: "3px",
        boxShadow: "none",
        zIndex: 2,
    },
    "& .article-header": {
        marginTop: "1px",
        fontSize: "13px",
        fontWeight: FONT_WEIGHT_MEDIUM,
        color: theme.palette.grey[400],
    },
    "& .article-content": {
        margin: "15px 0 35px 0",
        padding: "0",
        "& h1, & h2, & h1 *, & h2 *": {
            fontSize: "18px!important",
            fontWeight: FONT_WEIGHT_SEMI_BOLD + "!important",
        },
        "& h3, & h4, & h5, & h6": {
            fontWeight: FONT_WEIGHT_BOLD,
            fontSize: "16px!important",
        },
        "& p": {
            margin: "7px 0",
        },
        "& ul": {
            listStyleType: "disc",
        },
        "& img": {
            justifySelf: "center",
            maxWidth: "650px",
        },
    },
}))

export const NewsOverview = (): JSX.Element => {
    const notificationContext = useNotificationContext()

    const [articles, setArticles] = useState<ArticleDTO[]>([])

    const theme = useTheme()

    useEffect(() => {
        fetchNews()
    }, [])

    const fetchNews = () => {
        ArticleService.loadLatestArticles(ContentType.NEWS, 5).then((response) => {
            setArticles(response.articles)
        })
    }

    const handleClose = () => {
        notificationContext.setShowNews(false)
    }

    const dialogContent = (
        <DialogContentText>
            <Box sx={{ margin: "0px -10px 10px -15px", height: "99%" }}>
                <Timeline
                    sx={{
                        marginTop: "10px",
                        [`& .${timelineItemClasses.root}:before`]: {
                            flex: 0,
                            padding: 0,
                        },
                    }}
                >
                    {articles.map((article) => {
                        return (
                            <TimelineItem key={article.title}>
                                <TimelineSeparator>
                                    <TimelineDot />
                                    <TimelineConnector />
                                </TimelineSeparator>
                                <TimelineContent>
                                    <Typography variant={"h2"} className={"article-header"}>
                                        {article.title}
                                    </Typography>
                                    <Box
                                        className={"article-content"}
                                        dangerouslySetInnerHTML={{ __html: article.content }}
                                    />
                                </TimelineContent>
                            </TimelineItem>
                        )
                    })}
                </Timeline>
            </Box>
        </DialogContentText>
    )

    return (
        <StyledCustomDialog
            className={"news-overview"}
            header={
                <Typography variant={"h3"} sx={{ fontSize: "20px" }}>
                    What's New?
                </Typography>
            }
            content={dialogContent}
            footer={{ kind: "none" }}
            onClose={handleClose}
            open={notificationContext.showNews}
            maxWidth="md"
        />
    )
}
