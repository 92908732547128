import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined"
import LogoutIcon from "@mui/icons-material/Logout"
import { Alert, Autocomplete, Button, Paper, Popover, Snackbar, TextField } from "@mui/material"
import Stack from "@mui/material/Stack"
import { RegularUserDTO } from "generated/models"
import { useLayoutContext } from "layout/MainLayout/LayoutContext"
import { LogoutConfirmationDialog } from "layout/MainLayout/UserMenu/LogoutConfirmationDialog"
import { UserProfileAction } from "layout/MainLayout/UserMenu/UserProfileAction"
import { UserSwitchConfirmationDialog } from "layout/MainLayout/UserMenu/UserSwitchConfirmationDialog"
import React from "react"

export interface UserMenuPopoverProps {
    popoverAnchorEl: HTMLElement | null
    onPopoverClose: () => void
}

export type UserSwitchState =
    | { type: "notInitiated" }
    | { type: "confirmationDialogOpen"; user: RegularUserDTO }
    | { type: "inProgress"; user: RegularUserDTO }

const userSwitchNotInitiated: UserSwitchState = { type: "notInitiated" }
const userSwitchDialogOpenForUser = (user: RegularUserDTO): UserSwitchState => ({
    type: "confirmationDialogOpen",
    user,
})
const userSwitchInProgressForUser = (user: RegularUserDTO): UserSwitchState => ({
    type: "inProgress",
    user,
})

export const UserMenuPopover = ({ popoverAnchorEl, onPopoverClose: onPopoverClose }: UserMenuPopoverProps) => {
    const [logoutConfirmationDialogOpen, setLogoutConfirmationDialogOpen] = React.useState(false)
    const [userSwitchState, setUserSwitchState] = React.useState<UserSwitchState>(userSwitchNotInitiated)
    const [errorSnackbarOpen, setErrorSnackbarOpen] = React.useState(false)

    const { handleLogout, useGetUsersQuery, useSwitchUserMutation, redirectAfterUserSwitch, authenticationState } =
        useLayoutContext()

    const switchUser = useSwitchUserMutation()

    const skipGetUsersQuery = !authenticationState?.user?.internalUser
    const userSwitchOptions = useGetUsersQuery(skipGetUsersQuery)

    const onLogoutButtonClick = () => {
        setLogoutConfirmationDialogOpen(true)
    }

    const onConfirmLogoutButtonClick = () => {
        setLogoutConfirmationDialogOpen(false)
        handleLogout()
    }

    const onCancelLogoutButtonClick = () => {
        setLogoutConfirmationDialogOpen(false)
    }

    const onSelectUserSwitchOption = (event: React.SyntheticEvent, value: RegularUserDTO | null) => {
        setUserSwitchState(value ? userSwitchDialogOpenForUser(value) : userSwitchNotInitiated)
    }

    const onSnackbarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === "clickaway") {
            return
        }

        setErrorSnackbarOpen(false)
    }

    const onConfirmUserSwitchButtonClick = (user: RegularUserDTO) => () => {
        setUserSwitchState(userSwitchInProgressForUser(user))
        switchUser(user.id)
            .then((success) => {
                if (success) {
                    redirectAfterUserSwitch()
                } else {
                    setErrorSnackbarOpen(true)
                }
            })
            .catch(() => {
                setErrorSnackbarOpen(true)
            })
            .finally(() => {
                setUserSwitchState(userSwitchNotInitiated)
            })
    }

    const onCancelUserSwitchButtonClick = () => {
        setUserSwitchState(userSwitchNotInitiated)
    }

    const openUserSettings = () => {
        const userId = authenticationState?.user?.id
        UserProfileAction.open(userId)
        onPopoverClose()
    }

    let switchUserContent = <></>
    if (authenticationState?.user?.internalUser) {
        switchUserContent = (
            <React.Fragment>
                <Autocomplete
                    className={"user-switch-autocomplete"}
                    fullWidth
                    size="small"
                    sx={{ mt: 1, mb: 1 }}
                    autoHighlight
                    handleHomeEndKeys
                    loading={userSwitchOptions.isLoading || userSwitchOptions.isError}
                    loadingText={userSwitchOptions.isError ? "An error occurred" : "Loading users..."}
                    options={userSwitchOptions.users || []}
                    getOptionLabel={(option) => option.loginName}
                    value={
                        userSwitchState.type === "confirmationDialogOpen" || userSwitchState.type === "inProgress"
                            ? userSwitchState.user
                            : null
                    }
                    onChange={onSelectUserSwitchOption}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            InputProps={{
                                ...params.InputProps,
                            }}
                            label="Change User"
                        />
                    )}
                />
                <UserSwitchConfirmationDialog
                    userSwitchState={userSwitchState}
                    onConfirmUserSwitchButtonClick={onConfirmUserSwitchButtonClick}
                    onCancelUserSwitchButtonClick={onCancelUserSwitchButtonClick}
                />
            </React.Fragment>
        )
    }

    return (
        <>
            <Popover
                anchorEl={popoverAnchorEl}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                open={Boolean(popoverAnchorEl)}
                onClose={onPopoverClose}
            >
                <Paper elevation={0} sx={{ padding: "1em", width: 260 }} className={"user-menu-popover"}>
                    <Stack spacing={0} alignItems={"start"}>
                        <Button startIcon={<AccountCircleOutlinedIcon />} onClick={openUserSettings}>
                            User Settings
                        </Button>
                        <Button onClick={onLogoutButtonClick} startIcon={<LogoutIcon />}>
                            Logout
                        </Button>
                        <LogoutConfirmationDialog
                            open={logoutConfirmationDialogOpen}
                            onConfirmLogoutButtonClick={onConfirmLogoutButtonClick}
                            onCancelLogoutButtonClick={onCancelLogoutButtonClick}
                        />
                        {switchUserContent}
                    </Stack>
                </Paper>
            </Popover>
            <Snackbar
                open={errorSnackbarOpen}
                onClose={onSnackbarClose}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
                <Alert onClose={onSnackbarClose} severity="error">
                    An error occurred while switching users.
                </Alert>
            </Snackbar>
        </>
    )
}
