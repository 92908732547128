import Box from "@mui/material/Box"
import CircularProgress from "@mui/material/CircularProgress"
import { CustomIcon } from "domain/core/component/CustomIcon"
import React from "react"

type Props = {
    formattedNumber: string
    label: string
    icon?: string
    additionalCssClass?: string
    currencyString?: string
    span?: number
}

const renderNumber = (formattedNumber: string, currencyString?: string) =>
    formattedNumber ? (
        <React.Fragment>
            {currencyString && <span className={"currency"}>{currencyString}</span>}
            {formattedNumber}
        </React.Fragment>
    ) : (
        <React.Fragment>
            <span className={"spacer"}>00,0k</span> <CircularProgress size={16} />
        </React.Fragment>
    )

const KPIEntry = (props: Props) => (
    <Box sx={{ flex: props.span || 1 }}>
        <div className={"kpi " + (props.additionalCssClass || "")}>
            <div className={"label-and-number"}>
                <div className={"number"}>{renderNumber(props.formattedNumber, props.currencyString)}</div>
                <div className={"label"}>{props.label}</div>
            </div>
            {props.icon && <CustomIcon icon={props.icon} size={"large"} />}
        </div>
    </Box>
)

export default KPIEntry
