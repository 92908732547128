import { Paper } from "@mui/material"
import { styled } from "@mui/material/styles"
import { FONT_WEIGHT_BOLD, FONT_WEIGHT_MEDIUM, FONT_WEIGHT_REGULAR } from "styles/theme/constants"

export const CommonMenuPaper = styled(Paper)(({ theme }) => ({
    paddingTop: "20px",
    "& .MuiListItem-root": {
        padding: 0,
    },
    "& > .MuiList-root > .MuiListItem-root:first-of-type": {
        marginTop: "-15px",
        paddingTop: 0,
    },
    "& .MuiListItemButton-root": {
        lineHeight: "150%",
        paddingRight: "5px",
        backgroundColor: "rgba(255,255,255,0)",
        transition: "none",
    },
    "& .level-0 .MuiListItemButton-root": {
        paddingBottom: "2px",
        paddingTop: "4px",
        fontWeight: FONT_WEIGHT_MEDIUM,
    },
    "& .level-0 span": {
        color: theme.palette.secondaryTitleColor,
    },
    "&& .level-1 span": {
        fontWeight: FONT_WEIGHT_MEDIUM,
    },
    "& *": {
        fontSize: "13px!important",
    },
    "& .level-1 .MuiListItemButton-root": {
        marginLeft: "0px",
        paddingBottom: "1px",
        paddingTop: "1px",
        fontWeight: FONT_WEIGHT_REGULAR,
    },
    "& .MuiSvgIcon-root": {
        color: "rgba(54, 72, 125, 0.8)",
        height: "20px",
        marginTop: "-1px",
        width: "20px",
    },
    "& .MuiListItemIcon-root": {
        minWidth: "28px",
    },
    // Selected item background
    "& .menu-list-item-node .Mui-selected": {
        backgroundColor: theme.palette.primaryShades[50],
        "& > div > span": {
            fontWeight: FONT_WEIGHT_BOLD,
            color: theme.palette.primary.main,
        },
    },
}))
