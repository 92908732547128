import ExpandLess from "@mui/icons-material/ExpandLess"
import ExpandMore from "@mui/icons-material/ExpandMore"
import { Box, Collapse, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material"
import {
    ASONewUiMenuLeafDTO,
    ClassicUiEmbeddedMenuLeafDTO,
    LegacyDashboardsEmbeddedMenuLeafDTO,
    MenuDTO,
    MenuNodeDTO,
    NewUiGenericPageMenuLeafDTO,
} from "generated/models"
import * as React from "react"
import { useEffect, useState } from "react"
import { Link } from "react-router"
import MenuUtil, { isNode } from "shared/service/MenuUtil"

type ArticleMenuProps = {
    inlineIndent?: number
    showLogo: boolean
    firstEntryPath: string
    entries: Array<
        | ASONewUiMenuLeafDTO
        | ClassicUiEmbeddedMenuLeafDTO
        | LegacyDashboardsEmbeddedMenuLeafDTO
        | MenuNodeDTO
        | NewUiGenericPageMenuLeafDTO
    >
    onMenuClick: (path: string) => void
}

type ArticleMenuState = {
    collapsed: boolean
    selected: string
    open: string[]
}

export const ArticleMenu: React.FC<ArticleMenuProps> = (props: ArticleMenuProps) => {
    const [state, setState] = useState<ArticleMenuState>({ collapsed: false, selected: "", open: [] })

    const getNodePath = (articleIdentifier: string) => {
        return MenuUtil.getMenuNodeEndingWith(articleIdentifier, { root: props.entries })?.path
    }

    useEffect(() => {
        const selected = props.firstEntryPath
        setState({
            collapsed: false,
            selected: selected ? selected : undefined,
            open: selected ? getOpenPaths(selected) : [],
        })
    }, [props.firstEntryPath])

    const getOpenPaths = (selectedPath: string): string[] => {
        const subPaths = selectedPath ? selectedPath.split("/") : undefined
        const [_, ...consideredSubPaths] = subPaths.filter((p) => p.length > 0).reverse()
        consideredSubPaths.reverse()
        return consideredSubPaths.map(
            (p, i) =>
                `/${[consideredSubPaths.filter((_, ii) => ii < i).join("/"), p]
                    .filter((pp) => pp.length > 0)
                    .join("/")}`,
        )
    }

    const onCollapse = (collapsed: boolean) => {
        setState((prev) => ({ ...prev, collapsed: collapsed }))
    }

    const gotoHome = () => {
        const { entries } = props
        setState((prevState) => ({
            ...prevState,
            selected:
                entries.length && isNode(entries[0]) && entries[0].items.length
                    ? `${entries[0].path}${entries[0].items[0].path}`
                    : undefined,
            open: entries.length ? [entries[0].path] : [],
        }))
    }

    const onSelect = (path: string) => {
        setState((prevState) => ({
            ...prevState,
            selected: path,
        }))
    }

    const onOpenChange = (path: string) => {
        const isOpen = state.open.includes(path)
        const hierarchyOpenKeys = state.open.filter((key) => path.startsWith(key))

        setState((prevState) => ({
            ...prevState,
            open: isOpen ? hierarchyOpenKeys : [...hierarchyOpenKeys, path],
        }))
    }

    const getMenuItem = (item: MenuDTO) => (
        <ListItem key={item.path} disablePadding>
            <ListItemButton
                selected={state.selected === item.path}
                onClick={(e) => {
                    e.preventDefault()
                    clickOnLink(item.path)
                }}
                sx={{
                    pl: props.inlineIndent ? props.inlineIndent / 8 : 3,
                    pr: "1px",
                    ml: 2,
                    position: "relative",
                    py: "6px",
                    "&.Mui-selected": {
                        backgroundColor: "#eef3ff",
                        "&:hover": {
                            backgroundColor: "#e3ebff",
                        },
                        "&::before": {
                            content: '""',
                            position: "absolute",
                            left: 0,
                            top: 0,
                            bottom: 0,
                            width: "3px",
                            backgroundColor: "#3662e3",
                        },
                    },
                }}
            >
                <ListItemText
                    primary={item.title}
                    sx={{
                        ".MuiTypography-root": {
                            color: state.selected === item.path ? "#3662e3" : "#384e5e",
                            fontSize: "13px",
                        },
                        margin: 0,
                    }}
                />
            </ListItemButton>
        </ListItem>
    )

    const getSubMenu = (entry: MenuNodeDTO, level: number) => {
        const isOpen = state.open.includes(entry.path)
        const hasSelectedChild = entry.items.some(
            (item) =>
                state.selected === item.path ||
                (isNode(item) && item.items.some((subItem) => state.selected === subItem.path)),
        )

        return (
            <React.Fragment key={entry.path}>
                <ListItem disablePadding>
                    <ListItemButton
                        onClick={() => onOpenChange(entry.path)}
                        sx={{
                            pl: level === 1 ? 2 : (props.inlineIndent ? props.inlineIndent / 8 : 3) * level,
                            pr: "1px",
                            py: 0.5,
                            marginBottom: "7px",
                        }}
                    >
                        {entry.icon && (
                            <ListItemIcon>
                                <div className={`icon icon-${entry.icon}`} />
                            </ListItemIcon>
                        )}
                        <ListItemText
                            primary={entry.title}
                            sx={{
                                ".MuiTypography-root": {
                                    color: hasSelectedChild ? "#3662e3" : "#384e5e",
                                    fontWeight: 650,
                                    fontSize: "13px",
                                },
                                margin: 0,
                            }}
                        />
                        {isOpen ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                </ListItem>
                <Collapse in={isOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {getItem(entry, level)}
                    </List>
                </Collapse>
            </React.Fragment>
        )
    }

    const getItem = (entry: MenuNodeDTO, level) => entry.items.map((item) => mapItem(item, level))

    const mapItem = (item: MenuDTO, level) =>
        isNode(item) && item.items.length ? getSubMenu(item, level + 1) : getMenuItem(item)

    const { entries, showLogo } = props
    const { selected } = state

    const clickOnLink = (path: string) => {
        const selected = getNodePath(path.split("/").pop())
        props.onMenuClick(selected)

        setState((prevState) => ({
            ...prevState,
            selected: selected ? selected : undefined,
            open: selected ? getOpenPaths(selected) : [],
        }))
    }

    return (
        <Box
            component="nav"
            sx={{
                width: 250,
                flexShrink: 0,
                backgroundColor: "background.paper",
                borderRight: 1,
                borderColor: "divider",
            }}
        >
            {showLogo && (
                <Box
                    component={Link}
                    to="/"
                    onClick={gotoHome}
                    sx={{
                        display: "block",
                        p: 2,
                        textDecoration: "none",
                        color: "inherit",
                    }}
                >
                    <div id="logo" className={state.collapsed ? "small" : "wide"}>
                        <span className={"company"}>exactag</span>
                        <span className={"subtitle"}>Support</span>
                    </div>
                </Box>
            )}
            <List
                component="nav"
                sx={{
                    pt: showLogo ? 0 : 2,
                    width: "100%",
                }}
            >
                {entries &&
                    entries.map((entry) =>
                        isNode(entry) && entry.items.length ? getSubMenu(entry, 1) : getMenuItem(entry),
                    )}
            </List>
        </Box>
    )
}
