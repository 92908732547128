import { createSvgIcon } from "@mui/material"
import React from "react"

const SmallClockIcon = createSvgIcon(
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_5626_1866)">
            <path
                d="M6 3.22222V6H8.77778M6 11C3.23858 11 1 8.76144 1 6C1 3.23858 3.23858 1 6 1C8.76144 1 11 3.23858 11 6C11 8.76144 8.76144 11 6 11Z"
                stroke="white"
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
        <defs>
            <clipPath id="clip0_5626_1866">
                <rect width="12" height="12" fill="currentColor" />
            </clipPath>
        </defs>
    </svg>,
    "Clock",
)

export default SmallClockIcon
