/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from "../runtime"

/**
 *
 * @export
 * @interface TimeSpanDTO
 */
export interface TimeSpanDTO {
    /**
     *
     * @type {string}
     * @memberof TimeSpanDTO
     */
    endDate?: string
    /**
     *
     * @type {boolean}
     * @memberof TimeSpanDTO
     */
    includeTime: boolean
    /**
     *
     * @type {string}
     * @memberof TimeSpanDTO
     */
    startDate?: string
    /**
     *
     * @type {string}
     * @memberof TimeSpanDTO
     */
    type: TimeSpanDTOTypeEnum
}

/**
 * @export
 */
export const TimeSpanDTOTypeEnum = {
    CUSTOM: "CUSTOM",
    TODAY: "TODAY",
    YESTERDAY: "YESTERDAY",
    THIS_WEEK_INCLUDING_TODAY: "THIS_WEEK_INCLUDING_TODAY",
    THIS_WEEK_EXCLUDING_TODAY: "THIS_WEEK_EXCLUDING_TODAY",
    THIS_MONTH_INCLUDING_TODAY: "THIS_MONTH_INCLUDING_TODAY",
    THIS_MONTH_EXCLUDING_TODAY: "THIS_MONTH_EXCLUDING_TODAY",
    THIS_QUARTER_INCLUDING_TODAY: "THIS_QUARTER_INCLUDING_TODAY",
    THIS_QUARTER_EXCLUDING_TODAY: "THIS_QUARTER_EXCLUDING_TODAY",
    THIS_YEAR_INCLUDING_TODAY: "THIS_YEAR_INCLUDING_TODAY",
    THIS_YEAR_EXCLUDING_TODAY: "THIS_YEAR_EXCLUDING_TODAY",
    LAST_WEEK: "LAST_WEEK",
    LAST_MONTH: "LAST_MONTH",
    LAST_7_DAYS_INCLUDING_TODAY: "LAST_7_DAYS_INCLUDING_TODAY",
    LAST_7_DAYS_EXCLUDING_TODAY: "LAST_7_DAYS_EXCLUDING_TODAY",
    LAST_30_DAYS_INCLUDING_TODAY: "LAST_30_DAYS_INCLUDING_TODAY",
    LAST_30_DAYS_EXCLUDING_TODAY: "LAST_30_DAYS_EXCLUDING_TODAY",
    LAST_QUARTER: "LAST_QUARTER",
    LAST_90_DAYS_INCLUDING_TODAY: "LAST_90_DAYS_INCLUDING_TODAY",
    LAST_90_DAYS_EXCLUDING_TODAY: "LAST_90_DAYS_EXCLUDING_TODAY",
    LAST_YEAR: "LAST_YEAR",
    LAST_365_DAYS_INCLUDING_TODAY: "LAST_365_DAYS_INCLUDING_TODAY",
    LAST_365_DAYS_EXCLUDING_TODAY: "LAST_365_DAYS_EXCLUDING_TODAY",
} as const
export type TimeSpanDTOTypeEnum = (typeof TimeSpanDTOTypeEnum)[keyof typeof TimeSpanDTOTypeEnum]

/**
 * Check if a given object implements the TimeSpanDTO interface.
 */
export function instanceOfTimeSpanDTO(value: object): value is TimeSpanDTO {
    if (!("includeTime" in value) || value["includeTime"] === undefined) return false
    if (!("type" in value) || value["type"] === undefined) return false
    return true
}

export function TimeSpanDTOFromJSON(json: any): TimeSpanDTO {
    return TimeSpanDTOFromJSONTyped(json, false)
}

export function TimeSpanDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): TimeSpanDTO {
    if (json == null) {
        return json
    }
    return {
        endDate: json["endDate"] == null ? undefined : json["endDate"],
        includeTime: json["includeTime"],
        startDate: json["startDate"] == null ? undefined : json["startDate"],
        type: json["type"],
    }
}

export function TimeSpanDTOToJSON(value?: TimeSpanDTO | null): any {
    if (value == null) {
        return value
    }
    return {
        endDate: value["endDate"],
        includeTime: value["includeTime"],
        startDate: value["startDate"],
        type: value["type"],
    }
}
