import { ColumnConfigDTO } from "generated/models"

export type Scenario = {
    campaign_id?: number
    comment?: string
    optimizationLevel?: string
    mediaPlan?: any[]
    adspend_scenario_id?: string | number
    adspend_scenario_name?: string
    start_date?: string
    end_date?: string
    job_status?: string
    mediaplan_group_by?: string
}

export type CampaignDTO = {
    campaignId: string | number
    campaignName: string
    costs?: number
}

export type ScenarioColumnConfig = ColumnConfigDTO & {
    valueType: ColumnValueType
    comparisonItem1Name?: string
    comparisonItem2Name?: string
    round?: number
    goodChangeDirection?: GoodChangeDirection
}

export enum ColumnValueType {
    STRING = "STRING",
    CURRENCY = "CURRENCY",
    NUMBER = "NUMBER",
    PERCENT = "PERCENT",
}

export enum GoodChangeDirection {
    HAS_NO_DIRECTION = "HAS_NO_DIRECTION",
    UP = "UP",
    DOWN = "DOWN",
}

export type ScenarioComparisonData = {
    values: any[]
    change: {
        absolute: { value: number }
        relative: { value: number }
    }
    comparisonItem1Name: string
    comparisonItem2Name: string
    goodChangeDirection: GoodChangeDirection
    label: string
    valueType: ColumnValueType
    round: number
}

// export type ReportingDataSetDTO = {
//     rows: GridDataRowDTO[]
//     totals?: GridDataRowDTO
//     minValues?: DataRowDTO
//     maxValues?: DataRowDTO
// }

export type ComparisonRequestDataDTO = {
    prefix: string
    scenarioIds: (string | number)[]
    appContext: any
}

export type EfficiencyDataDTO = {
    rows: { [key: string]: any }[]
    totals?: { [key: string]: any }
    minValues?: { [key: string]: any }
    maxValues?: { [key: string]: any }
}

export type EfficiencyRequestDataDTO = object
