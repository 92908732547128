import { MenuDTO } from "generated/models"
import { isMenuLeaf, isNode } from "shared/service/MenuUtil"

/**
 * Given a URL path and a menu tree, finds the full path (using each item’s `path`)
 * from the root to the leaf that matches.
 */
export const findPathInTree = (path: string, tree: MenuDTO[]): string[] => {
    for (const item of tree) {
        if (isMenuLeaf(item)) {
            if (item.path === path) {
                return [item.path]
            }
        } else if (isNode(item)) {
            const result = findPathInTree(path, item.items)
            if (result.length > 0) {
                return [item.path, ...result]
            }
        }
    }
    return []
}

/**
 * Same as above, but returns the title of each menu item instead of the path.
 */
export const findPrintablePathInTree = (path: string, tree: MenuDTO[]): string[] => {
    for (const item of tree) {
        if (isMenuLeaf(item)) {
            if (item.path === path) {
                return [item.title]
            }
        } else if (isNode(item)) {
            const result = findPrintablePathInTree(path, item.items)
            if (result.length > 0) {
                return [item.title, ...result]
            }
        }
    }
    return []
}
