import { Alert, DialogContentText } from "@mui/material"
import { CustomDialog } from "layout/components/CustomDialog"
import React, { type JSX } from "react"

type AuthLoginErrorDialogProps = {
    errorState: "start" | "first-error" | "further-error"
    navigateToPasswordReset: () => void
    onClose: () => void
    open: boolean
}

export const AuthLoginErrorDialog = ({
    errorState,
    navigateToPasswordReset,
    onClose,
    open,
}: AuthLoginErrorDialogProps): JSX.Element => {
    return (
        <CustomDialog
            header={"Failed to Verify Credentials"}
            content={
                <DialogContentText>
                    <div className={"login-form-error"}>
                        We couldn't authenticate your login details. To resolve this issue, please ensure the following:
                        <ul>
                            <li>Verify that both your username and password are correct.</li>
                            <li>Both fields are case-sensitive; please check your capitalization.</li>
                        </ul>
                        Still having trouble? Consider <a onClick={navigateToPasswordReset}>resetting your password</a>{" "}
                        or contacting our <a href="mailto:support@exactag.com">support team</a> for assistance.
                        {errorState === "further-error" && (
                            <>
                                <br />
                                <br />
                                <Alert severity="warning">
                                    Please be aware that your account will be locked temporarily after repeated login
                                    failures.
                                </Alert>
                            </>
                        )}
                    </div>
                </DialogContentText>
            }
            footer={{
                kind: "yesNoButton",
                noText: "Back To Login",
                yesText: "Reset Password Now",
                onYesButtonClick: navigateToPasswordReset,
                onNoButtonClick: onClose,
                submitting: false,
            }}
            onClose={onClose}
            open={open}
        />
    )
}
