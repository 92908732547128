import CloseIcon from "@mui/icons-material/Close"
import { Box, IconButton } from "@mui/material"
import { useDashboardLayout } from "domain/dashboard/DashboardLayout/DashboardLayoutContext"
import React, { PropsWithChildren } from "react"

type Props = {
    widgetId: string
}

export const RemovableWidget = (props: PropsWithChildren<Props>) => {
    const { widgetId, children } = props
    const { removeWidget, readOnly } = useDashboardLayout()

    return (
        <Box
            position="relative"
            height="100%"
            width="100%"
            sx={{
                ":hover > .MuiIconButton-root": {
                    opacity: 1,
                },
            }}
        >
            {children}
            {!readOnly && (
                <IconButton
                    onClick={() => removeWidget(widgetId)}
                    sx={{
                        zIndex: 1,
                        position: "absolute",
                        top: 0,
                        right: 32,
                        opacity: 0,
                        transition: "opacity 0.2s 0.3s ease-out",
                    }}
                >
                    <CloseIcon />
                </IconButton>
            )}
        </Box>
    )
}
