import { createSvgIcon } from "@mui/material"
import React from "react"

const GiftIcon = createSvgIcon(
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2 9C2 7.89543 2.89543 7 4 7H20C21.1046 7 22 7.89543 22 9V11C22 12.1046 21.1046 13 20 13H4C2.89543 13 2 12.1046 2 11V9ZM20 9H4V11H20V9Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 7C12.5523 7 13 7.44772 13 8V21C13 21.5523 12.5523 22 12 22C11.4477 22 11 21.5523 11 21V8C11 7.44772 11.4477 7 12 7Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5 11C5.55228 11 6 11.4477 6 12V19C6 19.2652 6.10536 19.5196 6.29289 19.7071C6.48043 19.8946 6.73478 20 7 20H17C17.2652 20 17.5196 19.8946 17.7071 19.7071C17.8946 19.5196 18 19.2652 18 19V12C18 11.4477 18.4477 11 19 11C19.5523 11 20 11.4477 20 12V19C20 19.7957 19.6839 20.5587 19.1213 21.1213C18.5587 21.6839 17.7957 22 17 22H7C6.20435 22 5.44129 21.6839 4.87868 21.1213C4.31607 20.5587 4 19.7956 4 19V12C4 11.4477 4.44772 11 5 11Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.46944 5.01213C8.80934 4.27868 8.12408 3.98928 7.51742 3.99985L7.5 4C7.10218 4 6.72064 4.15804 6.43934 4.43934C6.15804 4.72065 6 5.10218 6 5.5C6 5.89783 6.15804 6.27936 6.43934 6.56066C6.72064 6.84197 7.10218 7 7.5 7C8.05228 7 8.5 7.44772 8.5 8C8.5 8.55229 8.05228 9 7.5 9C6.57174 9 5.6815 8.63125 5.02513 7.97488C4.36875 7.3185 4 6.42826 4 5.5C4 4.57174 4.36875 3.68151 5.02513 3.02513C5.67943 2.37083 6.56612 2.00234 7.4912 2.00001C8.81053 1.97992 10.0128 2.62612 10.956 3.6742C11.3497 4.1116 11.6987 4.61781 12 5.17657C12.3013 4.61781 12.6503 4.1116 13.044 3.6742C13.9872 2.62612 15.1895 1.97992 16.5088 2.00001C17.4339 2.00234 18.3206 2.37083 18.9749 3.02513C19.6313 3.68151 20 4.57174 20 5.5C20 6.42826 19.6313 7.3185 18.9749 7.97488C18.3185 8.63125 17.4283 9 16.5 9C15.9477 9 15.5 8.55229 15.5 8C15.5 7.44772 15.9477 7 16.5 7C16.8978 7 17.2794 6.84197 17.5607 6.56066C17.842 6.27936 18 5.89783 18 5.5C18 5.10218 17.842 4.72065 17.5607 4.43934C17.2794 4.15804 16.8978 4 16.5 4L16.4826 3.99985C15.8759 3.98928 15.1907 4.27868 14.5306 5.01213C13.8654 5.75123 13.305 6.8626 12.9718 8.23583C12.863 8.68426 12.4614 9 12 9C11.5386 9 11.137 8.68426 11.0282 8.23583C10.695 6.8626 10.1346 5.75123 9.46944 5.01213Z"
            fill="currentColor"
        />
    </svg>,
    "Gift",
)

export default GiftIcon
