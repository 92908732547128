import ArrowForwardIcon from "@mui/icons-material/ArrowForward"
import DownloadIcon from "@mui/icons-material/Download"
import { Box, IconButton, Stack, Tooltip, Typography } from "@mui/material"
import Grid2 from "@mui/material/Grid2"
import ScenarioSelectDropdown from "domain/adspend-optimizer/component/ScenarioSelectDropdown"
import { Scenario } from "domain/types"
import React, { ReactElement } from "react"
import { capitalizeEnum } from "shared/util/util"

type Props = {
    optimizationLevel: string
    titlePrefix: string
    scenarioId1: string | number
    scenarioId2: string | number
    callback1?: (_, scenario: number | string) => void
    callback2?: (_, scenario: number | string) => void
    scenarios?: Scenario[]
    onDownload?: () => void
    downloadTooltip?: string
}

export default (props: Props) => {
    const downloadButton = (children: ReactElement) =>
        props.downloadTooltip ? (
            <Tooltip title={props.downloadTooltip} arrow>
                {children}
            </Tooltip>
        ) : (
            <React.Fragment>{children}</React.Fragment>
        )

    return (
        <Grid2
            container
            className="comparison-header"
            justifyContent="space-between"
            alignItems="center"
            sx={{ mb: 2 }}
        >
            <Grid2 className="header-left">
                <Stack direction="row" alignItems="center">
                    <Box sx={{ mr: 2 }}>
                        <Typography variant="h6" component="h6" display="inline" sx={{ fontSize: 16 }}>
                            {props.titlePrefix} {capitalizeEnum(props.optimizationLevel).replace("-", " ")}:{" "}
                        </Typography>
                    </Box>
                    <Box sx={{ mr: 2 }}>
                        <ScenarioSelectDropdown
                            initValue={props.scenarioId1}
                            size="large"
                            callback={props.callback1}
                            scenarios={props.scenarios}
                        />
                    </Box>
                    <Box sx={{ mr: 1, mt: 1 }}>
                        <ArrowForwardIcon />
                    </Box>
                    <ScenarioSelectDropdown
                        initValue={props.scenarioId2}
                        callback={props.callback2}
                        size="large"
                        scenarios={props.scenarios}
                    />
                </Stack>
            </Grid2>
            <Grid2 className="toolbar toolbar-right">
                {props.onDownload &&
                    downloadButton(
                        <IconButton onClick={props.onDownload} color="primary" className="download">
                            <DownloadIcon />
                        </IconButton>,
                    )}
            </Grid2>
        </Grid2>
    )
}
